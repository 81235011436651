import {Dimensions} from 'react-native';
import moment from 'moment';
import Moment from 'moment';
import React from "react";

const WINDOW_WIDTH = Dimensions.get('window').width;
const WINDOW_HEIGHT = Dimensions.get('window').height;

const _colorSet = {
    whiteBackgroundColor: 'white',
    mainThemeBackgroundColor: 'white',
    mainThemeForegroundColor: 'white',
    drawerTextColor: 'white',
    inputBackgroundColor: '#f0f2f5',
    navBackgroundColor: '#1D1F1E',
    primaryTextColor: '#119de2',
    primaryButtonBackgroundColor: '#119de2',
    successButtonBackgroundColor: '#3DBA71',
    successTextColor: '#096730',
    mainTextColor: '#1A1A1A',
    dangerTextColor: '#B96363',
    mainSubtextColor: '#595959',
    textMutedColor: '#595959',
    hairlineColor: '#d6d6d6',
    whiteColor: 'white',
    redColor: 'red',
    analyticsColor: '#5f65fc',
    analyticsLightColor: '#5f65fc30',
    customersColor: '#ff8a02',
    customersLightColor: '#ff8a0230',
    ordersColor: '#e090d7',
    ordersLightColor: '#e090d730',
    tasksColor: '#6fb168',
    tasksLightColor: '#6fb16830',
    salesColor: '#f8d246',
    salesLightColor: '#f8d24630',
    productsColor: '#bc6e7e',
    productsLightColor: '#bc6e7e30',
    taskNotStartedColor: '#f8d246',
    taskDoneColor: 'green',
};

const _lightColorSet = {
    dark: false,
    colors: {
        bottomTabBarBackgroundColor: '#F8F8F8',
        whiteBackgroundColor: 'white',
        mainThemeBackgroundColor: 'white',
        mainThemeForegroundColor: 'white',
        drawerTextColor: 'white',
        inputBackgroundColor: '#f0f2f5',
        navBackgroundColor: '#1D1F1E',
        primaryTextColor: '#119de2',
        primaryButtonBackgroundColor: '#252628',
        primaryButtonTextColor: 'white',
        successButtonBackgroundColor: '#3DBA71',
        successTextColor: '#096730',
        greenTextColor: '#3DBA71',
        mainTextColor: '#1A1A1A',
        dangerTextColor: '#B96363',
        mainSubtextColor: '#595959',
        textMutedColor: '#595959',
        hairlineColor: '#d6d6d6',
        whiteColor: 'white',
        redColor: 'red',
        analyticsColor: '#5f65fc',
        analyticsLightColor: '#5f65fc30',
        customersColor: '#ff8a02',
        customersLightColor: '#ff8a0230',
        ordersColor: '#e090d7',
        ordersLightColor: '#e090d730',
        tasksColor: '#6fb168',
        tasksLightColor: '#6fb16830',
        salesColor: '#f8d246',
        salesLightColor: '#f8d24630',
        productsColor: '#bc6e7e',
        productsLightColor: '#bc6e7e30',
        taskNotStartedColor: '#f8d246',
        taskDoneColor: 'green',
        card: {
            backgroundColor: 'white',
            borderColor: '#E4E1E0'
        },
        tab: {
            backgroundColor: '#EBE8E7',
            activeBackgroundColor: 'white',
            textColor: '#595959',
            activeTextColor: '#3DBA71',
        },
        toast: {
            success: {
                backgroundColor: '#EDFDF4',
                borderColor: '#19994F',
                textColor: '#19994F'
            },
            error: {
                backgroundColor: '#FBE8E8',
                borderColor: '#F09696',
                textColor: '#1A1A1A'
            }
        },
			form: {
				backgroundColor: 'white',
				borderColor: '#D9D6D6',
				hoverColor: '#595959'
			}
    },

};

const _darkColorSet = {
    dark: true,
    colors: {
        bottomTabBarBackgroundColor: '#242628',
        whiteBackgroundColor: 'white',
        mainThemeBackgroundColor: '#1D1F1E',
        mainThemeForegroundColor: 'white',
        drawerTextColor: 'white',
        inputBackgroundColor: '#f0f2f5',
        navBackgroundColor: '#1D1F1E',
        primaryTextColor: '#119de2',
        primaryButtonBackgroundColor: '#C2F3D7',
        primaryButtonTextColor: '#096730',
        successButtonBackgroundColor: '#3DBA71',
        successTextColor: '#3DBA71',
        greenTextColor: '#3DBA71',
        mainTextColor: '#F5F4F3',
        dangerTextColor: '#B96363',
        mainSubtextColor: '#A8A8A8',
        textMutedColor: '#595959',
        hairlineColor: '#d6d6d6',
        whiteColor: 'white',
        redColor: 'red',
        analyticsColor: '#5f65fc',
        analyticsLightColor: '#5f65fc30',
        customersColor: '#ff8a02',
        customersLightColor: '#ff8a0230',
        ordersColor: '#e090d7',
        ordersLightColor: '#e090d730',
        tasksColor: '#6fb168',
        tasksLightColor: '#6fb16830',
        salesColor: '#f8d246',
        salesLightColor: '#f8d24630',
        productsColor: '#bc6e7e',
        productsLightColor: '#bc6e7e30',
        taskNotStartedColor: '#f8d246',
        taskDoneColor: 'green',
        card: {
            backgroundColor: '#242628',
            borderColor: '#3e3e40'
        },
        tab: {
            backgroundColor: '#353739',
            activeBackgroundColor: '#1E1F21',
            textColor: '#A8A8A8',
            activeTextColor: '#3DBA71',
        },
        toast: {
            success: {
                backgroundColor: '#192C29',
                borderColor: '#19994F',
                textColor: '#19994F'
            },
            error: {
                backgroundColor: '#3E0E0E',
                borderColor: '#983131',
                textColor: '#F5F4F3'
            }
        },
			form: {
				backgroundColor: '#1D1F1E',
				borderColor: '#424244',
				hoverColor: '#F5F4F3'
			}
    },
};

const _fontSizeSet = {
    xxlarge: 40,
    xlarge: 30,
    large: 25,
    middle: 20,
    normal: 16,
    small: 13,
    xsmall: 11,
};

const _sizeSet = {
    buttonWidth: '70%',
    inputWidth: '80%',
    radius: 25,
};

const _iconSizeSet = {
    large: 35,
    normal: 24,
    small: 20,
};

const _styleSet = {
    menuButton: {
        alignSelf: 'center',
        justifyContent: 'center',
        padding: 10,
        paddingLeft: 20,
        paddingRight: 20,
    },
};

const _configSet = {
    stripe: {
        publishableKey: 'pk_test_eOs2OK9HVC7ZwYHJjwRmyoIN00RF2ZbLtW',
    },
};

const _functions = {
    timeFormat: timeStamp => {
        let time = '';
        if (timeStamp) {
            if (moment().diff(timeStamp, 'days') === 0) {
                time = moment(timeStamp).format('H:mm');
            } else {
                time = moment(timeStamp).fromNow();
            }
        }
        // time = postTime.toUTCString();
        return time;
    },

    diffTime: (startTime, endTime) => {
        startTime = Moment(startTime, 'YY-MM-dd hh:mm:i');
        endTime = Moment(endTime, 'YY-MM-dd hh:mm:i');
        let duration = Moment.duration(endTime.diff(startTime));
        let hours = parseInt(duration.asHours());
        let minutes = parseInt(duration.asMinutes()) % 60;
        return hours + ':' + ('0' + minutes).slice(-2)
    },
    diffTimeToHours: (startTime, endTime) => {
        startTime = Moment(startTime, 'YY-MM-dd hh:mm:i');
        endTime = Moment(endTime, 'YY-MM-dd hh:mm:i');
        let duration = Moment.duration(endTime.diff(startTime));
        return duration.asHours().toFixed(2)
    },
    moneyFormat: (num) => {
        return num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    },
    bytesToSize: (bytes) => {
        let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    },
    genUid: () => {
        return Math.round((Math.pow(36, 5 + 1) - Math.random() * Math.pow(36, 5))).toString(36).slice(1);
    }
};


const _chartConfig = {
    backgroundGradientFrom: _colorSet.mainThemeForegroundColor,
    backgroundGradientTo: _colorSet.mainThemeForegroundColor,
    decimalPlaces: 0,
    paddingLeft: 0,
    color: () => `#119de2`,
};

const _dateRangeOptions = [
    { key: 'custom_range', label: 'Custom Range' },
    { key: 'last_week', label: 'Last Week' },
    { key: 'this_month', label: 'This Month' },
    { key: 'last_month', label: 'Last Month' },
    { key: 'year_to_date', label: 'Year to Date' },
    { key: 'lifetime', label: 'Lifetime' },
];

const _fontSet = {
    regular: 'AppleSDGothicNeo-Regular',
    bold: 'AppleSDGothicNeo-Bold',
};

const StyleDict = {
    colorSet: _colorSet,
    lightColorSet: _lightColorSet,
    darkColorSet: _darkColorSet,
    iconSizeSet: _iconSizeSet,
    sizeSet: _sizeSet,
    fontSizeSet: _fontSizeSet,
    styleSet: _styleSet,
    windowW: WINDOW_WIDTH,
    windowH: WINDOW_HEIGHT,
    utils: _functions,
    chartConfig: _chartConfig,
    dateRangeOptions: _dateRangeOptions,
    dateFormat: 'YYYY-MM-DD',
    fontSet: _fontSet,
    configSet: _configSet,
};

export default StyleDict;
