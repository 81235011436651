import React, {useEffect, useMemo, useRef, useState} from "react";
import {
	Text,
	View,
	TouchableOpacity, ScrollView, StyleSheet, Image, TextInput, ActivityIndicator
} from "react-native";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import API from "../../helpers/Axios";
import moment from 'moment';
import Socketio from 'socket.io-client';
import Echo from "laravel-echo";
import Moment from "moment";

function MessagesScreen ({navigation, route, auth, message}) {
	const textInput = useRef();
	const scrollViewRef = useRef();

	const [isLoading, setIsLoading] = useState(true);
	const [conversations, setConversations] = useState([]);
	const [fixedConversations, setFixedConversations] = useState([]);
	const [messages, setMessages] = useState([]);
	const [currentConversation, setCurrentConversation] = useState(null);
	const [isMessagesLoading, setIsMessagesLoading] = useState(true);
	const [searchString, setSearchString] = useState('');
	const [messageString, setMessageString] = useState('');
	const [currentBooking, setCurrentBooking] = useState(null);

	const sendMessage = () => {
		if(!messageString.length) {
			return
		}
		const data = new FormData();
		const lid = (+ new Date());
		data.append('conversation_id', currentConversation.id);
		data.append('lid', lid);
		data.append('message', messageString.trim());
		data.append('type', 'text');

		const message = {
			"lid": lid,
			"body": messageString.trim(),
			"is_sender": 1,
			"type": "text"
		}

		setMessages([...messages, message]);
		setMessageString('');
		scrollMessagesToBottom();
		API.post('chat/send-message', data)
			.then(res => {
				let message = res.data;
				console.log(message);
				textInput.current.focus();
			}).catch (error => {
			console.log(error)
		});
	}

	const getConversationTitle = (conversation) => {
		let array = [];
		conversation.participants.forEach((item) => {
			if(item.messageable_type !== 'App\\Models\\User') {
				array.push(item.messageable.participant_name)
			}
		})
		return array.join(', ')
	}

	const getConversationArtwork = (conversation) => {
		let array = [];
		conversation.participants.forEach((item) => {
			if(item.messageable_type === 'App\\Models\\Booking') {
				array.push(item.messageable.participant_artwork_url)
			}
		})
		return array[0]
	}

	const getBookingDetailFromConversation = (conversationId) => {
		API.post(`booking/get-details-from-conversation`, {id: conversationId})
			.then(res => {
				setCurrentBooking(res.data)
			}).catch (error => {
			console.log(error.response);
		});
	}

	const scrollMessagesToBottom = () => {
		setTimeout(() => {
			try {
				scrollViewRef.current.scrollToEnd({ animated: true })
			} catch (e) {

			}
		}, 500);
	}

		const getConversationById = (conversationId) => {
		setIsMessagesLoading(true);
		API.post(`chat/get-conversation-messages`, {id: conversationId})
			.then(res => {
				setMessages(res.data.data.reverse());
				setIsMessagesLoading(false);
				scrollMessagesToBottom();
			}).catch (error => {
			console.log(error.response);
		});
	}

	useEffect(() => {
		API.post(`chat/get-all-conversations`)
			.then(res => {
				setConversations(res.data.data);
				setFixedConversations(res.data.data);
				if(res.data.data.length) {
					setCurrentConversation(res.data.data[0]);
					getConversationById(res.data.data[0].id)
				}
				setIsLoading(false);
			}).catch (error => {
			console.log(error.response);
		});
	}, []);

	const usePrevious = (value) => {
		const ref = useRef();
		useEffect(() => {
			ref.current = value;
		});
		return ref.current;
	}

	const {receiveAmount, sendAmount } = message.messageWasReceived;

	const prevAmount = usePrevious({receiveAmount, sendAmount});

	useEffect(() => {
		if(prevAmount !== receiveAmount) {
			if(message.content.sender.id !== auth.user.id) {
				setMessages([...messages, message.content]);
				scrollMessagesToBottom();
			}
		}
	}, [message]);

	useEffect(() => {
		if(currentConversation) {
			getBookingDetailFromConversation(currentConversation.id)
		}
	}, [currentConversation])

	const renderMessages = () => {
		return messages.map((item, index) => (
			<View
				key={index}
				style={{
					backgroundColor: item.is_sender ? '#3DBA71' : 'white',
					borderRadius: 16,
					borderBottomRightRadius: item.is_sender ? 0 : 16,
					borderBottomLeftRadius: item.is_sender ? 16 : 0,
					padding: 10,
					maxWidth: 320,
					alignSelf: item.is_sender ? 'flex-end' : 'flex-start',
					marginBottom: 16,
				}}
			>
				<Text
					style={{
						fontSize: 16,
						lineHeight: 22,
						color: item.is_sender ? 'white' : 'black'
					}}
				>{item.body}</Text>
				<Text
					style={{
						marginTop: 8,
						fontSize: 12,
						color: item.is_sender ? 'white' : 'black'
					}}
				>{moment(item.created_at).format("h:mm a")}</Text>
			</View>
		))
	}

	return isLoading ? (<ActivityIndicator style={{flex: 1, justifyContent: 'center', alignItems: 'center'}} />) : (
		<View
			style={{
				flex: 1,
				padding: 40
			}}
		>
			<Text
				style={{
					fontSize: 48,
					fontWeight: '600'
				}}
			>Messages</Text>
			<Text
				style={{
					marginTop: 16,
					fontSize: 20,
					color: '#595959'
				}}
			>Check your message history</Text>
			<View
				style={{
					flexDirection: 'row',
					backgroundColor: 'white',
					borderWidth: 1,
					borderColor: '#E4E1E0',
					borderRadius: 8,
					marginTop: 40,
					flex: 1
				}}
			>
				<View
					style={{
						width: 340,
					}}
				>
					<View
						style={{
							flexDirection: 'row',
							alignItems: 'center',
							paddingTop: 16,
							paddingLeft: 16,
							paddingRight: 16
						}}
					>
						<Image
							source={{
								uri: auth.user.artwork_url
							}}
							resizeMode={'cover'}
							style={{
								width: 40,
								height: 40,
								borderRadius: 22,
								marginRight: 16,
							}}
						/>
						<Text
							style={{
								fontWeight: '600'
							}}
						>{auth.user.name}</Text>
					</View>
					<View
						style={{
							flexDirection: 'row',
							alignItems: 'center',
							borderRadius: 8,
							borderWidth: 1,
							borderColor: '#E4E1E0',
							height: 40,
							margin: 16,
							paddingLeft: 16
						}}
					>
						<svg width={25} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M11.5 4a7 7 0 1 0 0 14 7 7 0 0 0 0-14Zm-9 7a9 9 0 1 1 18 0 9 9 0 0 1-18 0Z"
								fill="#6D6E6F"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M16.443 15.943a1 1 0 0 1 1.414 0l4.35 4.35a1 1 0 0 1-1.414 1.414l-4.35-4.35a1 1 0 0 1 0-1.414Z"
								fill="#6D6E6F"
							/>
						</svg>
						<TextInput
							placeholder={'Search by username, email or telephone'}
							style={{
								flex: 1,
								borderColor: '#AFABAC',
								lineHeight: 36,
								outline: 0,
								marginHorizontal: 8,
								fontSize: 12
							}}
							value={searchString}
							onChangeText={text => {
								setSearchString(text);
								let result = fixedConversations.filter(item => item.conversation.subject.toLowerCase().includes(text));
								setConversations(result);
							}}
						/>
					</View>
					<ScrollView>
						{conversations.map((item, index) => (
							<TouchableOpacity
								onPress={() => {
									setCurrentConversation(item);
									getConversationById(item.id)
								}}
								key={index}
								style={{
									flexDirection: 'row',
									justifyContent: 'space-between',
									alignItems: 'center',
									padding: 16,
									borderBottomWidth: 1,
									borderColor: '#E4E1E0',
									backgroundColor: currentConversation.id === item.id ? '#DCF5E7' : 'transparent',
								}}
							>
								<View
									style={{
										flexDirection: 'row',
										flex: 1,
										alignItems: 'center'
									}}
								>
									<View
										style={{
											width: 32,
											height: 32,
											borderRadius: 16,
											borderWidth: 1,
											borderColor: '#6EB28B',
											overflow: 'hidden'
										}}
									>

										<Image
											style={{
												flex: 1,
											}}
											source={{
												uri: item.conversation.participants.find(participant => participant.messageable_id !== auth.user.id).messageable?.artwork_url ?? 'https://admin.stugo.com/storage/853/conversions/1698695030-lg.jpg'
											}}
										/>

									</View>
									<View
										style={{
											marginLeft: 12
										}}
									>
										<Text
											style={{
												fontSize: 16,
												fontWeight: '500',
											}}
										>{item.conversation.subject}</Text>
										{item.conversation.last_message && (
											<Text
												style={{
													marginTop: 4,
													color: '#909090'
												}}
												numberOfLines={1}
											>{item.conversation.last_message.body}</Text>
										)}
									</View>
								</View>
								<View>
									{item.conversation.last_message && (
										<Text
											style={{
												fontSize: 12,
												fontWeight: '600',
												color: '#595959'
											}}
										>{moment(item.conversation.last_message.created_at).format('MMM D')}</Text>
									)}
									{item.unread_count > 0 && (
										<View
											style={{
												marginTop: 4,
												width: 24,
												height: 24,
												borderRadius: 12,
												backgroundColor: '#19994F',
												justifyContent: 'center',
												alignItems: 'center'
											}}
										>
											<Text
												style={{
													fontSize: 12,
													fontWeight: '600',
													color: 'white'
												}}
											>{item.unread_count}</Text>
										</View>
									)}
								</View>
							</TouchableOpacity>
						))}
					</ScrollView>
				</View>
				<View
					style={{
						flex: 1,
						paddingHorizontal: 16,
						paddingVertical: 24,
						boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)'
					}}
				>
					{currentConversation && (
						<>
							<View
								style={{
									marginBottom: 24
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										fontSize: 20
									}}
								>{currentConversation.conversation.subject}</Text>
							</View>
							{isMessagesLoading ? (<ActivityIndicator style={{backgroundColor: '#f8f8f8', borderWidth: 1, borderColor: '#E4E1E0', flex: 1, justifyContent: 'center', alignItems: 'center'}} />) : (
								<ScrollView
									ref={scrollViewRef}
									style={{
										flex: 1,
										backgroundColor: '#f8f8f8' ,
										paddingLeft: 24,
										paddingRight: 24,
										paddingTop: 16,
										borderWidth: 1,
										borderColor: '#E4E1E0',
										overflow: 'hidden',
										borderRadius: 4
									}}
									onScroll={
										({ nativeEvent }) => {
											/*if (this.isCloseToBottom(nativeEvent) && !this.isLoadingMore && this.state.next_page_url) {
                          this.isLoadingMore = true;
                          this.setState({indicator: true});
                          this.fetch();
                      }*/
										}
									}
									onScrollToTop={() => {
										/*if(! this.isLoadingMore && this.state.next_page_url) {
                        this.isLoadingMore = true;
                        this.setState({indicator: true});
                        this.fetch();
                    }*/
									}}
									scrollEventThrottle={16}
								>
									{renderMessages()}
								</ScrollView>
							)}
							<View
								style={{
									marginTop: 16,
									backgroundColor: '#f8f8f8' ,
									paddingTop: 16,
									paddingLeft: 16,
									paddingRight: 16,
									borderWidth: 1,
									borderColor: '#E4E1E0',
									overflow: 'hidden',
									borderRadius: 8,
									paddingBottom:8,
								}}>
								<TextInput
									ref={textInput}
									placeholder={`Send message to ${currentConversation.conversation.subject}`}
									style={{
										borderColor: '#AFABAC',
										flex: 1,
										outline: 0,
										fontSize: 16
									}}
									value={messageString}
									onChangeText={text => {
										setMessageString(text)
									}}
									multiline={true}
									numberOfLines={4}
									blurOnSubmit={true}
									onSubmitEditing={() => sendMessage()}
								/>
								<View
									style={{
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'space-between'
									}}
								>
									<TouchableOpacity
										style={{
											backgroundColor: 'white',
											height: 26,
											borderRadius: 13,
											paddingHorizontal: 8,
											flexDirection: 'row',
											alignItems: 'center'
										}}
									>
										<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M10.52 1.592c-.53 0-1.04.21-1.415.586L2.978 8.305a3.336 3.336 0 1 0 4.717 4.717l6.127-6.127a.667.667 0 1 1 .943.943l-6.127 6.127a4.669 4.669 0 1 1-6.603-6.603l6.127-6.127a3.335 3.335 0 1 1 4.716 4.716l-6.133 6.127a2 2 0 0 1-2.83-2.83l5.66-5.653a.667.667 0 1 1 .943.943l-5.66 5.653a.668.668 0 0 0 .944.944l6.133-6.126a2.002 2.002 0 0 0-1.415-3.417Z"
												fill="#6D6E6F"
											/>
										</svg>
										<Text
											style={{
												marginLeft: 8,
												fontSize: 12,
												color: '#595959'
											}}
										>Attach file - PDF, JPG, PNG</Text>
									</TouchableOpacity>
									<TouchableOpacity
										onPress={() => sendMessage()}
										style={{
											flexDirection: 'row',
											alignItems: 'center',
										}}
									>
										<Text
											style={{
												marginRight: 8,
												color: '#096730',
												fontWeight: '600'
											}}
										>Send message</Text>
										<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M15.138.862c.26.26.26.682 0 .943L7.805 9.138a.667.667 0 1 1-.943-.943L14.195.862c.26-.26.683-.26.943 0Z"
												fill="#096730"
											/>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M15.138.862c.181.181.243.45.158.692l-4.667 13.333a.667.667 0 0 1-1.238.05L6.828 9.172 1.063 6.609a.667.667 0 0 1 .05-1.238L14.447.704a.667.667 0 0 1 .691.158ZM3.141 6.074l4.463 1.983c.151.067.272.188.339.339l1.983 4.463L13.58 2.42 3.14 6.074Z"
												fill="#096730"
											/>
										</svg>
									</TouchableOpacity>
								</View>
							</View>
						</>
					)}
				</View>
			</View>
		</View>
	);
}

MessagesScreen.propTypes = {
	navigation: PropTypes.object,
};

export default connect(({auth, message}) => ({auth, message}))(MessagesScreen);
