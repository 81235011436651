import React, {useEffect, useMemo, useState} from "react";
import {
	Text,
	View,
	ActivityIndicator, ScrollView, StyleSheet, Image
} from "react-native";
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, ReferenceLine, ReferenceArea,
	ReferenceDot, Tooltip, CartesianGrid, Legend, Brush, ErrorBar, AreaChart, Area,
	Label, LabelList } from 'recharts';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import API from "../../helpers/Axios";
import moment from 'moment';
import {TouchableOpacity} from "react-native-web";
import useModal from "../../helpers/useModal";
import AppStyles from "../../AppStyles";

function EarningsScreen ({navigation, route}) {
	const [isLoading, setIsLoading] = useState(true);
	const [studios, setStudios] = useState([]);
	const [currentStudio, setCurrentStudio] = useState(null);
	const [stats, setStats] = useState(null);

	const [bookings, setBookings] = useState([]);
	const [currentRoom, setCurrentRoom] = useState({id: 0});
	const [allRoomBookings, setAllRoomBookings] = useState([]);
	const [isEarningDataLoading, setIsEarningDataLoading] = useState(true);
	const [chartData, setChartData] = useState([]);
  const [revenueData, setRevenueData] = useState(null);
  const [currentQuarter, setCurrentQuarter] = useState(moment().quarter());

  const getQuarterSpent = (quarter, data) => {
    let index = data.findIndex(item => item.quarter === quarter);
    return index >= 0 ? data[index] : {quarter: quarter, total: 0};
  }

	useEffect(() => {
		API.get('owner/earnings')
			.then(res => {
				setStudios(res.data.studios);
				setCurrentStudio(res.data.studios[0]);
				setIsLoading(false);
				getEarningByStudio(res.data.studios[0].id);
			}).catch (error => {
			console.log(error);
		});
  }, []);

	const parseChartData = (data) => {
		let array = [];
		Object.keys(data).forEach(function(key) {
			let item = {
				name: moment(key).format('MMM D'),
				revenue: data[key],
			}
			array.push(item)
		});

		return array
	}

	const getEarningByStudio = (studioId) => {
		setIsEarningDataLoading(true);
		API.get(`owner/earnings/studio/${studioId}`)
			.then(res => {
				setCurrentStudio(res.data.studio);
				setStats(res.data.stats);
				setBookings(res.data.bookings);
				setAllRoomBookings(res.data.bookings);
				setIsEarningDataLoading(false);
				setChartData(parseChartData(res.data.stats.chartData));
        setCurrentQuarter(getQuarterSpent(moment().quarter(), res.data.stats.bookings.quarter));
        setRevenueData(res.data.stats.bookings);
			}).catch (error => {
			console.log(error);
		});
	}

	return isLoading ? (<ActivityIndicator style={{flex: 1, justifyContent: 'center', alignItems: 'center'}} />) : (
		<ScrollView
			style={{
				flex: 1,
				padding: 40
			}}
		>
			<Text
				style={{
					fontSize: 48,
					fontWeight: '600'
				}}
			>Earnings</Text>
			<Text
				style={{
					marginTop: 16,
					fontSize: 20,
					color: '#595959'
				}}
			>A detailed breakdown of your studios revenue.</Text>
			<ScrollView
				horizontal={true}
				style={{
					marginVertical: 40,
					flexDirection: 'row'
				}}
			>
				{studios.map((item, index) => (
					<TouchableOpacity
						key={index}
						onPress={() => {
							setCurrentStudio(item);
							getEarningByStudio(item.id);
						}}
						style={{
							width: 364,
							flexDirection: 'row',
							borderWidth: item.id === currentStudio.id ? 2 : 1,
							borderColor: item.id === currentStudio.id ? '#6EB28B' : '#E4E1E0',
							padding: 8,
							borderRadius: 8,
							backgroundColor: 'white',
							marginRight: 24
						}}
					>
						<View
							style={{
								width: 128,
								aspectRatio: 1.777,
								borderRadius: 4,
								backgroundColor: 'gray',
								marginRight: 16,
								overflow: 'hidden'
							}}
						>
							<Image
								style={{
									flex: 1
								}}
								source={{
									uri: item.artwork_url,
								}}
							/>
						</View>
						<View
							style={{
								flex: 1,
								justifyContent: 'center'
							}}
						>
							<Text
								style={{
									fontWeight: '600',
									color: '#096730',
									fontSize: 18
								}}
							>{item.name}</Text>
							<Text
								style={{
									fontWeight: '600',
									marginTop: 4,
									color: '#595959'
								}}
								numberOfLines={2}
							>{item.address}</Text>
						</View>
					</TouchableOpacity>
				))}
			</ScrollView>
			{isEarningDataLoading && (
				<View
					style={{
						height: 180,
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<ActivityIndicator color={'#096730'} />
				</View>
			)}
			{!isEarningDataLoading && (
				<>
					<View
						style={{
							flexDirection: 'row',
							marginHorizontal: -8
						}}
					>
						{[
							{
								title: 'Gross earnings',
                subText: 'Lifetime revenue for your studio.',
								value: `$${stats.bookings.revenue}`,
								icon: <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M6 15h1M17 7h1M10.232 12.768a2.5 2.5 0 1 1 3.536-3.536"
										stroke="#6D6E6F"
										strokeWidth={1.5}
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M8 18H6a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v4"
										stroke="#6D6E6F"
										strokeWidth={1.5}
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										clipRule="evenodd"
										d="M12.5 17h2a.5.5 0 0 1 .5.5V21h-2.5a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5ZM15.5 13h2a.5.5 0 0 1 .5.5V21h-3v-7.5a.5.5 0 0 1 .5-.5ZM18.5 15h2a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5H18v-5.5a.5.5 0 0 1 .5-.5Z"
										stroke="#6D6E6F"
										strokeWidth={1.5}
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							},
							{
								title: 'Current balance',
                subText: 'Revenue available to be paid out within 24hrs.',
								value: `$${AppStyles.utils.moneyFormat(stats.bookings.balance)}`,
								icon: <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M12 15.9H3.9A1.9 1.9 0 0 1 2 14V4.9C2 3.85 2.85 3 3.9 3h15.2c1.05 0 1.9.85 1.9 1.9V10"
										stroke="#6D6E6F"
										strokeWidth={1.5}
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M13.179 7.97a2.376 2.376 0 0 1-3.36 3.36 2.376 2.376 0 0 1 3.36-3.36M22 16.8V14c0-.774-1.568-1.4-3.5-1.4s-3.499.627-3.5 1.4v5.6c.001.773 1.567 1.4 3.5 1.4s3.499-.627 3.5-1.4V14"
										stroke="#6D6E6F"
										strokeWidth={1.5}
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M15.001 14c0 .773 1.567 1.4 3.5 1.4S22 14.773 22 14M15.001 16.8c0 .773 1.567 1.4 3.5 1.4s3.5-.627 3.5-1.4M6 13H5M18 6h-1"
										stroke="#6D6E6F"
										strokeWidth={1.5}
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							}
						].map((item, index) => (
							<View
								key={index}
								style={{
									flex: 1,
									paddingHorizontal: 8,
								}}
							>
								<View
									style={{
										flex: 1,
										backgroundColor: 'white',
										padding: 16,
										borderRadius: 8,
										shadowColor: "#000",
										shadowOffset: {
											width: 0,
											height: 2,
										},
										shadowOpacity: 0.05,
										shadowRadius: 3.84,
										elevation: 5,
									}}
								>
									{item.icon}
									<Text
										style={{
											fontSize: 32,
											fontWeight: '600',
											marginTop: 16,
											color: '#096730'
										}}
									>{item.value}</Text>
									<Text
										style={{
											fontSize: 20,
											marginTop: 16,
											fontWeight: '600'
										}}
									>{item.title}</Text>
									<Text
										style={{
											marginTop: 8,
											color: '#595959'
										}}
									>{item.subText}.</Text>
								</View>
							</View>
						))}
            {revenueData && (
              <View
                style={{
                  paddingHorizontal: 8,
                  flex: 1
                }}
              >
                <View
                  style={{
                    padding: 16,
                    borderRadius: 8,
                    backgroundColor: 'white',
                    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)',
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.0021 6.99801H16.0017" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M5.99756 14.0009H6.99798" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M9.99925 17.0022H5.49738C4.11609 17.0022 2.99634 15.8825 2.99634 14.5012V6.49787C2.99634 5.83455 3.25984 5.1984 3.72888 4.72936C4.19791 4.26033 4.83406 3.99683 5.49738 3.99683H17.5024C18.8837 3.99683 20.0034 5.11658 20.0034 6.49787V9.99933" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M9.73132 12.2681C8.7546 11.2914 8.7546 9.70779 9.73132 8.73107C10.708 7.75436 12.2916 7.75436 13.2683 8.73107" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M17.5025 21.0039C15.6817 21.0039 14.0401 19.907 13.3433 18.2248C12.6465 16.5426 13.0317 14.6062 14.3192 13.3187C15.6067 12.0312 17.5431 11.646 19.2253 12.3428C20.9075 13.0396 22.0044 14.6812 22.0044 16.502C22.0044 18.9883 19.9888 21.0039 17.5025 21.0039" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M17.3953 14.9846V16.7389L18.778 17.5824" stroke="#6D6E6F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <View
                      style={{
                        flexDirection: 'row',
                      }}
                    >
                      {[
                        {
                          title: 'Q1',
                          quarter: 1,
                          action: () => {
                            setCurrentQuarter(getQuarterSpent(1, revenueData.quarter));
                          }
                        },
                        {
                          title: 'Q2',
                          quarter: 2,
                          action: () => {
                            setCurrentQuarter(getQuarterSpent(2, revenueData.quarter));
                          }
                        },
                        {
                          title: 'Q3',
                          quarter: 3,
                          action: () => {
                            setCurrentQuarter(getQuarterSpent(3, revenueData.quarter));
                          }
                        },
                        {
                          title: 'Q4',
                          quarter: 4,
                          action: () => {
                            setCurrentQuarter(getQuarterSpent(4, revenueData.quarter));
                          }
                        },
                      ].map((item, index) => (
                        <TouchableOpacity
													key={index}
                          onPress={item.action}
                          style={{
                            backgroundColor: currentQuarter.quarter === item.quarter ? '#AFABAC' : '#F4F2F1',
                            marginLeft: 8,
                            padding: 4,
                            borderRadius: 2
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 12,
                              fontWeight: '600',
                              color: currentQuarter.quarter === item.quarter ? 'black' : '#909090'
                            }}
                          >{item.title}</Text>
                        </TouchableOpacity>
                      ))}
                    </View>
                  </View>
                  <Text
                    style={{
                      fontWeight: '600',
                      color: '#096730',
                      fontSize: 34,
                      marginVertical: 16
                    }}
                  >${AppStyles.utils.moneyFormat(currentQuarter.total)}</Text>
                  <Text
                    style={{
                      fontSize: 20,
                      fontWeight: '600'
                    }}
                  >Q{currentQuarter.quarter} Quarterly clients spent</Text>
                  <Text
                    style={{
                      marginTop: 8,
                      fontSize: 12,
                      color: '#595959'
                    }}
                  >Revenue in <strong>Quarter {currentQuarter.quarter} {moment().year()}</strong></Text>
                </View>
              </View>
            )}
					</View>
					<View
						style={{
							marginTop: 40,
							backgroundColor: 'white',
							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: 2,
							},
							shadowOpacity: 0.05,
							shadowRadius: 3.84,
							elevation: 5,
							borderRadius: 8,
							padding: 24
						}}
					>
						<ResponsiveContainer
							width="100%"
							aspect={2.5}
						>
							<LineChart
								data={chartData}
							>
								<CartesianGrid strokeDasharray="3 3" />
								<YAxis />
								<XAxis dataKey="name" />
								<Tooltip />
								<Line type="monotone" dataKey="revenue" stroke="rgb(9, 103, 48)" activeDot={{ r: 8 }} strokeWidth={2}/>
							</LineChart>
						</ResponsiveContainer>
					</View>
					<View
						style={{
							marginTop: 40,
							backgroundColor: 'white',
							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: 2,
							},
							shadowOpacity: 0.05,
							shadowRadius: 3.84,
							elevation: 5,
							borderRadius: 8
						}}
					>
						<View
							style={{
								flexDirection: 'row'
							}}
						>
							<TouchableOpacity
								onPress={() => {
									setCurrentRoom({id: 0});
									setBookings(allRoomBookings);
								}}
								style={{
									borderBottomWidth: 2,
									borderColor: currentRoom.id === 0 ? '#096730' : 'white',
									alignSelf: 'flex-start',
									paddingHorizontal: 16
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										fontSize: 16,
										color: '#096730',
										lineHeight: 40
									}}
								>All rooms</Text>
							</TouchableOpacity>
							{currentStudio.rooms.map((item, index) => (
								<TouchableOpacity
									key={index}
									onPress={() => {
										setCurrentRoom(item);
									}}
									style={{
										borderBottomWidth: 2,
										borderColor: currentRoom.id === item.id ? '#096730' : 'white',
										alignSelf: 'flex-start',
										paddingHorizontal: 16
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											fontSize: 16,
											color: currentRoom.id === item.id ? 'black' : '#096730',
											lineHeight: 40
										}}
									>{item.name}</Text>
								</TouchableOpacity>
							))}
						</View>
						<table
							style={{
								flex: 1,
								width: '100%',
								borderCollapse: 'collapse'
							}}
						>
							<tr
								style={{
									backgroundColor: '#F8F8F8',
									height: 48
								}}
							>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
									<Text
										style={styles.tableHeader}
									>Amenities</Text>
								</th>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
									<Text
										style={styles.tableHeader}
									>Last 30 days</Text>
								</th>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
									<Text
										style={styles.tableHeader}
									>Total</Text>
								</th>
							</tr>
							{bookings.map((bookingItem, bookingIndex) => (
								<>
									{bookingItem.booking_amenities.map((amenity, amenityIndex) => amenity.amenity && (
										<tr
											key={amenityIndex}
											style={{
												height: 64,
											}}
										>
											<td
												style={{
													borderBottom: '1px solid',
													borderBottomColor: '#E4E1E0',
													paddingLeft: 16,
													paddingRight: 16
												}}
											>
												<View>
													<Text>{amenity.amenity.name} ({bookingItem.room.name})</Text>
													<Text
														style={{
															color: '#909090',
															marginTop: 4,
															fontWeight: '600'
														}}
													>{bookingItem.user.name} | {bookingItem.user.email}</Text>
												</View>
											</td>
											<td
												style={{
													borderBottom: '1px solid',
													borderBottomColor: '#E4E1E0',
													paddingLeft: 16,
													paddingRight: 16
												}}
											>
												<Text>{moment(bookingItem.created_at).format("MMM D, YYYY")}</Text>
											</td>
											<td
												style={{
													borderBottom: '1px solid',
													borderBottomColor: '#E4E1E0',
													paddingLeft: 16,
													paddingRight: 16
												}}
											>
												<Text>$ {amenity.cost}</Text>
											</td>
										</tr>
									))}
								</>
							))}
							<tr
								style={{
									backgroundColor: '#F8F8F8',
									height: 48
								}}
							>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
									<Text
										style={styles.tableHeader}
									>Equipments</Text>
								</th>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
									<Text
										style={styles.tableHeader}
									>Last 30 days</Text>
								</th>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
									<Text
										style={styles.tableHeader}
									>Total</Text>
								</th>
							</tr>
							{bookings.map((bookingItem, bookingIndex) => (
								<>
									{bookingItem.booking_equipments.map((equipment, equipmentIndex) => equipment.equipment && (
										<tr
											key={equipmentIndex}
											style={{
												height: 64,
											}}
										>
											<td
												style={{
													borderBottom: '1px solid',
													borderBottomColor: '#E4E1E0',
													paddingLeft: 16,
													paddingRight: 16
												}}
											>
												<View>
													<Text>{equipment.equipment.name} ({bookingItem.room.name})</Text>
													<Text
														style={{
															color: '#909090',
															marginTop: 4,
															fontWeight: '600'
														}}
													>{bookingItem.user.name} | {bookingItem.user.email}</Text>
												</View>
											</td>
											<td
												style={{
													borderBottom: '1px solid',
													borderBottomColor: '#E4E1E0',
													paddingLeft: 16,
													paddingRight: 16
												}}
											>
												<Text>{moment(bookingItem.created_at).format("MMM D, YYYY")}</Text>
											</td>
											<td
												style={{
													borderBottom: '1px solid',
													borderBottomColor: '#E4E1E0',
													paddingLeft: 16,
													paddingRight: 16
												}}
											>
												<Text>$ {equipment.cost}</Text>
											</td>
										</tr>
									))}
								</>
							))}
						</table>
					</View>
					<View
						style={{
							marginTop: 40,
							marginBottom: 16,
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center'
						}}
					>
					</View>
				</>
			)}
		</ScrollView>
	);

}


const styles = StyleSheet.create({
	tableHeader: {
		fontWeight: '600',
		textAlign: 'left',
		alignSelf: 'flex-start'
	},
	tableCell: {
		borderBottom: '1px solid',
		borderBottomColor: 'gray',
		paddingLeft: 16,
		paddingRight: 16
	},
});

EarningsScreen.propTypes = {
	navigation: PropTypes.object,
};

export default connect(({auth}) => ({auth}))(EarningsScreen);