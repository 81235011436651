import React, {useCallback, useState} from "react";
import {
	Text,
	View,
	TextInput, ImageBackground, ActivityIndicator
} from "react-native";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import API from "../../helpers/Axios";
import {TouchableOpacity} from "react-native-web";
import FieldSetTextInput from "../../components/FieldSetTextInput";
import FieldSetTextInputWithPhone from "../../components/FieldSetTextInputWithPhone";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {countriesArray} from "../../helpers/CountryCode";
import {store} from "../../store/configureStore";
import {GoogleReCaptcha} from "react-google-recaptcha-v3";

const VerifyPhoneScreen = ({navigation, auth}) => {
	const [token, setToken] = useState();
	const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
	const [stepIndex, setStepIndex] = useState(0)
	const [countryCode, setCountryCode] = useState('US')
	const [isSubmitting, setIsSubmitting] = useState( false);
	const [verificationCode, setVerificationCode] = useState( '');
	const [errorMessage, setErrorMessage] = useState('');
	const [botsDetected, setBotDetected] = useState(false);

	const onVerify = useCallback((token) => {
		setToken(token);
	});

	const [phoneNumber, setPhoneNumber] = useState({
		value: '',
		error: false,
		errorString: null
	});

  const onSubmit = () => {
    if(isSubmitting) {
      return
    }
    const data = new FormData();
    data.append('phoneNumber', phoneNumber.value);
    data.append('callingCode', countriesArray.find(x => x.code === countryCode).dial_code);
    data.append('countryCode', countryCode);
    data.append('token', token);

    API.post('auth/verify-phone-number', data)
      .then(res => {
        setIsSubmitting(false);
        setStepIndex(1);
      }).catch (error => {
      if(error.response.status === 400) {
        setBotDetected(true);
      } else {
        setBotDetected(false);
        setPhoneNumber({
          value: phoneNumber.value,
          error: error.response.data.errors['phone_number'] !== undefined,
          errorString: error.response.data.errors['phone_number'] !== undefined ? error.response.data.errors['phone_number'][0] : 'Error'
        });
      }
      setIsSubmitting(false)
    });
  }

	const onVerifyPhoneSubmit = () => {
		if(isSubmitting) {
			return
		}
		const data = new FormData();
		data.append('phoneNumber', phoneNumber.value);
		data.append('callingCode', countriesArray.find(x => x.code === countryCode).dial_code);
		data.append('countryCode', countryCode);
		data.append('verificationCode', verificationCode);

    API.post('auth/verify-phone-number-submit', data)
			.then(res => {
				setIsSubmitting(false)
				API.post('auth/user')
					.then(res => {
						store.dispatch({type: 'UPDATE_USER_INFO', user: res.data});
						navigation.navigate('OnBoardingStack');
					})
			}).catch (error => {
			setErrorMessage(error.response.data.errors[Object.keys(error.response.data.errors)[0]][0])
			setIsSubmitting(false);
		});
	}

	return (
		<ImageBackground
			source={{uri: '/assets/splash/1.jpeg'}}
			style={{
				position: 'absolute',
				flex: 1,
				width: '100%',
				height: '100vh',
				margin: 0,
				padding: 0,
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: '#1D1F1E',
			}}
		>
			<View
				style={{
					position: 'absolute',
					backgroundColor: 'rgba(29, 31, 30, 0.4)',
					top: 0,
					right: 0,
					left: 0,
					bottom: 0
				}}
			/>
			<View
				style={{
          width: '90%',
          maxWidth: 480,
				}}
			>
				{stepIndex === 0 && (
					<View
						style={{
							padding: 48,
							backgroundColor: 'white',
							borderRadius: 8,
						}}
					>
						<Text
							style={{
								color: '#3DBA71',
								fontSize: 34,
								marginBottom: 24,
								fontWeight: '600',
							}}
						>Confirm your phone number</Text>
						<Text
							style={{
								fontSize: 20,
								marginBottom: 24,
								color: '#6D6E6F'
							}}
						>Stugo uses a phone verification for easier login and security purposes, please verify your phone number to proceed.</Text>
						<View>
              <View
                style={{
                  marginTop: 40
                }}
              >
                <FieldSetTextInputWithPhone
                  name={'Phone'}
                  placeholder={'Phone number'}
                  value={phoneNumber.value || ''}
                  error={phoneNumber.error}
                  errorString={phoneNumber.errorString}
                  onChangeText={text => {
                    setPhoneNumber({
                      value: text.replace(/[^0-9]/g, ''),
                      error: false,
                      errorString: phoneNumber.errorString
                    });
                  }}
                  onChangeCountryCode={text => {
                    setCountryCode(text);
                  }}
                />
              </View>
						</View>
						{botsDetected && (
							<Text
								style={{
									marginTop: 16,
									marginBottom: -24,
									fontWeight: '500',
									color: 'red',
									fontSize: 24
								}}
							>Are you a robot?</Text>
						)}
						<GoogleReCaptcha
							onVerify={onVerify}
							refreshReCaptcha={refreshReCaptcha}
						/>
						<TouchableOpacity
							onPress={() =>  {
								setIsSubmitting(true);
								onSubmit();
							}}
							style={{
								backgroundColor: '#3DBA71',
								alignItems: 'center',
								justifyContent: 'center',
								borderRadius: 4,
								height: 48,
								marginTop: 40
							}}
						>
							{isSubmitting && <ActivityIndicator color={'white'}/>}
							{!isSubmitting && <Text
								style={{
									color: 'white',
									fontSize: 16,
									fontWeight: '600'
								}}
							>Save & Continue</Text>}
						</TouchableOpacity>
						<View
							style={{
								flexDirection: 'row',
								marginTop: 24
							}}
						>
							<svg width={14} height={15} fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M9.917 5.458h.583a1.17 1.17 0 0 1 1.167 1.167v5.833a1.17 1.17 0 0 1-1.167 1.167h-7a1.17 1.17 0 0 1-1.167-1.167V6.625A1.17 1.17 0 0 1 3.5 5.458h.583V4.292a2.918 2.918 0 0 1 5.834 0v1.166ZM7 2.542c-.968 0-1.75.781-1.75 1.75v1.166h3.5V4.292c0-.969-.782-1.75-1.75-1.75Zm-3.5 9.916V6.625h7v5.833h-7Zm4.667-2.916A1.17 1.17 0 0 1 7 10.708a1.17 1.17 0 0 1-1.167-1.166A1.17 1.17 0 0 1 7 8.375a1.17 1.17 0 0 1 1.167 1.167Z"
									fill="#8692A6"
								/>
							</svg>
							<Text
								style={{
									marginLeft: 8,
									color: '#8692A6'
								}}
							>Your Info is safely secured</Text>
						</View>
					</View>
				)}
				{stepIndex === 1 && (
          <View
						style={{
							padding: 48,
							backgroundColor: 'white',
							borderRadius: 8,
						}}
					>
            <Text
                style={{
                  color: '#3DBA71',
                  fontSize: 34,
                  marginBottom: 24,
                  fontWeight: '600',
                }}
            >Hi, {auth.user.name}!</Text>
            <Text
              style={{
                fontSize: 28,
                marginBottom: 24,
                color: '#6D6E6F',
                fontWeight: '600',
              }}
            >Please confirm your phone number</Text>
              <Text
                style={{
                  fontSize: 20,
                  marginBottom: 4,
                  color: '#6D6E6F'
                }}
              >Please, enter the code sent to:</Text>
              <Text
                style={{
                  fontSize: 20,
                  marginBottom: 24,
                  color: '#3DBA71'
                }}
              >+{countriesArray.find(x => x.code === countryCode).dial_code} {phoneNumber.value}</Text>
						<View>
							<Text
								style={{
									marginBottom: 4,
									color: '#6D6E6F'
								}}
							>Confirmation code</Text>
							<View
								style={{
									borderWidth: 1,
									borderColor: '#8692A6',
									borderRadius: 6,
									height: 64,
									marginTop: 8
								}}
							>
								<TextInput
									placeholder={'00000'}
									maxLength={5}
									style={{
										flex: 1,
										outline: 0,
										marginHorizontal: 16,
										fontSize: 24,
										textAlign: 'center',
										letterSpacing: 3,
										fontWeight: '600',
										color: '#6D6E6F'
									}}
									placeholderTextColor={'#bdbdbd'}
									value={verificationCode}
									onChangeText={text => {
										setVerificationCode(text);
									}}
								/>
								<View
									style={{
										flexDirection: 'row',
										justifyContent: 'space-between',
                    alignItems: 'center',
										marginTop: 8,
										position: 'absolute',
										height: 24,
										bottom: -32,
										left: 0,
										right: 0
									}}
								>
                  <Text
                    style={{
                      color: '#A42323'
                    }}
                  >{!!errorMessage.length && errorMessage}</Text>
                  <TouchableOpacity
                    style={{
                      justifySelf: 'flex-end'
                    }}
                    onPress={() => {

                    }}
                  >
                    <Text
                      style={{
                        color: '#1A1A1A',
                        fontWeight: '600'
                      }}
                    >Resend code</Text>
                  </TouchableOpacity>
								</View>
							</View>
						</View>
						<TouchableOpacity
							onPress={() =>  {
								setIsSubmitting(true);
                onVerifyPhoneSubmit();
							}}
							style={{
								backgroundColor: '#3DBA71',
								alignItems: 'center',
								justifyContent: 'center',
								borderRadius: 4,
								height: 48,
								marginTop: 40
							}}
						>
							{isSubmitting && <ActivityIndicator color={'white'}/>}
							{!isSubmitting && <Text
								style={{
									color: 'white',
									fontSize: 16,
									fontWeight: '600'
								}}
							>Confirm phone number</Text>}
						</TouchableOpacity>
					</View>
				)}
			</View>
		</ImageBackground>
	)

}

VerifyPhoneScreen.propTypes = {
	navigation: PropTypes.object,
};

export default connect(({auth}) => ({auth}))(VerifyPhoneScreen);