import React  from "react";
import { View } from "react-native";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/lib/integration/react';
import {store, persistor} from './store/configureStore';
import AppNavigator from "./navigations/AppNavigator";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import './index.css';

function App() {
  return (

      <Provider store={store}>
        <PersistGate loading={<View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignContent: 'center',
            backgroundColor: '#2B2B2B'
          }}
        >
        </View>} persistor={persistor}>
          <GoogleReCaptchaProvider
            reCaptchaKey="6LdKg7MhAAAAAIBhSgeGLVqHQIfaPNJRDK2L-PYa"
            scriptProps={{
              async: false, // optional, default to false,
              defer: false, // optional, default to false
              appendTo: 'head', // optional, default to "head", can be "head" or "body",
              nonce: undefined // optional, default undefined
            }}
          >
        <AppNavigator />
          </GoogleReCaptchaProvider>
        </PersistGate>
      </Provider>

  );
}

export default App;
