import React, {useEffect, useRef, useState} from 'react';
import {
	ActivityIndicator,
	Text,
	View,
	ScrollView,
	TouchableOpacity,
	TouchableOpacityComponent,
	TextInput
} from "react-native";
import moment from "moment";
import StyleDict from "../../AppStyles";
import AppStyles from "../../AppStyles";
import {useTheme} from "@react-navigation/native";
import BookingStatus from "../../components/BookingStatus";
import API from "../../helpers/Axios";
import {FileUploader} from "react-drag-drop-files";
import ConversationBoxScreen from "../../components/ConversationBoxScreen";

function BookingDetailsScreen ({ setIsVisible, item }) {
	const {colors, dark} = useTheme()
	const [isSubmitting, setIsSubmitting] = useState( false);
	const [isRejectSubmitting, setRejectIsSubmitting] = useState( false);
	const [data, setData] = useState(item);
	const [status, setStatus] = useState(item.status);
	const [code, setCode] = useState(item.confirm_code);
	const [infoActive, setInfoActive] = useState(true);
	const [uploadActive, setUploadActive] = useState(true);
	const [uploadFiles, setUploadFiles] = useState(null);
	const [exceededError, setExceededError] = useState(false);
	const [uploadPercentage, setUploadPercentage] = useState([]);
	const [stateChange, setStateChange] = useState(0);
	const [attachments, setAttachments] = useState([]);
  const [approveFault, setApproveFault] = useState(false);
  const [approveFaultMessage, setApproveFaultMessage] = useState('');

	const fileTypes = ["JPEG", "PNG", "GIF", 'PDF', 'JPEG', 'ISO', 'ZIP', 'mp3', 'm4a', 'wav', 'mp3', 'm4a', 'wav', 'mp4', 'm4v', 'mpg', 'wmv', 'mov', 'avi'];
	const [files, setFiles] = useState(null);

	const handleChange = (selectedFiles) => {
		let files = [];
		if(getTotalFilesSize(selectedFiles) < 2147483648) {
			Array.from(selectedFiles).forEach(file => {
				file.hash = AppStyles.utils.genUid();
				file.isUploading = false;
				files.push(file);
			});
		} else {
			setExceededError(true)
		}

		let newUploadFiles = uploadFiles;
		if(newUploadFiles && newUploadFiles.length) {
			newUploadFiles = newUploadFiles.concat(files);
		} else {
			newUploadFiles = files;
		}

		files.forEach((file) => {
			const formData = new FormData();
			formData.append('id', data.id);
			formData.append('file', file);
			API.post('booking/upload-file', formData, {
				onUploadProgress: progressEvent => {
					let percentComplete = progressEvent.loaded / progressEvent.total
					percentComplete = parseInt(percentComplete * 100);
					let data = uploadPercentage;
					let index = data.findIndex(x => x.hash === file.hash);
					if(index >= 0) {
						data[index].percent = percentComplete;
						setUploadPercentage([...data]);
					} else {
						let update = {};
						update.percent = percentComplete;
						update.hash = file.hash;
						data.push(update);
						setUploadPercentage([...data]);
					}
				}
			}).then(res => {
				loadBookingAttachments()
			}).catch (error => {
				console.log(error);
			});
		})
		setUploadFiles(prev => ([...newUploadFiles]));
		setFiles(selectedFiles);
	}

	const getTotalFilesSize = (files) => {
		let size = 0;
		Array.from(files).forEach(file => {
			size = size + file.size;
		});
		return size;
	}

	const loadBookingAttachments = () => {
		API.post('booking/attachments', {
			id: data.id
		}).then(res => {
			setAttachments(res.data)
		}).catch (error => {
			console.log(error);
		});
	}

	useEffect(() => {
		loadBookingAttachments()
	}, []);

	const [tabIndex, setTabIndex] = useState(0);

	const textInput = useRef();
	const scrollViewRef = useRef();
	const [isLoading, setIsLoading] = useState(true);
	const [conversations, setConversations] = useState([]);
	const [fixedConversations, setFixedConversations] = useState([]);
	const [messages, setMessages] = useState([]);
	const [currentConversation, setCurrentConversation] = useState(null);
	const [isMessagesLoading, setIsMessagesLoading] = useState(true);
	const [searchString, setSearchString] = useState('');
	const [messageString, setMessageString] = useState('');
	const [currentBooking, setCurrentBooking] = useState(null);

	const getConversationById = (conversationId) => {
		setIsMessagesLoading(true);
		API.post(`chat/get-conversation-messages`, {id: conversationId})
			.then(res => {
				setMessages(res.data.data.reverse());
				setIsMessagesLoading(false);
				scrollMessagesToBottom();
			}).catch (error => {
			console.log(error.response);
		});
	}

	useEffect(() => {
		API.post(`chat/get-all-conversations`)
			.then(res => {
				setConversations(res.data.data);
				setFixedConversations(res.data.data);
				if(res.data.data.length) {
					setCurrentConversation(res.data.data[0]);
					getConversationById(res.data.data[0].id)
				}
				setIsLoading(false);
			}).catch (error => {
			console.log(error.response);
		});
	}, []);

	const scrollMessagesToBottom = () => {
		setTimeout(() => {
			try {
				scrollViewRef.current.scrollToEnd({ animated: true })
			} catch (e) {

			}
		}, 500);
	}

	const sendMessage = () => {
		if(!messageString.length) {
			return
		}
		const data = new FormData();
		const lid = (+ new Date());
		data.append('conversation_id', currentConversation.id);
		data.append('lid', lid);
		data.append('message', messageString.trim());
		data.append('type', 'text');

		const message = {
			"lid": lid,
			"body": messageString.trim(),
			"is_sender": 1,
			"type": "text"
		}

		setMessages([...messages, message]);
		setMessageString('');
		scrollMessagesToBottom();
		API.post('chat/send-message', data)
			.then(res => {
				let message = res.data;
				console.log(message);
				textInput.current.focus();
			}).catch (error => {
			console.log(error)
		});
	}

	return (
		<View
			style={{
				flex: 1,
				maxHeight: '90vh'
			}}
		>
			<View
				style={{
					justifyContent: 'center',
					alignItems: 'center',
					borderBottomWidth: 1,
					borderColor: '#E4E1E0',
					padding: 16
				}}
			>
				<Text
					style={{
						fontWeight: '500',
						fontSize: 20
					}}
				>Booking details</Text>
				<TouchableOpacity
					onPress={() => setIsVisible(false)}
					style={{
						position: 'absolute',
						right: 16,
					}}
				>
					<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M18.707 5.293a1 1 0 0 1 0 1.414l-12 12a1 1 0 0 1-1.414-1.414l12-12a1 1 0 0 1 1.414 0Z"
							fill="#6D6E6F"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M5.293 5.293a1 1 0 0 1 1.414 0l12 12a1 1 0 0 1-1.414 1.414l-12-12a1 1 0 0 1 0-1.414Z"
							fill="#6D6E6F"
						/>
					</svg>
				</TouchableOpacity>
			</View>
			<ScrollView
				style={{
					padding: 32
				}}
			>
				<View
					style={{
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}
				>
					<View>
						<Text
							style={{
								fontWeight: '600',
								fontSize: 20,
								marginBottom: 8
							}}
						>Room</Text>
						<Text
							style={{
								fontWeight: '600',
								fontSize: 16,
								color: '#595959'
							}}
						>{data.room.name}</Text>
            <Text
              style={{
                fontSize: 16,
                color: '#595959'
              }}
            >{data.studio.address}</Text>
					</View>
					<View>
						<Text
							style={{
								fontWeight: '600',
								fontSize: 16,
								marginBottom: 8
							}}
						>Location <Text style={{fontSize: 16, fontWeight: '400', color: colors.mainSubtextColor}}>{data.address}</Text></Text>
						<View
							style={{
								flexDirection: 'row',
								justifyContent: 'flex-end',
								alignItems: 'center'
							}}
						>
							<Text
								style={{
									fontWeight: '600',
									fontSize: 16,
									color: colors.mainSubtextColor,
									marginRight: 16
								}}
							>Booking status</Text>
							<BookingStatus status={status}/>
						</View>
					</View>
				</View>

				<View
					style={{
						flexDirection: 'row',
						marginTop: 24,
						gap: 16
					}}
				>
					<View
						style={{
							borderBottomColor: '#e4e1e0',
							borderBottomWidth: 2,
							position: 'absolute',
							bottom: 0,
							left: 0,
							right: 0
						}}
					></View>
					<TouchableOpacity
						onPress={() => setTabIndex(0)}
						style={{
							borderBottomColor: tabIndex === 0 ? '#6EB28B' : '#e4e1e0',
							borderBottomWidth: 2
						}}
					>
						<Text
							style={{
								fontWeight: '600',
								fontSize: 16,
								paddingVertical: 8
							}}
						>Booking information</Text>
					</TouchableOpacity>
          {data.status === 3 && (
            <TouchableOpacity
              onPress={() => setTabIndex(1)}
              style={{
                borderBottomColor: tabIndex === 1 ? '#6EB28B' : '#e4e1e0',
                borderBottomWidth: 2
              }}
            >
              <Text
                style={{
                  fontWeight: '600',
                  fontSize: 16,
                  paddingVertical: 8
                }}
              >Session files</Text>
            </TouchableOpacity>
          )}
					<TouchableOpacity
						onPress={() => setTabIndex(2)}
						style={{
							borderBottomColor: tabIndex === 2 ? '#6EB28B' : '#e4e1e0',
							borderBottomWidth: 2
						}}
					>
						<Text
							style={{
								fontWeight: '600',
								fontSize: 16,
								paddingVertical: 8
							}}
						>Send message</Text>
					</TouchableOpacity>
				</View>

				{tabIndex === 0 && (
					<View
						style={{

						}}
					>
						{infoActive && (
							<View
								style={{
									flexDirection: 'row',
									justifyContent: 'space-between',
									paddingVertical: 16
								}}
							>
								<View
									style={{
										flex: 1,
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											fontSize: 20
										}}
									>Date & Time</Text>
									<View
										style={{
											flexDirection: 'row',
											marginTop: 16
										}}
									>
										<View
											style={{
												width: '50%'
											}}
										>
											<Text
												style={{
													color: '#3DBA71',
													fontWeight: '700',
													fontSize: 12
												}}
											>Name</Text>
											<Text
												style={{
													marginTop: 8,
													fontWeight: '600',
													color: colors.mainTextColor
												}}
											>{data.user.name}</Text>
										</View>
										<View
											style={{
												width: '50%'
											}}
										>
											<Text
												style={{
													color: '#3DBA71',
													fontWeight: '700',
													fontSize: 12
												}}
											>Booking date</Text>
											<Text
												style={{
													marginTop: 8,
													fontWeight: '600',
													color: colors.mainTextColor
												}}
											>{moment(data.start_time).format('DD MMM Y')}</Text>
										</View>
									</View>
									<View
										style={{
											flexDirection: 'row',
											marginTop: 16
										}}
									>
										<View
											style={{
												width: '50%'
											}}
										>
											<Text
												style={{
													color: '#3DBA71',
													fontWeight: '700',
													fontSize: 12
												}}
											>Session starts at</Text>
											<Text
												style={{
													marginTop: 8,
													fontWeight: '600',
													color: colors.mainTextColor
												}}
											>{moment.utc(data.start_time).local().format("hh:mm A")}</Text>
										</View>
										<View
											style={{
												width: '50%'
											}}
										>
											<Text
												style={{
													color: '#3DBA71',
													fontWeight: '700',
													fontSize: 12
												}}
											>Session ends at</Text>
											<Text
												style={{
													marginTop: 8,
													fontWeight: '600',
													color: colors.mainTextColor
												}}
											>{moment.utc(data.end_time).local().format("hh:mm A")}</Text>
										</View>
									</View>
									<View
										style={{
											flexDirection: 'row',
											marginTop: 16
										}}
									>
										<View
											style={{
												width: '50%'
											}}
										>
											<Text
												style={{
													color: '#3DBA71',
													fontWeight: '700',
													fontSize: 12
												}}
											>Session duration</Text>
											<Text
												style={{
													marginTop: 8,
													fontWeight: '600',
													color: colors.mainTextColor
												}}
											>{StyleDict.utils.diffTime(moment.utc(data.start_time), moment.utc(data.end_time))} Hours</Text>
										</View>
									</View>
									<Text
										style={{
											fontWeight: '600',
											fontSize: 20,
											marginTop: 32
										}}
									>Confirm check in code</Text>
									<Text
										style={{
											marginTop: 8,
											color: colors.mainSubtextColor
										}}
									>Check in code is only available for approved bookings.</Text>
									<View
										style={{
											flexDirection: 'row',
											justifyContent: 'space-between',
											marginTop: 16
										}}
									>
										<View
											style={{
												height: 48,
												width: '70%',
												borderWidth: 1,
												borderColor: '#E4E1E0',
												borderRadius: 8,
												alignItems: 'center',
												justifyContent: 'center'
											}}
										>
											{code && <Text
												style={{
													color: 'rgb(61, 186, 113)',
													fontWeight: '600',
													fontSize: 32,
													letterSpacing: 4
												}}
											>{code}</Text>}
											{!code && <Text
												style={{
													textTransform: 'uppercase',
													color: '#E4E1E0',
													fontWeight: '600',
													fontSize: 20
												}}
											>Not Available</Text>}
										</View>
									</View>

								</View>
								<View
									style={{
										flex: 1,
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											fontSize: 20
										}}
									>Detailed Breakdown</Text>
									<View
										style={{
											marginBottom: 24
										}}
									>
										{data.booking_amenities.map((item, index) => item.amenity && (
											<View
												key={index}
												style={{
													flexDirection: 'row',
													marginTop: 16,
													justifyContent: 'space-between',
													borderBottomWidth: 1,
													borderBottomColor: dark ? '#313233' : '#E4E1E0',
													paddingBottom: 16
												}}
											>
												<Text
													style={{
														fontWeight: '600',
														fontSize: 14,
														color: colors.mainSubtextColor
													}}
												>{item.amenity.name} ({item.quantity} hours)</Text>
												{item.cost === 0 && (
													<Text
														style={{
															fontWeight: '600',
															color: colors.mainTextColor
														}}
													>Free</Text>
												)}
												{item.cost > 0 && (
													<Text
														style={{
															fontWeight: '600',
															color: colors.mainTextColor
														}}
													>+ ${item.cost}</Text>
												)}
											</View>
										))}
										{data.booking_equipments.map((item, index) => item.equipment && (
											<View
												key={index}
												style={{
													flexDirection: 'row',
													marginTop: 16,
													justifyContent: 'space-between',
													borderBottomWidth: 1,
													borderBottomColor: dark ? '#313233' : '#E4E1E0',
													paddingBottom: 16
												}}
											>
												<Text
													style={{
														fontWeight: '600',
														fontSize: 14,
														color: colors.mainSubtextColor
													}}
												>{item.equipment.name} ({item.quantity} hours)</Text>
												{item.cost === 0 && (
													<Text
														style={{
															fontWeight: '600',
															color: colors.mainTextColor
														}}
													>Free</Text>
												)}
												{item.cost > 0 && (
													<Text
														style={{
															fontWeight: '600',
															color: colors.mainTextColor
														}}
													>+ ${item.cost}</Text>
												)}
											</View>
										))}
									</View>

									{item && (() => {
										const baseCost = StyleDict.utils.diffTimeToHours(moment(data.start_time).toDate(), moment(data.end_time).toDate()) * data.room.per_session_price;
										let amenitiesCost = 0;
										data.booking_amenities.forEach(item => {
											amenitiesCost = amenitiesCost + item.cost * item.quantity
										});
										let equipmentsCost = 0;
										data.booking_equipments.forEach(item => {
											equipmentsCost = equipmentsCost + item.cost * item.quantity
										});
										const subTotal = baseCost + amenitiesCost + equipmentsCost;
										const serviceFee = subTotal * 0.03;
										const totalCost = baseCost + amenitiesCost + equipmentsCost + serviceFee;

										return (
											<>
												<View
													style={{
														justifyContent: 'space-between',
														flexDirection: 'row',
														marginBottom: 8
													}}
												>
													<Text
														style={{
															color: colors.mainTextColor
														}}
													>Subtotal</Text>
													<Text
														style={{
															color: colors.mainTextColor
														}}
													>$ {(item.cost - item.total_service_fee).toFixed(2)}</Text>
												</View>
												<View
													style={{
														justifyContent: 'space-between',
														flexDirection: 'row',
														marginBottom: 8
													}}
												>
													<Text
														style={{
															color: colors.mainTextColor
														}}
													>Merchant fee</Text>
													<Text
														style={{
															color: colors.mainTextColor
														}}
													>$ {(item.cost*0.029 + 0.3).toFixed(2)}</Text>
												</View>
												<View
													style={{
														justifyContent: 'space-between',
														flexDirection: 'row',
														marginBottom: 8
													}}
												>
													<Text
														style={{
															color: colors.mainTextColor
														}}
													>Service fee</Text>
													<Text
														style={{
															color: colors.mainTextColor
														}}
													>$ {(item.total_service_fee - (item.cost*0.029 + 0.3)).toFixed(2)}</Text>
												</View>
												<View
													style={{
														justifyContent: 'space-between',
														flexDirection: 'row',
														marginTop: 4
													}}
												>
													<Text
														style={{
															color: colors.mainTextColor,
															fontWeight: '700'
														}}
													>Total</Text>
													<Text
														style={{
															color: colors.mainTextColor,
															fontWeight: '700'
														}}
													>$ {item.cost}</Text>
												</View>
											</>
										)
									})()}
								</View>
							</View>
						)}
					</View>
				)}
				{tabIndex === 1 && (
					<>
						{data.status === 3 && (
							<View
								style={{
									marginTop: 24,

								}}
							>
								{uploadActive && (
									<View
										style={{
											flexDirection: 'row',
											justifyContent: 'space-between',
											alignItems: 'flex-start',
											gap: 24,
											width: '100%',
											overflow: 'hidden'
										}}
									>
										<View
											style={{
												boxSizing: 'border-box',
												width: '50%'
											}}
										>
											<FileUploader
												multiple={true}
												handleChange={handleChange}
												name="file"
												types={fileTypes}
												classes={'files_drop_zone'}
												children={<View
													style={{
														marginVertical: 24,
														cursor: 'pointer',
														justifyContent: 'center',
														alignItems: 'center',
													}}
												>
													<svg width="42" height="40" viewBox="0 0 42 40" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M27.6673 26.6667L21.0007 20L14.334 26.6667" stroke="#CCCCCC" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
														<path d="M21 20V35" stroke="#CCCCCC" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
														<path d="M34.9838 30.6459C36.6093 29.7597 37.8935 28.3574 38.6335 26.6603C39.3736 24.9632 39.5275 23.068 39.0708 21.2737C38.6141 19.4795 37.5729 17.8884 36.1115 16.7516C34.6502 15.6149 32.8519 14.9971 31.0004 14.9959H28.9004C28.396 13.0446 27.4557 11.2331 26.1503 9.69756C24.845 8.16199 23.2085 6.94232 21.3639 6.13025C19.5193 5.31818 17.5147 4.93484 15.5006 5.00904C13.4866 5.08325 11.5155 5.61307 9.73572 6.55868C7.9559 7.50429 6.41361 8.84107 5.22479 10.4685C4.03598 12.096 3.23157 13.9718 2.87206 15.9549C2.51254 17.938 2.60726 19.9768 3.14911 21.918C3.69096 23.8592 4.66583 25.6523 6.00042 27.1626" stroke="#CCCCCC" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
														<path d="M27.6673 26.6667L21.0007 20L14.334 26.6667" stroke="#CCCCCC" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
													</svg>
													<Text
														style={{
															marginTop: 16,
															fontWeight: '600',
															fontSize: 16
														}}
													>Drag & drop files here</Text>
													<Text
														style={{
															marginTop: 16,
															fontSize: 13
														}}
													>Or</Text>
													<TouchableOpacity
														style={{
															marginTop: 16,
															height: 36,
															alignItems: 'center',
															justifyContent: 'center',
															paddingHorizontal: 24,
															backgroundColor: '#3DBA71',
															borderRadius: 6,
														}}
													>
														<Text
															style={{
																fontWeight: '600',
																fontSize: 16,
																color: 'white'
															}}
														>Browse files</Text>
													</TouchableOpacity>
												</View>}
											/>
											<View>
												{exceededError && (
													<View
														style={{
															backgroundColor: '#f8d7da',
															paddingHorizontal: 8,
															paddingVertical: 8,
															borderRadius: 6,
															marginTop: 16
														}}
													>
														<Text
															style={{
																fontSize: 16,
																color: '#721c24',
																lineHeight: 20
															}}
														>Upload file size exceeded system limit, please select files in total below 2GB.</Text>
													</View>
												)}

											</View>
										</View>
										<View
											style={{
											}}
										>
											{uploadFiles && uploadFiles.map((file, index) => (
												<View
													key={index}
													style={{
														borderWidth: 1,
														borderColor: '#E4E1E0',
														borderRadius: 4,
														padding: 8,
														flexDirection: 'row',
														alignItems: 'center',
														justifyContent: 'space-between',
														marginBottom: 8
													}}
												>
													<View
														style={{
															flex: 1,
															flexDirection: 'row',
															alignItems: 'center',
															justifyContent: 'space-between'
														}}
													>
														<Text
															style={{
																fontWeight: '500',
																fontSize: 16
															}}
														>{file.type.split('/')[1]}</Text>
														<View
															style={{
																flex: 1,
																marginHorizontal: 16
															}}
														>
															<Text>{file.name}</Text>
															<Text
																style={{
																	marginTop: 4,
																	fontSize: 12
																}}
															>Uploading... {uploadPercentage.findIndex(x => x.hash === file.hash) >=0 ? uploadPercentage[uploadPercentage.findIndex(x => x.hash === file.hash)].percent : 0}%</Text>
															<View
																style={{
																	marginTop: 8,
																	height: 4,
																	backgroundColor: '#EBE8E7',
																	width: '100%'
																}}
															>
																<View
																	style={{
																		position: 'absolute',
																		left: 0,
																		top: 0,
																		bottom: 0,
																		height: 4,
																		backgroundColor: '#3DBA71',
																		width: (uploadPercentage.findIndex(x => x.hash === file.hash) >=0 ? uploadPercentage[uploadPercentage.findIndex(x => x.hash === file.hash)].percent : 0) + '%'
																	}}
																/>
															</View>
														</View>
													</View>
													<View
														style={{
															borderWidth: 1,
															borderColor: '#D9D6D6'
														}}
													>
														<Text
															style={{
																fontWeight: '600',
																fontSize: 12,
																margin: 4
															}}
														>{AppStyles.utils.bytesToSize(file.size)}</Text>
													</View>
												</View>
											))}
											{!!attachments.length && attachments.map((item, index) => (
												<View
													key={index}
													style={{
														borderWidth: 1,
														borderColor: '#E4E1E0',
														borderRadius: 4,
														padding: 8,
														flexDirection: 'row',
														alignItems: 'center',
														justifyContent: 'space-between',
														marginBottom: 8,
													}}
												>
													<View
														style={{
															flex: 1,
															flexDirection: 'row',
															alignItems: 'center',
															justifyContent: 'space-between'
														}}
													>
														<Text
															style={{
																fontWeight: '500',
																fontSize: 16
															}}
														>{item.mime_type}</Text>
														<View
															style={{
																flex: 1,
																marginHorizontal: 16,
																overflow: 'hidden',
															}}
														>
															<Text>
																{item.file_name.length > 10
																	? item.file_name.substring(0, 10) + '...'
																	: item.file_name}
															</Text>
														</View>
													</View>
													<View
														style={{
															alignItems: 'center',
															flexDirection: 'row'
														}}
													>
														<View
															style={{
																borderRadius: 4,
																backgroundColor: 'rgb(61, 186, 113)',
																paddingHorizontal: 4,
																marginRight: 16,
																height: 20,
																alignItems: 'center',
																justifyContent: 'center'
															}}
														>
															<Text
															><a
																style={{
																	color: 'white',
																	fontSize: 12,
																	textDecoration: 'none'
																}}
																href={item.file_url} target={'_blank'}>Download</a></Text>
														</View>
														<View
															style={{
																borderWidth: 1,
																borderColor: '#D9D6D6',
																borderRadius: 4,
																height: 20,
																alignItems: 'center',
																justifyContent: 'center'
															}}
														>
															<Text
																style={{
																	fontWeight: '600',
																	fontSize: 12,
																	margin: 4
																}}
															>{AppStyles.utils.bytesToSize(item.size)}</Text>
														</View>
													</View>
												</View>
											))}
										</View>
									</View>
								)}
							</View>
						)}
					</>
				)}
				{tabIndex === 2 && (
					<View
						style={{
							marginTop: 16
						}}
					>
            <ConversationBoxScreen bookingData={data} />
					</View>
				)}
        {isRejectSubmitting && <ActivityIndicator color={'#A42323'}/>}
        {approveFault && (
          <View
            style={{
              marginTop: 16,
              padding: 16,
              borderRadius: 4,
              backgroundColor: '#FBE8E8',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Text
              style={{
                color: '#A42323',
                fontSize: 16
              }}
            >{approveFaultMessage}</Text>
          </View>
        )}
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'flex-end',
						marginTop: 32
					}}
				>
					{(data.status === 0 || data.status === 2) && (
						<TouchableOpacity
							onPress={() => {
								if(isRejectSubmitting) {
									return
								}
								setRejectIsSubmitting(true);
								API.post('owner/cancel-booking', {
									id: data.id
								})
									.then(res => {
										setRejectIsSubmitting(false);
										let newData = data;
										newData.status =  res.data.status;
										newData.confirm_code = res.data.confirm_code;
										setData(newData);
										setStatus(res.data.status);
										setCode(res.data.confirm_code);
									}).catch (error => {
									setRejectIsSubmitting(false)
								});
							}}
							style={{
								fontWeight: '600',
								fontSize: 16,
								paddingHorizontal: 32,
								height: 48,
								borderRadius: 8,
								backgroundColor: '#FBE8E8',
								justifyContent: 'center',
								alignItems: 'center'
							}}
						>
							{isRejectSubmitting && <ActivityIndicator color={'#A42323'}/>}
							{!isRejectSubmitting && <Text
								style={{
									fontWeight: '600',
									fontSize: 16,
									color: '#A42323'
								}}
							>{data.status === 2 ? 'Cancel booking': 'Reject booking'}</Text>}
						</TouchableOpacity>
					)}
					{(data.status !== 2 && data.status !== 3  && data.status !== 1) && (
						<TouchableOpacity
							onPress={() => {
								if(isSubmitting) {
									return
								}
                setApproveFault(false);
								setIsSubmitting(true);
								API.post('owner/approve-booking', {
									id: data.id
								})
									.then(res => {
										setIsSubmitting(false);
										let newData = data;
										newData.status =  res.data.status;
										newData.confirm_code = res.data.confirm_code;
										setData(newData);
										setStatus(res.data.status);
										setCode(res.data.confirm_code);
									}).catch (error => {
									setIsSubmitting(false);
                  setApproveFault(true);
                  setApproveFaultMessage(error.response.data.message || 'Customers credit card was declined. Advise them to use another form of payment.')
								});
							}}
							style={{
								paddingHorizontal: 32,
								marginLeft: 16,
								fontWeight: '600',
								fontSize: 16,
								height: 48,
								borderRadius: 8,
								backgroundColor: '#19994F',
								justifyContent: 'center',
								alignItems: 'center'
							}}
						>
							{isSubmitting && <ActivityIndicator color={'white'}/>}
							{!isSubmitting && <Text
								style={{
									fontWeight: '600',
									fontSize: 16,
									color: 'white'
								}}
							>Approve booking</Text>}
						</TouchableOpacity>
					)}
					{data.status === 2 && (
						<TouchableOpacity
							onPress={() => {
								if(isSubmitting) {
									return
								}
								setIsSubmitting(true);
								API.post('owner/end-booking', {
									id: data.id
								})
									.then(res => {
										setIsSubmitting(false);
										let newData = data;
										newData.status =  res.data.status
										setData(newData);
										setStatus(res.data.status);
										setCode(res.data.confirm_code);
									}).catch (error => {
									setIsSubmitting(false)
								});
							}}
							style={{
								paddingHorizontal: 32,
								marginLeft: 16,
								fontWeight: '600',
								fontSize: 16,
								height: 48,
								borderRadius: 8,
								backgroundColor: '#19994F',
								justifyContent: 'center',
								alignItems: 'center'
							}}
						>
							{isSubmitting && <ActivityIndicator color={'white'}/>}
							{!isSubmitting && <Text
								style={{
									fontWeight: '600',
									fontSize: 16,
									color: 'white'
								}}
							>Confirm Check-In Code</Text>}
						</TouchableOpacity>
					)}
				</View>
			</ScrollView>
		</View>
	)
}
export default BookingDetailsScreen;
