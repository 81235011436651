import React from 'react';
import {Image, StyleSheet, Text, View} from 'react-native';
import SortableImages from "./SortableImages";
import {arrayMove} from "react-sortable-hoc";
import {toast} from "react-toastify";

const PhotoUploader = ({artworkFiles = [], setArtworkFiles = () => {}, ...props}) => {
  const artworksSelectedHandler = (e) => {
    let videoFiles = [];
    for (const file of artworkFiles) {
      if (file.type.startsWith("video/")) {
        videoFiles.push(file);
      }
    }
    for (const file of e.target.files) {
      if (file.type.startsWith("video/")) {
        videoFiles.push(file);
      }
    }
    if(videoFiles.length > 1) {
      toast.error('You can upload only one video');
      return;
    }
    if(artworkFiles.length + e.target.files.length > 10) {
      toast.error('You just can upload up to 10 photos and one video.');
      return;
    }
    let array = artworkFiles;
    for (let i = 0; i < e.target.files.length; i++) {
      array.push(e.target.files[i]);
    }
    setArtworkFiles(prev => ([...array]));
  }

  return (
    <>
      {artworkFiles.length > 0 && (
        <View
          style={{
            marginTop: 40
          }}
        >
          <SortableImages
            items={artworkFiles}
            onSortEnd={({oldIndex, newIndex}) => {
              let items = artworkFiles;
              items = arrayMove(items, oldIndex, newIndex);
              setArtworkFiles(prev => ([...items]));
            }}
          />
        </View>
      )}
      {artworkFiles.length > 0 && (
        <View
          style={{
            backgroundColor: '#3DBA71',
            height: 40,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            borderRadius: 6,
            paddingHorizontal: 24
          }}
        >
          <Text
            style={{
              color: 'white',
              fontWeight: '500'
            }}
          >Add More Images</Text>
          <input
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              opacity: 0,
              cursor: 'pointer'
            }}
            type="file"
            onChange={artworksSelectedHandler}
            accept="image/*,video/mp4"
            multiple
          />
        </View>
      )}
      {artworkFiles.length === 0 && (
        <View
          style={{
            marginTop: 40,
            flex: 1,
            borderWidth: 1,
            borderColor: '#D2D2D2'
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              padding: 4
            }}
          >
            {[{}, {}, {}].map((item, index) => (
              <View
                key={index}
                style={{
                  width: '33.33%',
                  aspectRatio: 1.77,
                  padding: 4,
                  opacity: .2
                }}
              >
                <View
                  style={{
                    flex: 1,
                    backgroundColor: '#97DEB4',
                  }}
                />
              </View>
            ))}
          </View>
          <View
            style={{
              marginTop: -8,
              flexDirection: 'row',
              padding: 4
            }}
          >
            {[{}, {}, {}].map((item, index) => (
              <View
                key={index}
                style={{
                  width: '33.33%',
                  aspectRatio: 1.77,
                  padding: 4,
                  opacity: .2
                }}
              >
                <View
                  style={{
                    flex: 1,
                    backgroundColor: '#97DEB4',
                  }}
                />
              </View>
            ))}
          </View>
          <View
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <View
              style={{
                backgroundColor: '#3DBA71',
                height: 40,
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                borderRadius: 6,
                paddingHorizontal: 24
              }}
            >
              <Text
                style={{
                  color: 'white',
                  fontWeight: '500'
                }}
              >Upload your photos and video</Text>
              <input
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  opacity: 0,
                  cursor: 'pointer'
                }}
                type="file"
                onChange={artworksSelectedHandler}
                accept="image/*,video/mp4"
                multiple
              />
              <Text
                style={{
                  position: 'absolute',
                  bottom: -24,
                  lineHeight: 20,
                  color: '#484848'
                }}
              >Or drag them in here</Text>
            </View>
          </View>
        </View>
      )}
      <View
        style={{
          backgroundColor: '#F5F4F3',
          padding: 8,
          marginTop: 40
        }}
      >
        <Text
          style={{
            color: '#6D6E6F'
          }}
        >You can upload up to 10 photos and one video.</Text>
      </View>
    </>
  );
};

export default PhotoUploader;