import React, {useEffect, useMemo, useState} from "react";
import {
  Text,
  View,
  TextInput, ImageBackground, StyleSheet, Image, TouchableOpacity
} from "react-native";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {useNavigation, useRoute} from "@react-navigation/native";
import API from "../helpers/Axios";
import Responsive from "../helpers/Reponsive";

function DashboardSideBar () {
  const navigation = useNavigation();
  const route = useRoute();
  const [routeName, setRouteName] = useState(route.name.replace('Screen', '').toLowerCase());
  const [isSideBarMinimum, setIsSideBarMinimum] = useState(false);
  const { isMobile } = Responsive();

  useEffect(() => {
    setTimeout(() => {
      setRouteName((window.location.href.substring(window.location.href.lastIndexOf('/') + 1)).replace('Screen', '').toLowerCase())
    }, 100)
  }, [window.location.href.substring(window.location.href.lastIndexOf('/') + 1)]);

  return (
    <View
      style={{
        width: isSideBarMinimum ? 72 : 220,
        backgroundColor: 'white',
        padding: 24,
      }}
    >
      <TouchableOpacity
        onPress={() => {
          setIsSideBarMinimum(!isSideBarMinimum)
        }}
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'white',
          shadowColor: "#000",
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowOpacity: 0.15,
          shadowRadius: 3.84,
          elevation: 5,
          width: 24,
          height: 24,
          borderRadius: 12,
          position: 'absolute',
          top: 40,
          right: -12,
          zIndex: 1,
          transform: [{ rotate: isSideBarMinimum ? '0deg': '180deg' }]
      }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20"><path fill="currentColor" d="M7.733 4.207a.75.75 0 0 1 1.06.026l5.001 5.25a.75.75 0 0 1 0 1.035l-5 5.25a.75.75 0 1 1-1.087-1.034L12.216 10l-4.51-4.734a.75.75 0 0 1 .027-1.06" /></svg>
      </TouchableOpacity>
      {[
        {
          title: 'Dashboard',
          screen: 'DashboardScreen',
          icon: (routeName === '' || routeName === 'dashboard') ? <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 15a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM14 11l2-2"
              stroke="#31A762"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.778 21a10.101 10.101 0 0 1-3.384-5.097 10.189 10.189 0 0 1 .164-6.134A10.088 10.088 0 0 1 6.21 4.865 9.935 9.935 0 0 1 12 3c2.075 0 4.098.652 5.79 1.865a10.088 10.088 0 0 1 3.652 4.904c.683 1.978.74 4.122.164 6.134A10.101 10.101 0 0 1 18.222 21H5.778Z"
              stroke="#31A762"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg> : <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12 15a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM14 11l2-2"
              stroke="#595959"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.778 21a10.101 10.101 0 0 1-3.384-5.097 10.189 10.189 0 0 1 .164-6.134A10.088 10.088 0 0 1 6.21 4.865 9.935 9.935 0 0 1 12 3c2.075 0 4.098.652 5.79 1.865a10.088 10.088 0 0 1 3.652 4.904c.683 1.978.74 4.122.164 6.134A10.101 10.101 0 0 1 18.222 21H5.778Z"
              stroke="#595959"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        },
        {
          title: 'Bookings',
          screen: 'BookingsScreen',
          icon: routeName === 'bookings' ? <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.5 3v9l-3-2.25-3 2.25V3"
              stroke="#31A762"
              strokeWidth={1.5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.5 20.25A2.25 2.25 0 0 1 6.75 18H19.5V3H6.75A2.25 2.25 0 0 0 4.5 5.25v15ZM4.5 20.25V21H18"
              stroke="#31A762"
              strokeWidth={1.5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg> : <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.5 3v9l-3-2.25-3 2.25V3"
              stroke="#595959"
              strokeWidth={1.5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.5 20.25A2.25 2.25 0 0 1 6.75 18H19.5V3H6.75A2.25 2.25 0 0 0 4.5 5.25v15ZM4.5 20.25V21H18"
              stroke="#595959"
              strokeWidth={1.5}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        },
        {
          title: 'Earnings',
          screen: 'EarningsScreen',
          icon: routeName === 'earnings' ? <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 0a1 1 0 0 1 1 1v22a1 1 0 1 1-2 0V1a1 1 0 0 1 1-1Z"
              fill="#31A762"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.318 5.318A4.5 4.5 0 0 1 9.5 4H17a1 1 0 1 1 0 2H9.5a2.5 2.5 0 1 0 0 5h5a4.5 4.5 0 1 1 0 9H6a1 1 0 1 1 0-2h8.5a2.5 2.5 0 0 0 0-5h-5a4.5 4.5 0 0 1-3.182-7.682Z"
              fill="#31A762"
            />
          </svg> : <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 0a1 1 0 0 1 1 1v22a1 1 0 1 1-2 0V1a1 1 0 0 1 1-1Z"
              fill="#595959"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.318 5.318A4.5 4.5 0 0 1 9.5 4H17a1 1 0 1 1 0 2H9.5a2.5 2.5 0 1 0 0 5h5a4.5 4.5 0 1 1 0 9H6a1 1 0 1 1 0-2h8.5a2.5 2.5 0 0 0 0-5h-5a4.5 4.5 0 0 1-3.182-7.682Z"
              fill="#595959"
            />
          </svg>
        },
        {
          title: 'Messages',
          screen: 'MessagesScreen',
          icon: routeName === 'messages' ? <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.109 9.5V5.435C18.109 4.09 17.019 3 15.674 3H5.935C4.59 3 3.5 4.09 3.5 5.435V12.131C3.5 13.476 4.59 14.566 5.935 14.566H7.152V17L9.5 15.435M15.5 19H11.5C10.395 19 9.5 18.105 9.5 17V11.5C9.5 10.395 10.395 9.5 11.5 9.5H19.5C20.605 9.5 21.5 10.395 21.5 11.5V17C21.5 18.105 20.605 19 19.5 19H18.5V21L15.5 19Z" stroke="#31A762" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
          </svg> : <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.109 9.5V5.435C18.109 4.09 17.019 3 15.674 3H5.935C4.59 3 3.5 4.09 3.5 5.435V12.131C3.5 13.476 4.59 14.566 5.935 14.566H7.152V17L9.5 15.435M15.5 19H11.5C10.395 19 9.5 18.105 9.5 17V11.5C9.5 10.395 10.395 9.5 11.5 9.5H19.5C20.605 9.5 21.5 10.395 21.5 11.5V17C21.5 18.105 20.605 19 19.5 19H18.5V21L15.5 19Z" stroke="#6D6E6F" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        },
        /*{
          title: 'Rooms',
          screen: 'RoomsScreen',
          icon: routeName === 'rooms' ? <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.25 21h19.5M5.25 21V3.75A.75.75 0 0 1 6 3h12a.75.75 0 0 1 .75.75V21"
              stroke="#31A762"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
              fill="#31A762"
              stroke="#31A762"
              strokeWidth={1.5}
            />
          </svg> : <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.25 21h19.5M5.25 21V3.75A.75.75 0 0 1 6 3h12a.75.75 0 0 1 .75.75V21"
              stroke="#595959"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
              fill="#595959"
              stroke="#595959"
              strokeWidth={1.5}
            />
          </svg>
        },*/
        {
          title: 'Manage Studios',
          screen: 'StudiosScreen',
          icon:  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.25 20.25V10.8318C20.25 10.7273 20.2282 10.624 20.1859 10.5284C20.1436 10.4328 20.0818 10.3471 20.0045 10.2768L12.504 3.45793C12.3659 3.33242 12.186 3.26287 11.9995 3.26288C11.8129 3.26288 11.633 3.33244 11.4949 3.45797L3.99545 10.2769C3.91814 10.3471 3.85637 10.4328 3.8141 10.5284C3.77183 10.6239 3.75 10.7273 3.75 10.8318V20.25" stroke={routeName === 'studios' ? '#31A762' : '#595959'} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.5 20.25H22.5" stroke={routeName === 'studios' ? '#31A762' : '#595959'} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.2495 20.2492V14.9992C14.2495 14.8003 14.1705 14.6095 14.0298 14.4689C13.8892 14.3282 13.6984 14.2492 13.4995 14.2492H10.4995C10.3006 14.2492 10.1098 14.3282 9.96918 14.4689C9.82853 14.6095 9.74951 14.8003 9.74951 14.9992V20.2492" stroke={routeName === 'studios' ? '#31A762' : '#595959'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        },
        {
          title: 'Settings',
          screen: 'SettingsScreen',
          icon:  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_32947_16344" style={{maskType: 'luminance'}} maskUnits="userSpaceOnUse" x={1} y={1} width={22} height={22}>
              <path fillRule="evenodd" clipRule="evenodd" d="M9.25464 20.7454L7.92327 20.0797L4.72991 21.1441L2.85588 19.2701L3.92033 16.0767L3.25464 14.7454L1 13.618V10.382L3.25464 9.25464L3.92033 7.92327L2.85588 4.72991L4.72991 2.85588L7.92327 3.92033L9.25464 3.25464L10.382 1H13.618L14.7454 3.25464L16.0767 3.92033L19.2701 2.85588L21.1441 4.72991L20.0797 7.92327L20.7454 9.25464L23 10.382V13.618L20.7454 14.7454L20.0797 16.0767L21.1441 19.2701L19.2701 21.1441L16.0767 20.0797L14.7454 20.7453L13.618 23.0002L10.3819 23L9.25464 20.7454ZM4.87138 18.9888L4.89809 19.1019L5.01122 19.1286L5.08114 18.9189L4.87138 18.9888ZM10.7454 19.2546L8.07673 17.9203L5.27009 18.8559L5.14412 18.7299L6.07967 15.9233L4.74536 13.2546L3 12.382V11.618L4.74536 10.7454L6.07967 8.07673L5.14412 5.27009L5.27009 5.14412L8.07673 6.07967L10.7454 4.74536L11.618 3H12.382L13.2546 4.74536L15.9233 6.07967L18.7299 5.14412L18.8559 5.27009L17.9203 8.07673L19.2546 10.7454L21 11.618V12.382L19.2546 13.2546L17.9203 15.9233L18.8559 18.7299L18.7299 18.8559L15.9233 17.9203L13.2546 19.2547L12.382 21.0001L11.6181 21L10.7454 19.2546ZM5.01122 4.87138L4.89809 4.89809L4.87138 5.01122L5.08114 5.08114L5.01122 4.87138ZM19.1019 4.89809L19.1286 5.01122L18.9189 5.08114L18.9888 4.87138L19.1019 4.89809ZM18.9888 19.1286L19.1019 19.1019L19.1286 18.9888L18.9189 18.9189L18.9888 19.1286ZM12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16ZM12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z" fill="white" />
            </mask>
            <g mask="url(#mask0_32947_16344)">
              <rect width={24} height={24} fill={routeName === 'settings' ? '#31A762' : '#595959'} />
            </g>
          </svg>
        },
        {
          title: 'Help',
          screen: 'HelpScreen',
          icon: routeName === 'help' ? <svg width={24} height={24} fill="#31A762" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18ZM1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12Z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.258 8.024a2 2 0 0 0-2.225 1.308 1 1 0 1 1-1.886-.664 4 4 0 0 1 7.773 1.333c0 1.07-.563 1.888-1.145 2.445-.582.558-1.272.945-1.76 1.178a.213.213 0 0 0-.082.069.095.095 0 0 0-.012.022l-.001.006V14a1 1 0 1 1-2 0v-.28c0-.87.549-1.572 1.232-1.9.396-.19.874-.468 1.24-.818.365-.35.528-.681.528-1.002v-.001a2 2 0 0 0-1.662-1.975Z"
            />
            <path d="M13 17a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
          </svg> : <svg width={24} height={24} fill="#595959" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18ZM1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12Z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.258 8.024a2 2 0 0 0-2.225 1.308 1 1 0 1 1-1.886-.664 4 4 0 0 1 7.773 1.333c0 1.07-.563 1.888-1.145 2.445-.582.558-1.272.945-1.76 1.178a.213.213 0 0 0-.082.069.095.095 0 0 0-.012.022l-.001.006V14a1 1 0 1 1-2 0v-.28c0-.87.549-1.572 1.232-1.9.396-.19.874-.468 1.24-.818.365-.35.528-.681.528-1.002v-.001a2 2 0 0 0-1.662-1.975Z"
            />
            <path d="M13 17a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
          </svg>
        }
      ].map((item, index) => (
        <TouchableOpacity
          key={index}
          onPress={() => {
            setRouteName(item.screen.replace('Screen', '').toLowerCase());
            navigation.navigate(item.screen);
          }}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: 16
          }}
        >
          {item.icon}
          {!isSideBarMinimum && (
            <Text
              style={{
                fontWeight: '500',
                marginLeft: 16,
                color: (routeName === item.screen.replace('Screen', '').toLowerCase()) ? 'green' : 'black'
              }}
            >{item.title}</Text>
          )}
        </TouchableOpacity>
      ))}
    </View>

  );
}

DashboardSideBar.propTypes = {
  navigation: PropTypes.object,
};

export default connect(({auth}) => ({auth}))(DashboardSideBar);
