import React, {useEffect, useState} from 'react'
import {View, TouchableOpacity} from 'react-native'
import {SortableContainer, SortableElement, sortableHandle} from "react-sortable-hoc";

const SortableImages = ({ items, onSortEnd, onRemovedEnd, ...props}) => {
  const [artworkFiles, setArtworkFiles] = useState(items);
  const [deleteArray, setDeleteArray] = useState([]);

  useEffect(() => {
    setArtworkFiles(items);
  });

  const SortableItem = SortableElement(({item, index, i}) => {
    console.log(item);
    let objectUrl;
    if(item.md !== undefined) {
      objectUrl = item.md;
    } else {
      objectUrl = URL.createObjectURL(item);
    }

    return (
      <li
        className={'d-flex'}
        style={{
          marginBottom: 16
        }}
      >
        <DragHandle />
        <TouchableOpacity
          onPress={() => {
            let files = artworkFiles;
            files.splice(i, 1);
            setArtworkFiles(prev => ([...files]));
            if(item.md !== undefined) {
              let filename = item.md.substring(item.md.lastIndexOf('/') + 1).replace('-md', '');
              let newData = deleteArray;
              newData.push(filename);
              setDeleteArray(newData);
              onRemovedEnd(newData);
            }
          }}
          style={{
            position: 'absolute',
            right: 24,
            top: 16,
            width: 32,
            height: 32,
            zIndex: 2
          }}
        >
          <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.97 4.295c-6.516 0-11.799 5.365-11.799 11.983 0 6.617 5.283 11.982 11.8 11.982 6.516 0 11.8-5.365 11.8-11.982 0-6.618-5.284-11.983-11.8-11.983ZM1.55 16.278c0-8.089 6.457-14.646 14.422-14.646 7.965 0 14.421 6.557 14.421 14.646 0 8.088-6.456 14.645-14.421 14.645-7.965 0-14.422-6.557-14.422-14.645Z"
              fill="#fff"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.83 11.342c.513.52.513 1.363 0 1.883l-7.865 7.988c-.512.52-1.343.52-1.855 0a1.346 1.346 0 0 1 0-1.883l7.867-7.988a1.297 1.297 0 0 1 1.854 0Z"
              fill="#fff"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.11 11.342a1.297 1.297 0 0 1 1.854 0l7.867 7.988c.512.52.512 1.363 0 1.883s-1.342.52-1.854 0l-7.867-7.988a1.346 1.346 0 0 1 0-1.883Z"
              fill="#fff"
            />
          </svg>
        </TouchableOpacity>
        {(item.type === 'video' || item.type === 'video/mp4') && (
          <div
            className="studio-image-container"
          >
          <video
            controls={true}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: '100%'
            }}
            src={objectUrl} />
          </div>
        )}
        {(item.type !== 'video' && item.type !== 'video/mp4') && (
          <div
            className="studio-image-container"
            style={{
              backgroundImage: 'url(' + objectUrl +')',
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
            }}
          />
        )}
      </li>
    )
  });

  const DragHandle = sortableHandle(() => <View
    style={{
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      top: 0,
      zIndex: 1
    }}
  />);

  const SortableContainerElement = SortableContainer(({children}) => {
    return <ul className="sortable ui-sortable">{children}</ul>;
  });

  return (
    <SortableContainerElement
      onSortEnd={onSortEnd}
      useDragHandle
    >
      {artworkFiles.map((item, index) => (
        <SortableItem key={`item-${index}`} index={index} item={item} i={index} />
      ))}
    </SortableContainerElement>
  );
}




export default SortableImages
