import React from "react";
import {
  Text,
  StatusBar,
  View, TouchableOpacity,
} from "react-native";
import PropTypes from "prop-types"

const NotFoundScreen = () => {
  return (
    <View
      style={{
        width: '100%',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Text
        style={{
          fontSize: 40,
          marginBottom: 24,
          fontWeight: '600'
        }}
      >404 Not Found!</Text>
      <Text
        style={{
          fontSize: 24
        }}
      >We're not sure what are you looking for!</Text>
    </View>
  );

}

NotFoundScreen.propTypes = {
  navigation: PropTypes.object,
};

export default NotFoundScreen;