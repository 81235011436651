import React, {useEffect, useMemo, useState} from "react";
import {
	Text,
	View,
	TextInput, ScrollView, StyleSheet, Image, Modal, ActivityIndicator
} from "react-native";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import API from "../../helpers/Axios";
import {TouchableOpacity} from "react-native-web";
import moment from 'moment';
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import '../../styles/calendar.css';

import { Calendar, momentLocalizer  } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import useModal from "../../helpers/useModal";
import BookingDetailsScreen from "../modals/BookingDetailsScreen";
import EditStudioScreen from "../modals/EditStudioScreen";
import BookingStatus from "../../components/BookingStatus";
import Responsive from "../../helpers/Reponsive";
import MobileScrollView from "../../components/MobileScrollView";
const localizer = momentLocalizer(moment);


function BookingsScreen ({navigation, route}) {
  const { isMobile } = Responsive();
  const [studios, setStudios] = useState([]);
	const [bookings, setBookings] = useState([]);
	const {isShowing, toggleModal} = useModal();
	const [booking, setBooking] = useState(null);
	const [isVisible, setIsVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [currentStudio, setCurrentStudio] = useState(null);
	const [currentRoom, setCurrentRoom] = useState({id: 0});
	const [allRoomBookings, setAllRoomBookings] = useState([]);
  const [isBookingLoading, setIsBookingLoading] = useState(false);
	const [currentDate, setCurrentDate] = useState(new Date());
	const [events, setEvents] = useState([]);

	useEffect(() => {
		if(currentStudio) {
			setIsBookingLoading(true);
			API.get(`owner/bookings/studio/${currentStudio.id}`)
				.then(res => {
					setBookings(res.data.data);
					setIsBookingLoading(false);
					setCurrentRoom({id: 0});
				}).catch (error => {
				console.log(error);
			});
		}
	}, [currentStudio]);

	useEffect(() => {
		API.get('owner/bookings')
			.then(res => {
				setStudios(res.data.studios);
				setCurrentStudio(res.data.studios[0]);
				getStudioEvents(res.data.studios[0].id);
				setIsLoading(false);
				if(route.params && route.params.bookingId !== undefined) {
					showBookingDetails(route.params.studioId, route.params.roomId, route.params.bookingId);
				}
			}).catch (error => {
			console.log(error);
		});
	}, []);

	const getStudioEvents = (studio_id) => {
		API.post('owner/get-studio-events', {
			studio_id: studio_id
		}).then(res => {
			let events = [];
			res.data.forEach((item) => {
				events.push({
					id: item.id,
					title: `Room: ${item.room.name}, by: ${item.user.name}`,
					start: moment.utc(item.start_time).local().toDate(),
					end: moment.utc(item.end_time).local().toDate(),
				});
			});
			setEvents(events);
		}).catch (error => {
			console.log(error);
		});
	}

	const getBookingsRoom = (roomId) => {
		setIsBookingLoading(true);
		API.get(`owner/bookings/room/${roomId}`)
			.then(res => {
				setBookings(res.data.data)
				setIsBookingLoading(false);
			}).catch (error => {
			console.log(error);
		});
	}

	const showBookingDetails = (studioId, roomId, bookingId) => {
		setTimeout(() => {
			let sIndex = studios.findIndex(x => x.id = studioId);
			if(sIndex >= 0) {
				setCurrentStudio(studios[sIndex]);
				setTimeout(() => {
					let rIndex = currentStudio.rooms.findIndex(x => x.id = roomId);
					setCurrentRoom(currentStudio.rooms[rIndex]);
					setTimeout(() => {
						if(!isBookingLoading) {
							let bIndex = bookings.findIndex(x => x.id = bookingId);
							if(bIndex >= 0) {
								setBooking(bookings[bIndex]);
								setIsVisible(true);
							}
						}
					}, 1000);
				}, 300);
			}
		}, 300);
	}

	useEffect(() => {
		if(!isLoading && route.params && route.params.bookingId !== undefined) {
			showBookingDetails(route.params.studioId, route.params.roomId, route.params.bookingId);
		}
	}, [route.params]);

	return isLoading ? (<ActivityIndicator style={{flex: 1, justifyContent: 'center', alignItems: 'center'}} />) : (
		<View
			style={{
				flex: 1
			}}
		>
			<Modal onRequestClose={() => setIsVisible(false)} transparent visible={isVisible}>
				<View
					style={{
						backgroundColor: 'white',
						borderColor: '#eee',
						borderRadius: 8,
						borderWidth: 1,
						margin: 'auto',
						maxWidth: 900,
            width: '90%',
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
					}}
				>
					<BookingDetailsScreen
						setIsVisible={setIsVisible}
						item={booking}
					/>
				</View>
			</Modal>
			<ScrollView
				style={{
					flex: 1,
          padding: isMobile ? 24 : 40
				}}
			>
				<Text
					style={{
						fontSize: 48,
						fontWeight: '600'
					}}
				>Custom Booking</Text>
				<Text
					style={{
						marginTop: 16,
						fontSize: 20,
						color: '#595959'
					}}
				>Create Custom Booking For Room: </Text>

			</ScrollView>
		</View>
	);

}


const styles = StyleSheet.create({
	tableHeader: {
		fontWeight: '600',
		textAlign: 'left',
		alignSelf: 'flex-start'
	},
	tableCell: {
		borderBottom: '1px solid',
		borderBottomColor: 'gray',
		paddingLeft: 16,
		paddingRight: 16
	},
});

BookingsScreen.propTypes = {
	navigation: PropTypes.object,
};

export default connect(({auth}) => ({auth}))(BookingsScreen);