import React, {useEffect, useState} from 'react';
import {View, Text, ActivityIndicator, Modal} from "react-native";
import RoundTextInput from "../../components/RoundTextInput";
import {TouchableOpacity} from "react-native-web";
import API from "../../helpers/Axios";
import RoundPicker from "../../components/RoundPicker";

const EditStudioScreen = ({ setIsVisible, item, setCurrentStudio }) => {
	const [name, setName] = useState(item.name);
	const [businessName, setBusinessName] = useState(item.business_name || '');
	const [taxId, setTaxId] = useState(item.tax_id || '');
	const [description, setDescription] = useState(item.description);
	const [categories, setCategories] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [studioType, setStudioType] = useState({
		value: item.category_id,
		error: false,
		errorString: 'Please select studio type'
	});

	useEffect(() => {
		API.post('categories')
			.then(res => {
				setCategories(res.data)
			}).catch (error => {
		});
	}, []);

	return (
		<View
			style={{
				flex: 1,
			}}
		>
			<View
				style={{
					flex: 1,
					justifyContent: 'center',
					alignItems: 'center',
					borderBottomWidth: 1,
					borderColor: '#E4E1E0',
					padding: 16
				}}
			>
				<Text
					style={{
						fontWeight: '500',
						fontSize: 20
					}}
				>Edit Studio Information</Text>
				<TouchableOpacity
					onPress={() => setIsVisible(false)}
					style={{
						position: 'absolute',
						right: 16,
					}}
				>
					<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M18.707 5.293a1 1 0 0 1 0 1.414l-12 12a1 1 0 0 1-1.414-1.414l12-12a1 1 0 0 1 1.414 0Z"
							fill="#6D6E6F"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M5.293 5.293a1 1 0 0 1 1.414 0l12 12a1 1 0 0 1-1.414 1.414l-12-12a1 1 0 0 1 0-1.414Z"
							fill="#6D6E6F"
						/>
					</svg>
				</TouchableOpacity>
			</View>
			<View
				style={{
					padding: 32
				}}
			>
				<View
					style={{
						flexDirection: 'row'
					}}
				>
					<View
						style={{
							width: '60%'
						}}
					>
						<RoundTextInput
							placeholder={'Studio Name'}
							value={name}
							error={false}
							onChangeText={text => setName(text)}
						/>
					</View>
					<View
						style={{
							marginLeft: 16,
							flex: 1
						}}
					>
						<RoundTextInput
							placeholder={'Business Incorporation'}
							value={businessName}
							error={false}
							onChangeText={text => setBusinessName(text)}
						/>
					</View>
				</View>
				<View
					style={{
						marginTop: 16,
						flexDirection: 'row',
						justifyContent: 'space-between',
						marginHorizontal: -8
					}}
				>
					<View
						style={{
							width: '50%',
							paddingHorizontal: 8,
						}}
					>
						<RoundPicker
							data={categories}
							placeholder={'Studio type'}
							value={studioType.value}
							error={studioType.error}
							errorString={studioType.errorString}
							onValueChange={value => {
								setStudioType({
									value: value,
									error: false,
									errorString: studioType.errorString
								});
							}}
						/>
					</View>
					{/*
					<View
						style={{
							paddingHorizontal: 8,
						}}
					>
						<RoundTextInput
							placeholder={'Account type'}
							value={name}
							error={false}
							onChangeText={text => setName(text)}
						/>
					</View>
					*/}
					<View
						style={{
							width: '50%',
							paddingHorizontal: 8,
						}}
					>
						<RoundTextInput
							placeholder={'Tax ID'}
							value={taxId}
							error={false}
							onChangeText={text => setTaxId(text)}
						/>
					</View>
				</View>
				<View
					style={{
						marginTop: 16
					}}
				>
					<RoundTextInput
						placeholder={'Description'}
						value={description}
						error={false}
						onChangeText={text => setDescription(text)}
						multiline={true}
						numberOfLines={5}
						maxLength={300}
					/>
				</View>
				<View
					style={{
						alignItems: 'flex-end',
						marginTop: 8
					}}
				>
					<View
						style={{
							flexDirection: 'row'
						}}
					>
						<Text
							style={{
								color: '#4A535E'
							}}
						>{description.length}</Text>
						<Text
							style={{
								color: '#4A535E'
							}}
						>/300</Text>
					</View>
				</View>
				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'flex-end',
						marginTop: 32
					}}
				>
					<TouchableOpacity
						onPress={() => setIsVisible(false)}
						style={{
							width: 132,
							fontWeight: '600',
							fontSize: 16,
							paddingHorizontal: 32,
							borderWidth: 1,
							height: 48,
							borderRadius: 8,
							borderColor: '#E4E1E0',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<Text
							style={{
								fontWeight: '600',
								fontSize: 16
							}}
						>Cancel</Text>
					</TouchableOpacity>
					<TouchableOpacity
						onPress={() => {
							setIsLoading(true);
							const data = new FormData();
							data.append('id', item.id);
							data.append('name', name);
							data.append('business_name', businessName);
							data.append('tax_id', taxId);
							data.append('description', description);
							data.append('category_id', studioType.value);

							API.post('owner/save-studio', data).then(res => {
								let newData = item;
								newData.name = res.data.name;
								newData.description = res.data.description;
								newData.business_name = res.data.business_name;
								newData.tax_id = res.data.tax_id;
								newData.category_id = res.data.category_id;
								newData.category = res.data.category;

								setCurrentStudio(newData);
								setIsLoading(false);
								setIsVisible(false);
							}).catch (error => {
								setIsLoading(false);
								console.log(error);
							});
						}}
						style={{
							width: 132,
							marginLeft: 16,
							fontWeight: '600',
							fontSize: 16,
							height: 48,
							borderRadius: 8,
							backgroundColor: '#19994F',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'row'
						}}
					>
						<Text
							style={{
								fontWeight: '600',
								fontSize: 16,
								color: 'white'
							}}
						>Save</Text>
						{isLoading && <ActivityIndicator color={'white'} style={{marginLeft: 8}}/>}
					</TouchableOpacity>
				</View>
			</View>
		</View>
	)
}
export default EditStudioScreen;