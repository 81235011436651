import React from 'react';
import Responsive from "../helpers/Reponsive";
import {ScrollView} from "react-native";

const MobileScrollView = ({...props}) => {
  const { isMobile } = Responsive();

  return isMobile ? (
    <ScrollView
      horizontal={true}
      showsHorizontalScrollIndicator={false}
      style={{
        marginHorizontal: -24,
        paddingLeft: 24,
        flexDirection: 'row',
        paddingRight: 24
      }}
    >
      {props.children}
    </ScrollView>
  ) : (
    <>
      {props.children}
    </>
  );
};

export default MobileScrollView;