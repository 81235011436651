import React from 'react';
import {Image, StyleSheet, Text, View} from 'react-native';
import SortableImages from "./SortableImages";
import {arrayMove} from "react-sortable-hoc";
import {toast} from "react-toastify";

const ProgressBar = ({percentage = 0, shouldShow = true, ...props}) => {
  return shouldShow && (
    <View
      style={{
        borderRadius: 6,
        marginTop: 16,
        padding: 16,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#edfdf4'
      }}
    >
      <Text
        style={{
          color: '#096730'
        }}
      >Please do not close the window as the upload is in progress.</Text>
      <View
        style={{
          height: 8,
          width: '100%',
          backgroundColor: '#e9ecef',
          borderRadius: 4,
          marginTop: 16
        }}
      >
        <View
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            height: 8,
            width: percentage + '%',
            backgroundColor: '#096730',
            borderRadius: 4,
          }}
        />
      </View>
    </View>
  );
};

export default ProgressBar;