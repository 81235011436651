import React, {useEffect, useRef, useState} from "react";
import {
	Animated,
	Easing,
	View,
} from "react-native";
import {NavigationContainer, useTheme, useRoute, useNavigation} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import OneSignal from 'react-onesignal';
import AppStyles from "../AppStyles";
import SignupScreen from "../screens/signup/SignupScreen";
import WelcomeScreen from "../screens/WelcomeScreen";
import {connect} from "react-redux";
import {ThemeContext} from "../components/ThemeContext";
import AccountTypeScreen from "../screens/signup/AccountTypeScreen";
import DownloadAppScreen from "../screens/signup/DownloadAppScreen";
import UserInfoScreen from "../screens/signup/UserInfoScreen";
import InitializationScreen from "../screens/onBoarding/InitializationScreen";
import SigninScreen from "../screens/signup/SigninScreen";
import PasswordScreen from "../screens/signup/PasswordScreen";
import DashboardScreen from "../screens/dashboard/DashboardScreen";
import BookingsScreen from "../screens/dashboard/BookingsScreen";
import DashboardSideBar from "../components/DashboardSideBar";
import EarningsScreen from "../screens/dashboard/EarningsScreen";
import MessagesScreen from "../screens/dashboard/MessagesScreen";
import RoomsScreen from "../screens/dashboard/RoomsScreen";
import StudiosScreen from "../screens/dashboard/StudiosScreen";
import HelpScreen from "../screens/dashboard/HelpScreen";
import EditStudioScreen from "../screens/modals/EditStudioScreen";
import HeaderContainer from "../components/HeaderContainer";
import NewStudioScreen from "../screens/dashboard/NewStudioScreen";
import NewRoomScreen from "../screens/dashboard/NewRoomScreen";
import {store} from "../store/configureStore";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Echo from "laravel-echo";
import Socketio from "socket.io-client";
import runOneSignal from '../helpers/OneSignal';
import ForgotPasswordScreen from "../screens/signup/ForgotPasswordScreen";
import ResetPasswordScreen from "../screens/signup/ResetPasswordScreen";
import SetNewPasswordScreen from "../screens/signup/SetNewPasswordScreen";
import VerifyPhoneScreen from "../screens/signup/VerifyPhoneScreen";
import Responsive from "../helpers/Reponsive";
import SettingsScreen from "../screens/dashboard/SettingsScreen";
import NotFoundScreen from "../screens/NotFoundScreen";
import CustomBookingScreen from "../screens/dashboard/CustomBookingScreen";
const GLOBAL = require('../config/Global');

const noTransitionConfig = () => ({
	transitionSpec: {
		duration: 0,
		timing: Animated.timing,
		easing: Easing.step0,
	},
});

const Auth = createStackNavigator();
const Dashboard = createStackNavigator();
const Root = createStackNavigator();
const OnBoarding = createStackNavigator();
const VerifyPhone = createStackNavigator();

const VerifyPhoneStack = () => {
  return (
    <VerifyPhone.Navigator
      initialRouteName="VerifyPhoneScreen"
      screenOptions={{
        headerShown: false
      }}
    >
      <VerifyPhone.Screen name="VerifyPhoneScreen" component={VerifyPhoneScreen} />
    </VerifyPhone.Navigator>
  );
};

const AuthStack = () => {
	return (
		<Auth.Navigator

			initialRouteName="WelcomeScreen"
			screenOptions={{
				headerShown: false
			}}
		>
			<Auth.Screen name="WelcomeScreen" component={WelcomeScreen}/>
			<Auth.Screen name="SignupScreen" component={SignupScreen} />
			<Auth.Screen name="SigninScreen" component={SigninScreen} />
			<Auth.Screen name="AccountTypeScreen" component={AccountTypeScreen} />
			<Auth.Screen name="DownloadAppScreen" component={DownloadAppScreen} />
			<Auth.Screen name="PasswordScreen" component={PasswordScreen} />
			<Auth.Screen name="UserInfoScreen" component={UserInfoScreen} />
			<Auth.Screen name="ForgotPasswordScreen" component={ForgotPasswordScreen} />
			<Auth.Screen name="ResetPasswordScreen" component={ResetPasswordScreen} />
			<Auth.Screen name="SetNewPasswordScreen" component={SetNewPasswordScreen} />
		</Auth.Navigator>
	);
};

const OnBoardingStack = () => {
	return (
		<OnBoarding.Navigator
			initialRouteName="InitializationScreen"
			screenOptions={{
				headerShown: false
			}}
		>
			<OnBoarding.Screen name="InitializationScreen" component={InitializationScreen}/>
		</OnBoarding.Navigator>
	);
};
const DashboardStack = ({auth, display, dispatch}) => {
  const [initialized, setInitialized] = useState(false);
  const { isMobile } = Responsive();

  return (
		<View
			style={{
				flexDirection: 'row',
				minHeight: '100vh',
				paddingTop: 80
			}}
		>
			<HeaderContainer />
      {!isMobile && (
        <View
					style={{
						zIndex: 1
					}}
        >
          <DashboardSideBar />
        </View>
      )}
			<View
				style={{
					flex: 1,
					width: '100%',
					backgroundColor: '#f9f9f9',
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				<View
					style={{
						flexGrow: 1,
						maxWidth: 1080,
						width: '100%'
					}}
				>
					<Dashboard.Navigator
						initialRouteName="DashboardScreen"
						screenOptions={{
							headerShown: false
						}}
					>
						<Dashboard.Screen name="DashboardScreen" component={DashboardScreen} />
						<Dashboard.Screen name="CustomBookingScreen" component={CustomBookingScreen} />
						<Dashboard.Screen name="BookingsScreen" component={BookingsScreen} />
						<Dashboard.Screen name="EarningsScreen" component={EarningsScreen} />
						<Dashboard.Screen name="MessagesScreen" component={MessagesScreen} />
						<Dashboard.Screen name="RoomsScreen" component={RoomsScreen} />
						<Dashboard.Screen name="StudiosScreen" component={StudiosScreen} />
						<Dashboard.Screen name="HelpScreen" component={HelpScreen} />
						<Dashboard.Screen name="NewStudioScreen" component={NewStudioScreen} />
						<Dashboard.Screen name="NewRoomScreen" component={NewRoomScreen} />
						<Dashboard.Screen name="SettingsScreen" component={SettingsScreen} />
					</Dashboard.Navigator>
				</View>
			</View>
		</View>
	);
};

const RootNavigator = ({auth, display, dispatch}) => {
	const navigation = useNavigation();
	const isLogged = auth.isLogged;
	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		OneSignal.init({ appId: 'e3076dc2-3fb9-4b84-9bdf-737566e34e9b'});
		OneSignal.isPushNotificationsEnabled(function(isEnabled) {
			if (isEnabled)
				console.log("Push notifications are enabled!");
			else
				console.log("Push notifications are not enabled yet.");
		});
		OneSignal.showSlidedownPrompt();

		if(isLogged) {
      if(!auth.user.phone_number_confirmed) {
        setTimeout(() => {
          navigation.navigate('VerifyPhoneStack');
        }, 2000)
      }
			OneSignal.setExternalUserId(auth.user.id);
			OneSignal.getExternalUserId().then(function(externalUserId){
				console.log("externalUserId: ", externalUserId);
			});

			OneSignal.on('notificationDisplay', function(event) {
				console.warn('OneSignal notification displayed:', event);
			});

			OneSignal.on('permissionPromptDisplay', function () {
				console.log("The prompt displayed");
			});

			OneSignal.on('notificationDismiss', function (event) {
				let data = event.data;
				if(data.type === 'ChatMessageWasReceived') {
					navigation.navigate('MessagesScreen');
				}
				console.log('OneSignal notification dismissed:', event);
			});

			OneSignal.on('subscriptionChange', function(isSubscribed) {
				console.log("The user's subscription state is now:", isSubscribed);
			});


			const EchoServer = new Echo({
				broadcaster: 'socket.io',
				host: GLOBAL.SOCKET_URL,
				transports:["websocket","polling","flashsocket"],
				client: Socketio,
				auth:{
					headers: {
						Accept: 'application/json',
						'Authorization': `Bearer ${auth.accessToken}`,
					},
				},
			});

			const Channel = EchoServer.private(`App.Models.User.${auth.user.id}`);

			Channel.listen('.App\\Events\\ChatMessageWasReceived', (callback) => {
				console.log('.App\\Events\\ChatMessageWasReceived', callback);

				dispatch({
					type: 'NEW_MESSAGE_WAS_RECEIVED', content: callback.message
				})
			})
		}
	}, [isLogged]);

	return (
		<Root.Navigator
			initialRouteName={DashboardStack}
			screenOptions={{
				headerShown: false,
				transitionConfig: noTransitionConfig,
			}}
		>
			<Root.Group>
				{auth.isLogged ? (
				<>
					<Root.Screen name="DashboardStack" component={DashboardStack} />
					<Root.Screen name="OnBoardingStack" component={OnBoardingStack} />
          <Root.Screen name="VerifyPhoneStack" component={VerifyPhoneStack} />
        </>
				) : (
				<>
					<Root.Screen name="AuthStack" component={AuthStack} />
				</>
				)}
			</Root.Group>
			<Root.Group screenOptions={{ presentation: 'modal' }}>
				<Root.Screen name="EditStudioScreen" component={EditStudioScreen}/>
			</Root.Group>
			<Root.Group>
				<Root.Screen name="NotFound" component={NotFoundScreen} />
			</Root.Group>
		</Root.Navigator>
	);
};

const AppNavigator = ({auth, display, dispatch}) => {
	const [theme, setTheme] = useState( 'Light');

	const themeData = { theme, setTheme };
	const {colors, dark} = useTheme();

	const linking = {
		enabled: true,
		prefixes: ['http://localhost:3000', 'stugo://'],
		config: {
			screens: {
				AuthStack: {
					screens: {
						WelcomeScreen: '/welcome',
						SigninScreen: 'signin',
						SignupScreen: 'signup',
						AccountTypeScreen: 'signup/account-type',
						UserInfoScreen: 'signup/user-info',
						DownloadAppScreen: 'app/introduction',
						ForgotPasswordScreen: 'forgot-password'
					}
				},
        VerifyPhoneStack: {
          screens: {
            VerifyPhoneScreen: 'verify/phone'
          }
        },
				PasswordStack: {
					screens: {
						PasswordScreen: 'signup/password',
					}
				},
				OnBoardingStack: {
					screens: {
						InitializationScreen: 'initialization',
					}
				},
				DashboardStack: {
					screens: {
						DashboardScreen: '/',
						BookingsScreen: 'bookings',
						EarningsScreen: 'earnings',
						MessagesScreen: 'messages',
						RoomsScreen: 'rooms',
						StudiosScreen: 'studios',
						SettingsScreen: 'settings',
						HelpScreen: 'help',

						BroadcastScreen: {
							screens: {
								HistoryScreen: 'broadcast/history',
								ScheduledBroadcastScreen: 'broadcast/scheduled',
								TemplateReplyScreen: 'broadcast/templates',
							}
						},
						FlowTemplateScreen: 'flow-templates',
						FlowBuilderScreen: {
							path: 'flow-builder/:id',
							parse: {
								id: (id) => id,
							}
						},
					},
				},
				NotFound: '*',
			},
		},
	};

	return (
		<ThemeContext.Provider value={themeData}>
			<NavigationContainer linking={linking} theme={AppStyles.lightColorSet}>
				<RootNavigator auth={auth} display={display} dispatch={dispatch}/>
				<ToastContainer hideProgressBar={true}/>
			</NavigationContainer>
		</ThemeContext.Provider>
	);
};

export default connect(({auth, display, message}) => ({auth, display, message}))(AppNavigator);
