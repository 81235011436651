const types = {
  FAVORITE_CHANGE: "FAVORITE_CHANGE",
  BOOKING_CHANGE: "BOOKING_CHANGE",
  EXTRA_UPDATE: "EXTRA_UPDATE",
};

const initialState = {
  favoriteChange: 0,
  bookingChange: 0,
  extra: {}
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.FAVORITE_CHANGE:
      return {
        ...state,
        favoriteChange: state.favoriteChange + 1
      };
    case types.BOOKING_CHANGE:
      return {
        ...state,
        favoriteChange: state.bookingChange + 1
      };
    case types.EXTRA_UPDATE:
      return {
        ...state,
        extra: action.extra
      };
    default:
      return state;
  }
};