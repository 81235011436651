import React, {useMemo, useState} from "react";
import {
  Text,
  View,
  TextInput, ScrollView, StyleSheet, Image, TouchableOpacity
} from "react-native";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Responsive from "../../helpers/Reponsive";

const Accordion = ({ title, description, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <View
      style={{
        borderBottomWidth: 1,
        borderColor: '#D2D2D2'
      }}
    >
      <TouchableOpacity
        onPress={() => setIsActive(!isActive)}
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          height: 56
        }}
      >
        <View
          style={{
            flex: 1
          }}
        >
          <Text
            style={{
              fontWeight: '500',
              fontSize: 16
            }}
          >{title}</Text>
          <Text
            style={{
              color: '#909090',
              marginTop: 8
            }}
          >{description}</Text>
        </View>
        <View>
          {isActive ? (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M18.7071 15.7071C18.3166 16.0976 17.6834 16.0976 17.2929 15.7071L12 10.4142L6.70711 15.7071C6.31658 16.0976 5.68342 16.0976 5.29289 15.7071C4.90237 15.3166 4.90237 14.6834 5.29289 14.2929L11.2929 8.29289C11.6834 7.90237 12.3166 7.90237 12.7071 8.29289L18.7071 14.2929C19.0976 14.6834 19.0976 15.3166 18.7071 15.7071Z" fill="#6D6E6F" />
            </svg>
          ) : (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z" fill="#6D6E6F" />
            </svg>
          )}
        </View>
      </TouchableOpacity>
      {isActive && (
        <View
          style={{
            marginVertical: 16
          }}
        >{content}</View>
      )}
    </View>
  );
};

function HelpScreen ({navigation, route}) {
  const { isMobile } = Responsive();
  const accordionData = [
    {
      title: 'How to add room in Stugo?',
      description: 'How to add a room in stugo\'s owner panel?',
      content: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis sapiente
      laborum cupiditate possimus labore, hic temporibus velit dicta earum
      suscipit commodi eum enim atque at? Et perspiciatis dolore iure
      voluptatem.`
    },
    {
      title: 'How to delete a room',
      description: 'Description of Section 1',
      content: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia veniam
      reprehenderit nam assumenda voluptatem ut. Ipsum eius dicta, officiis
      quaerat iure quos dolorum accusantium ducimus in illum vero commodi
      pariatur? Impedit autem esse nostrum quasi, fugiat a aut error cumque
      quidem maiores doloremque est numquam praesentium eos voluptatem amet!
      Repudiandae, mollitia id reprehenderit a ab odit!`
    },
    {
      title: 'How to create a new Studio',
      description: 'Description of Section 1',
      content: `Sapiente expedita hic obcaecati, laboriosam similique omnis architecto ducimus magnam accusantium corrupti
      quam sint dolore pariatur perspiciatis, necessitatibus rem vel dignissimos
      dolor ut sequi minus iste? Quas?`
    }
  ];

  return (
		<View
			style={{
				flex: 1,
			}}
		>
      <ScrollView
        style={{
          flex: 1,
          padding: isMobile ? 24 : 40
        }}
      >
        <Text
          style={{
            fontSize: 48,
            fontWeight: '600'
          }}
        >Help</Text>
        <Text
          style={{
            marginTop: 16,
            fontSize: 20,
            color: '#595959'
          }}
        >Help and support, if you have any questions and can't find it talk to our support!</Text>
        <Text
          style={{
            marginTop: 24
          }}
        >F.A.Q.</Text>
        <Text
          style={{
            fontSize: 24,
            fontWeight: 500,
            color: '#096730',
            marginBottom: 24
          }}
        >Frequently Asked Questions</Text>
        {accordionData.map(({ title, description, content }, index) => (
          <Accordion title={title} description={description} content={content} key={index}/>
        ))}
      </ScrollView>
		</View>
	);
}

const styles = StyleSheet.create({
	tableHeader: {
		fontWeight: '600',
		textAlign: 'left',
		alignSelf: 'flex-start'
	},
	tableCell: {
		borderBottom: '1px solid',
		borderBottomColor: 'gray',
		paddingLeft: 16,
		paddingRight: 16
	},
});

HelpScreen.propTypes = {
	navigation: PropTypes.object,
};

export default connect(({auth}) => ({auth}))(HelpScreen);