import React, {useEffect, useMemo, useRef, useState} from "react";
import {
	Text,
	View,
	TouchableOpacity, ScrollView, StyleSheet, Image, TextInput, ActivityIndicator
} from "react-native";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import API from "../helpers/Axios";
import moment from 'moment';
import Socketio from 'socket.io-client';
import Echo from "laravel-echo";
import Moment from "moment";
import {useTheme} from "@react-navigation/native";

function MessagesScreen ({navigation, route, auth, bookingData, message}) {
	const textInput = useRef();
	const scrollViewRef = useRef();
  const {colors, dark} = useTheme()

	const [isLoading, setIsLoading] = useState(true);
	const [messages, setMessages] = useState([]);
	const [currentConversation, setCurrentConversation] = useState(null);
	const [isMessagesLoading, setIsMessagesLoading] = useState(true);
	const [searchString, setSearchString] = useState('');
	const [messageString, setMessageString] = useState('');
	const [currentBooking, setCurrentBooking] = useState(null);

	const sendMessage = () => {
		if(!messageString.length) {
			return
		}
		const data = new FormData();
		const lid = (+ new Date());
		data.append('conversation_id', currentConversation.id);
		data.append('lid', lid);
		data.append('message', messageString.trim());
		data.append('type', 'text');

		const message = {
			"lid": lid,
			"body": messageString.trim(),
			"is_sender": 1,
			"type": "text"
		}

		setMessages([...messages, message]);
		setMessageString('');
		scrollMessagesToBottom();
		API.post('chat/send-message', data)
			.then(res => {
				let message = res.data;
				console.log(message);
				textInput.current.focus();
			}).catch (error => {
			console.log(error)
		});
	}

	const getConversationTitle = (conversation) => {
		return 'Chat Box'
	}

	const getConversationArtwork = (conversation) => {
		let array = [];
		conversation.participants.forEach((item) => {
			if(item.messageable_type === 'App\\Models\\Booking') {
				array.push(item.messageable.participant_artwork_url)
			}
		})
		return array[0]
	}

	const getBookingDetailFromConversation = (conversationId) => {
		API.post(`booking/get-details-from-conversation`, {id: conversationId})
			.then(res => {
				setCurrentBooking(res.data)
			}).catch (error => {
			console.log(error.response);
		});
	}

	const scrollMessagesToBottom = () => {
		setTimeout(() => {
			try {
				scrollViewRef.current.scrollToEnd({ animated: true })
			} catch (e) {

			}
		}, 500);
	}

		const getConversationById = (conversationId) => {
		setIsMessagesLoading(true);
		API.post(`chat/get-conversation-messages`, {id: conversationId})
			.then(res => {
				setMessages(res.data.data.reverse());
				setIsMessagesLoading(false);
				scrollMessagesToBottom();
			}).catch (error => {
			console.log(error.response);
		});
	}

	useEffect(() => {
		API.post(`chat/booking`, {
			id: bookingData.id
		}).then(res => {
				setCurrentConversation(res.data);
				getConversationById(res.data.id)
				setIsLoading(false);
			}).catch (error => {
			console.log(error);
		});
	}, []);

	const usePrevious = (value) => {
		const ref = useRef();
		useEffect(() => {
			ref.current = value;
		});
		return ref.current;
	}

	const {receiveAmount, sendAmount } = message.messageWasReceived;

	const prevAmount = usePrevious({receiveAmount, sendAmount});

	useEffect(() => {
		if(prevAmount !== receiveAmount) {
			if(message.content.sender.id !== auth.user.id) {
				setMessages([...messages, message.content]);
				scrollMessagesToBottom();
			}
		}
	}, [message]);

	useEffect(() => {
		if(currentConversation) {
			getBookingDetailFromConversation(currentConversation.id)
		}
	}, [currentConversation])

	const renderMessages = () => {
		return messages.map((item, index) => (
			<View
				key={index}
				style={{
					backgroundColor: item.is_sender ? '#3DBA71' : 'white',
					borderRadius: 16,
					borderBottomRightRadius: item.is_sender ? 0 : 16,
					borderBottomLeftRadius: item.is_sender ? 16 : 0,
					padding: 10,
					maxWidth: 320,
					alignSelf: item.is_sender ? 'flex-end' : 'flex-start',
					marginBottom: 16,
				}}
			>
				<Text
					style={{
						fontSize: 16,
						lineHeight: 22,
						color: item.is_sender ? 'white' : 'black'
					}}
				>{item.body}</Text>
				<Text
					style={{
						marginTop: 8,
						fontSize: 12,
						color: item.is_sender ? 'white' : 'black'
					}}
				>{moment(item.created_at).format("h:mm a")}</Text>
			</View>
		))
	}

	return isLoading ? (<ActivityIndicator style={{flex: 1, justifyContent: 'center', alignItems: 'center'}} />) : (
		<View
			style={{
				flex: 1,
			}}
		>
			<View
			>
				<View
				>
					{currentConversation && (
						<>
              <View
                style={{
                  height: 280
                }}
              >
                {isMessagesLoading ? (<ActivityIndicator style={{backgroundColor: '#f8f8f8', borderWidth: 1, borderColor: '#E4E1E0', flex: 1, justifyContent: 'center', alignItems: 'center'}} />) : (
                  <>
                    {!messages.length && (
                      <View
                        style={{
                          borderWidth: 1,
                          borderColor: '#E4E1E0',
                          height: 280,
                          backgroundColor: '#F8F8F8',
                          borderRadius: 8,
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <svg width={64} height={64} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.51789 35.1872C6.28151 31.4172 5.49832 26.9605 6.31537 22.6539C7.13242 18.3473 9.49351 14.4871 12.9553 11.7982C16.4171 9.10923 20.7414 7.77652 25.1162 8.05028C29.4911 8.32405 33.6155 10.1855 36.715 13.285C39.8146 16.3845 41.676 20.509 41.9498 24.8838C42.2236 29.2587 40.8909 33.583 38.202 37.0448C35.5131 40.5065 31.6529 42.8677 27.3463 43.6847C23.0397 44.5018 18.583 43.7186 14.813 41.4823L14.813 41.4821L8.59615 43.2583C8.33894 43.3318 8.06676 43.3352 7.80781 43.2681C7.54886 43.201 7.31257 43.0658 7.12341 42.8767C6.93426 42.6875 6.79913 42.4512 6.73203 42.1923C6.66493 41.9333 6.6683 41.6612 6.74179 41.4039L8.51804 35.1871L8.51789 35.1872Z" stroke="#CCCCCC" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M23.0156 43.9731C23.9005 46.4851 25.3314 48.7697 27.2054 50.6621C29.0794 52.5546 31.3497 54.0079 33.8529 54.9173C36.3561 55.8268 39.0299 56.1698 41.6816 55.9217C44.3333 55.6735 46.897 54.8404 49.1879 53.4822L49.1879 53.482L55.4048 55.2583C55.662 55.3318 55.9341 55.3351 56.1931 55.268C56.452 55.2009 56.6883 55.0658 56.8775 54.8767C57.0666 54.6875 57.2018 54.4512 57.2689 54.1923C57.336 53.9333 57.3326 53.6611 57.2591 53.4039L55.4829 47.1871L55.483 47.1872C57.0612 44.5255 57.9268 41.5021 57.9963 38.4085C58.0658 35.3149 57.3368 32.2556 55.8797 29.5258C54.4226 26.796 52.2866 24.4878 49.6776 22.8239C47.0687 21.16 44.075 20.1965 40.9853 20.0265" stroke="#CCCCCC" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <Text
                          style={{
                            marginTop: 8,
                            color: colors.mainTextColor,
                            fontWeight: 'bold',
                          }}
                        >No messages, yet.</Text>
                        <Text
                          style={{
                            marginTop: 8,
                            color: colors.mainSubtextColor,
                          }}
                        >Your direct messages to {currentConversation.subject || currentConversation.conversation.subject} will also be here.</Text>
                      </View>
                    )}
                    {!!messages.length && (
                      <ScrollView
                        ref={scrollViewRef}
                        style={{
                          flex: 1,
                          backgroundColor: '#f8f8f8' ,
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 16,
                          borderWidth: 1,
                          borderColor: '#E4E1E0',
                          overflow: 'hidden',
                          borderRadius: 4
                        }}
                        onScroll={
                          ({ nativeEvent }) => {
                            /*if (this.isCloseToBottom(nativeEvent) && !this.isLoadingMore && this.state.next_page_url) {
                                this.isLoadingMore = true;
                                this.setState({indicator: true});
                                this.fetch();
                            }*/
                          }
                        }
                        onScrollToTop={() => {
                          /*if(! this.isLoadingMore && this.state.next_page_url) {
                              this.isLoadingMore = true;
                              this.setState({indicator: true});
                              this.fetch();
                          }*/
                        }}
                        scrollEventThrottle={16}
                      >
                        {renderMessages()}
                      </ScrollView>
                    )}
                  </>
                )}
              </View>
							<View
								style={{
									marginTop: 16,
									backgroundColor: '#f8f8f8' ,
									paddingTop: 16,
									paddingLeft: 16,
									paddingRight: 16,
									borderWidth: 1,
									borderColor: '#E4E1E0',
									overflow: 'hidden',
									borderRadius: 8,
									paddingBottom:8,
								}}>
								<TextInput
									ref={textInput}
									placeholder={`Send message to ${currentConversation.subject || currentConversation.conversation.subject}`}
									style={{
										borderColor: '#AFABAC',
										flex: 1,
										outline: 0,
										fontSize: 16
									}}
									value={messageString}
									onChangeText={text => {
										setMessageString(text)
									}}
									multiline={true}
									numberOfLines={4}
									blurOnSubmit={true}
									onSubmitEditing={() => sendMessage()}
								/>
								<View
									style={{
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'space-between'
									}}
								>
									<TouchableOpacity
										style={{
											backgroundColor: 'white',
											height: 26,
											borderRadius: 13,
											paddingHorizontal: 8,
											flexDirection: 'row',
											alignItems: 'center'
										}}
									>
										<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M10.52 1.592c-.53 0-1.04.21-1.415.586L2.978 8.305a3.336 3.336 0 1 0 4.717 4.717l6.127-6.127a.667.667 0 1 1 .943.943l-6.127 6.127a4.669 4.669 0 1 1-6.603-6.603l6.127-6.127a3.335 3.335 0 1 1 4.716 4.716l-6.133 6.127a2 2 0 0 1-2.83-2.83l5.66-5.653a.667.667 0 1 1 .943.943l-5.66 5.653a.668.668 0 0 0 .944.944l6.133-6.126a2.002 2.002 0 0 0-1.415-3.417Z"
												fill="#6D6E6F"
											/>
										</svg>
										<Text
											style={{
												marginLeft: 8,
												fontSize: 12,
												color: '#595959'
											}}
										>Attach file - PDF, JPG, PNG</Text>
									</TouchableOpacity>
									<TouchableOpacity
										onPress={() => sendMessage()}
										style={{
											flexDirection: 'row',
											alignItems: 'center',
										}}
									>
										<Text
											style={{
												marginRight: 8,
												color: '#096730',
												fontWeight: '600'
											}}
										>Send message</Text>
										<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M15.138.862c.26.26.26.682 0 .943L7.805 9.138a.667.667 0 1 1-.943-.943L14.195.862c.26-.26.683-.26.943 0Z"
												fill="#096730"
											/>
											<path
												fillRule="evenodd"
												clipRule="evenodd"
												d="M15.138.862c.181.181.243.45.158.692l-4.667 13.333a.667.667 0 0 1-1.238.05L6.828 9.172 1.063 6.609a.667.667 0 0 1 .05-1.238L14.447.704a.667.667 0 0 1 .691.158ZM3.141 6.074l4.463 1.983c.151.067.272.188.339.339l1.983 4.463L13.58 2.42 3.14 6.074Z"
												fill="#096730"
											/>
										</svg>
									</TouchableOpacity>
								</View>
							</View>
						</>
					)}
				</View>
			</View>
		</View>
	);
}

MessagesScreen.propTypes = {
	navigation: PropTypes.object,
};

export default connect(({auth, message}) => ({auth, message}))(MessagesScreen);
