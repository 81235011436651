import React, {useState} from 'react'
import {View, Picker, Text, TextInput} from 'react-native'

const RoundPicker = ({ data = [], value = '', placeholder = 'Placeholder', name, error = false, errorString = 'Error', onValueChange, onPress, style, textStyle, ...props}) => {
  if(name === undefined) {
    name = placeholder;
  }


  const localOnValueChange = (value, index) => {
    onValueChange(value);
  }
  
  return (
    <View>
      <View
        style={{
          borderWidth: 1,
          borderColor: '#AFABAC',
          borderRadius: 8,
          backgroundColor: 'white',
          paddingHorizontal: 16,
          paddingVertical: 8,
          minHeight: 60,
          justifyContent: 'center'
        }}
      >
        <Text
          style={{
            textTransform: 'uppercase',
            fontWeight: '500',
            fontSize: 12,
            color: error ? '#B96363' : (value.length > 0 ? '#096730' : '#8692A6'),
            marginBottom: 4
          }}
          >{name}</Text>
        <Picker
          onValueChange={localOnValueChange}
          style={{
            border: 0,
            outline: 0,
            opacity: (value > 0) ? 1 : .55,
            padding: 0
          }}
          value={value}
        >
          <Picker.Item
            label={placeholder}
            value={0}
          />
          {data.map((item, index) => (
            <Picker.Item
              key={index}
              label={item.name}
              value={item.id}
            />
          ))}
        </Picker>
      </View>
      {error && <Text
        style={{
          color: '#B96363',
          marginTop: 4,
          marginLeft: 16
        }}
      >{errorString}</Text>}
    </View>

  )
}

export default RoundPicker
