import React, {useEffect, useState} from "react";
import {
	Text,
	View,
	ScrollView, StyleSheet, Image, ActivityIndicator, Modal
} from "react-native";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import API from "../../helpers/Axios";
import {TouchableOpacity} from "react-native-web";
import Calendar from 'react-calendar';
import StarRatings from 'react-star-ratings';
import '../../styles/calendar.css';
import moment from "moment";
import ReviewsScreen from "../modals/ReviewsScreen";
import { Calendar as BigCalendar, momentLocalizer  } from 'react-big-calendar'
import AppStyles from "../../AppStyles";
import Responsive from "../../helpers/Reponsive";
import MobileScrollView from "../../components/MobileScrollView";
const localizer = momentLocalizer(moment);

const DashboardScreen = ({navigation, auth}) =>  {
  const { isMobile } = Responsive();
  const [isLoading, setIsLoading] = useState(true);
	const [studios, setStudios] = useState([]);
	const [bookings, setBookings] = useState([]);
	const [currentStudio, setCurrentStudio] = useState(null);
	const [currentDate, setCurrentDate] = useState(new Date());
	const [stats, setStats] = useState(null);
	const [reviews, setReviews] = useState([]);
	const [isVisible, setIsVisible] = useState(false);
	const [isDataLoading, setIsDataLoading] = useState(true);
	const [rooms, setRooms] = useState([]);
	const [currentRoom, setCurrentRoom] = useState(null);
	const [menuDisplay, setMenuDisplay] = useState( false);
	const [conversations, setConversations] = useState([]);
	const [events, setEvents] = useState([]);

	const getStudioDashBoardData = (studioId) => {
		setIsDataLoading(true);
		API.get(`owner/dashboard/studio/${studioId}`)
			.then(res => {
				setBookings(res.data.bookings.data);
				setStats(res.data.stats);
				setReviews(res.data.reviews);
				setRooms(res.data.rooms);
				if(res.data.rooms.length) {
					setCurrentRoom(res.data.rooms[0]);
				}
				setConversations(res.data.conversations.data)
				setIsDataLoading(false);
			}).catch (error => {
			console.log(error);
		});
	}

	const getConversationTitle = (conversation) => {
		let array = [];
		conversation.participants.forEach((item) => {
			if(item.messageable_type !== 'App\\Models\\User') {
				array.push(item.messageable.participant_name)
			}
		})
		return array.join(', ')
	}

	const getRoomEvents = (room_id) => {
		API.post('owner/get-day-events', {
			room_id: room_id,
			day: currentDate
		})
			.then(res => {
				let events = [];
				res.data.forEach((item) => {
					events.push({
						id: item.id,
						title: `Room: ${item.room.name}, by: ${item.user.name}`,
						start: moment.utc(item.start_time).local().toDate(),
						end: moment.utc(item.end_time).local().toDate(),
					});
				});
				setEvents(events);
			})
	}

	useEffect(() => {
		API.get('owner/dashboard')
			.then(res => {
				if(res.data.length) {
					setStudios(res.data);
					setCurrentStudio(res.data[0]);
					getStudioDashBoardData(res.data[0].id);
					setIsLoading(false);
				} else {
					navigation.replace('OnBoardingStack');
				}
			}).catch (error => {
			console.log(error);
		});
	}, []);

	return isLoading ? (<ActivityIndicator style={{flex: 1, justifyContent: 'center', alignItems: 'center'}} />) : (
		<View
			style={{
				flex: 1
			}}
		>
			<Modal onRequestClose={() => setIsVisible(false)} transparent visible={isVisible}>
				<View
					style={{
						backgroundColor: 'white',
						borderColor: '#eee',
						borderRadius: 8,
						borderWidth: 1,
						margin: 'auto',
						width: 780,
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
					}}
				>
					<ReviewsScreen
						setIsVisible={setIsVisible}
						item={currentStudio}
					/>
				</View>
			</Modal>
			<ScrollView
				style={{
					flex: 1,
					padding: isMobile ? 24 : 40
				}}
			>
				<Text
					style={{
						fontSize: 48,
						fontWeight: '600'
					}}
				>Dashboard</Text>
				<Text
					style={{
						marginTop: 16,
						fontSize: 20,
						color: '#595959'
					}}
				>This is an overview of everything that is happening in your studio</Text>
				{studios.length > 0 && (
					<>
						<ScrollView
							horizontal={true}
              showsHorizontalScrollIndicator={false}
							style={{
								marginVertical: 40,
                marginHorizontal: isMobile ? -24 : 0,
                paddingLeft: isMobile ? 24 : 0,
								flexDirection: 'row'
							}}
						>
							{studios.map((item, index) => (
								<TouchableOpacity
									key={index}
									onPress={() => {
										setIsLoading(false);
										setCurrentStudio(item);
										getStudioDashBoardData(item.id);
									}}
									style={{
										width: 364,
										flexDirection: 'row',
										borderWidth: item.id === currentStudio.id ? 2 : 1,
										borderColor: item.id === currentStudio.id ? '#6EB28B' : '#E4E1E0',
										padding: 8,
										borderRadius: 8,
										backgroundColor: 'white',
										marginRight: 24
									}}
								>
									<View
										style={{
											width: 128,
											aspectRatio: 1.777,
											borderRadius: 4,
											backgroundColor: 'gray',
											marginRight: 16,
											overflow: 'hidden'
										}}
									>
										<Image
											style={{
												flex: 1
											}}
											source={{
												uri: item.artwork_url,
											}}
										/>
									</View>
									<View
										style={{
											flex: 1,
											justifyContent: 'center'
										}}
									>
										<Text
											style={{
												fontWeight: '600',
												color: '#096730',
												fontSize: 18
											}}
										>{item.name}</Text>
										<Text
											style={{
												fontWeight: '600',
												marginTop: 4,
												color: '#595959'
											}}
											numberOfLines={2}
										>{item.address}</Text>
									</View>
								</TouchableOpacity>
							))}
						</ScrollView>
						{isDataLoading && (
							<View
								style={{
									height: 180,
									justifyContent: 'center',
									alignItems: 'center'
								}}
							>
								<ActivityIndicator color={'#096730'} />
							</View>
						)}
						{!isDataLoading && (
							<>
								<View
									style={{
										flexDirection: 'row',
                    flexWrap: 'wrap',
										justifyContent: 'space-between'
									}}
								>
									<View
										style={{
											width: isMobile ? '100%': '70%',
											paddingRight: isMobile ? 0: 40
										}}
									>
										<Text
											style={{
												fontSize: 20,
												fontWeight: '500',
												marginBottom: 16
											}}
										>Last 30 days</Text>
                    <MobileScrollView>
                      <View
                        style={{
                          flexDirection: 'row',
                          marginHorizontal: -8
                        }}
                      >
                        {[
                          {
                            title: 'Net earnings',
                            value: `$${stats.bookings.revenue}`,
                            action: () => {
                              navigation.navigate('EarningsScreen');
                            },
                            icon: <svg width={25} height={25} fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12.853 15.902h-8.1a1.9 1.9 0 0 1-1.9-1.9v-9.1c0-1.05.85-1.9 1.9-1.9h15.2c1.05 0 1.9.85 1.9 1.9v5.1"
                                stroke="#58B17D"
                                strokeWidth={1.5}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M14.032 7.972a2.376 2.376 0 0 1-3.359 3.36 2.376 2.376 0 0 1 3.36-3.36M22.853 16.802v-2.8c0-.774-1.568-1.4-3.5-1.4s-3.499.627-3.5 1.4v5.6c.001.773 1.567 1.4 3.5 1.4s3.5-.627 3.5-1.4v-5.6"
                                stroke="#58B17D"
                                strokeWidth={1.5}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M15.854 14.002c0 .772 1.567 1.4 3.5 1.4s3.5-.627 3.5-1.4M15.854 16.802c0 .773 1.567 1.4 3.5 1.4s3.5-.628 3.5-1.4M6.853 13.002h-1M18.853 6.001h-1"
                                stroke="#58B17D"
                                strokeWidth={1.5}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          },
                          {
                            title: 'Current balance',
                            value: `$${AppStyles.utils.moneyFormat(stats.bookings.balance)}`,
                            action: () => {

                            },
                            icon: <svg width={25} height={25} fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12.853 15.902h-8.1a1.9 1.9 0 0 1-1.9-1.9v-9.1c0-1.05.85-1.9 1.9-1.9h15.2c1.05 0 1.9.85 1.9 1.9v5.1"
                                stroke="#58B17D"
                                strokeWidth={1.5}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M14.032 7.972a2.376 2.376 0 0 1-3.359 3.36 2.376 2.376 0 0 1 3.36-3.36M22.853 16.802v-2.8c0-.774-1.568-1.4-3.5-1.4s-3.499.627-3.5 1.4v5.6c.001.773 1.567 1.4 3.5 1.4s3.5-.627 3.5-1.4v-5.6"
                                stroke="#58B17D"
                                strokeWidth={1.5}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M15.854 14.002c0 .772 1.567 1.4 3.5 1.4s3.5-.627 3.5-1.4M15.854 16.802c0 .773 1.567 1.4 3.5 1.4s3.5-.628 3.5-1.4M6.853 13.002h-1M18.853 6.001h-1"
                                stroke="#58B17D"
                                strokeWidth={1.5}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          },
                          {
                            title: 'Open disputes',
                            action: () => {

                            },
                            value: '0',
                            icon:  <svg width={25} height={25} fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12.254 12.396 8.252 8.394M11.993 4.653l4.002 4.001M3.595 21.005h10.59M4.496 21.005l.545-1.632a2 2 0 0 1 1.894-1.369h3.912a2 2 0 0 1 1.9 1.369l.542 1.632"
                                stroke="#58B17D"
                                strokeWidth={1.5}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                clipRule="evenodd"
                                d="M9.49 7.3 7.289 9.055a1.836 1.836 0 0 0-.156 2.728l1.677 1.684a1.815 1.815 0 0 0 2.7-.143l1.777-2.207c.42-.522.897-.997 1.421-1.416l2.201-1.756a1.836 1.836 0 0 0 .156-2.727l-1.674-1.684a1.815 1.815 0 0 0-2.7.143l-1.78 2.207c-.42.523-.897.997-1.421 1.416Z"
                                stroke="#58B17D"
                                strokeWidth={1.5}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="m14.976 9.488 5.927 5.015a1.987 1.987 0 1 1-2.755 2.852l-5.237-5.767"
                                stroke="#58B17D"
                                strokeWidth={1.5}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          }
                        ].map((item, index) => (
                          <TouchableOpacity
                            onPress={item.action}
                            key={index}
                            style={{
                              width: '33.33%',
                              paddingHorizontal: 8,
                            }}
                          >
                            <View
                              style={{
                                flex: 1,
                                backgroundColor: 'white',
                                padding: 16,
                                borderRadius: 8,
                                shadowColor: "#000",
                                shadowOffset: {
                                  width: 0,
                                  height: 2,
                                },
                                shadowOpacity: 0.05,
                                shadowRadius: 3.84,
                                elevation: 5,
                              }}
                            >
                              {item.icon}
                              <Text
                                style={{
                                  fontSize: 20,
                                  fontWeight: '600',
                                  marginTop: 8
                                }}
                              >{item.value}</Text>
                              <Text
                                style={{
                                  fontSize: 16,
                                  marginTop: 8,
                                  color: '#909090'
                                }}
                              >{item.title}</Text>
                            </View>
                          </TouchableOpacity>
                        ))}
                      </View>
                    </MobileScrollView>
										<View
											style={{
												marginTop: 40,
												marginBottom: 16,
												flexDirection: 'row',
												justifyContent: 'space-between'
											}}
										>
											<Text
												style={{
													fontSize: 20,
													fontWeight: '500',
												}}
											>Studio reviews</Text>
											<View
												style={{
													flexDirection: 'row',
													alignItems: 'center'
												}}
											>
												<StarRatings
													rating={reviews.average}
													starRatedColor={'#58B17D'}
													numberOfStars={5}
													starDimension="16px"
													starSpacing="4px"
												/>
                        {!isMobile && (
                          <Text
                            style={{
                              marginLeft: 16
                            }}
                          ><Text style={{fontWeight: '600'}}>{reviews.average} stars</Text> ({reviews.total} reviews)</Text>
                        )}
											</View>
										</View>
										{!reviews.data.length ? (
											<View
												style={{
													backgroundColor: 'white',
													borderRadius: 6,
													paddingVertical: 24,
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Text>Your studio does not have any reviews yet.</Text>
											</View>
										) : (
											<View
												style={{
													flexDirection: 'row',
													backgroundColor: 'white',
													padding: 24,
													borderRadius: 8,
												}}
											>
												<View
													style={{
														flex: 1,
														flexDirection: 'row',
														backgroundColor: 'white',
														borderRadius: 8,
													}}
												>
													{reviews.data.map((item, index) => (
														<View
															key={index}
															style={{
																width: '33.33%',
																flexDirection: 'row'
															}}
														>

															<Image
																style={{
																	width: 20,
																	height: 20,
																	borderRadius: 10,
																	backgroundColor: 'gray',
																	marginRight: 8
																}}
																source={{
																	uri: item.user.artwork_url
																}}
															/>
															<View
																style={{
																	flex: 1
																}}
															>
																<Text
																	style={{
																		fontWeight: '600',
																	}}
																>{item.user.name}</Text>
																<Text
																	style={{
																		fontSize: 16,
																		marginTop: 8,
																		color: '#909090'
																	}}
																	numberOfLines={2}
																>{item.comments}</Text>
															</View>
														</View>
													))}
												</View>
												<TouchableOpacity
													onPress={() => setIsVisible(true)}
													style={{
														justifyContent: 'center',
														alignItems: 'center'
													}}
												>
													<svg
														width={16} height={16}
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 289.04 492"
													>
														<path d="M191.91 245.9 7.86 430a27 27 0 0 0 0 38.06l16.12 16.12a27 27 0 0 0 38.06 0L281.19 265a27.15 27.15 0 0 0 0-38.19L62.25 7.86a27 27 0 0 0-38.07 0L8.06 24a27 27 0 0 0 0 38.06Z" />
													</svg>
												</TouchableOpacity>
											</View>
										)}
										<View
											style={{
												marginTop: 40,
												marginBottom: 16,
												flexDirection: 'row',
												justifyContent: 'space-between',
												alignItems: 'center'
											}}
										>
											<Text
												style={{
													fontSize: 20,
													fontWeight: '500',
												}}
											>Messages</Text>
                      {!isMobile && (
                        <TouchableOpacity
                          onPress={() => navigation.navigate('MessagesScreen')}
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            borderWidth: 1,
                            borderColor: '#E4E1E0',
                            paddingHorizontal: 16,
                            paddingVertical: 8,
                            borderRadius: 6
                          }}
                        >
                          <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M3.333 4.667a.667.667 0 0 0-.666.667v7.333a.667.667 0 0 0 .666.667h7.334a.667.667 0 0 0 .666-.667v-4a.667.667 0 0 1 1.334 0v4a2 2 0 0 1-2 2H3.333a2 2 0 0 1-2-2V5.334a2 2 0 0 1 2-2h4a.667.667 0 0 1 0 1.333h-4ZM9.333 2c0-.368.299-.666.667-.666h4c.368 0 .667.298.667.667v4a.667.667 0 0 1-1.334 0V2.667H10a.667.667 0 0 1-.667-.666Z"
                              fill="#6D6E6F"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M14.471 1.53c.26.26.26.682 0 .942L7.138 9.805a.667.667 0 0 1-.943-.942l7.334-7.334c.26-.26.682-.26.942 0Z"
                              fill="#6D6E6F"
                            />
                          </svg>
                          <Text
                            style={{
                              fontWeight: '600',
                              marginLeft: 8
                            }}
                          >See all messages</Text>
                        </TouchableOpacity>
                      )}
										</View>
										{!conversations.length && (
											<View
												style={{
													backgroundColor: 'white',
													borderRadius: 6,
													paddingVertical: 24,
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Text>Your studio does not have any messages yet.</Text>
											</View>
										)}
										{conversations.map((item, index) => item.conversation.last_message && (
											<TouchableOpacity
												key={index}
												onPress={() => navigation.navigate('MessagesScreen')}
												style={{
													flexDirection: 'row',
													alignItems: 'center',
													justifyContent: 'space-between',
													shadowColor: "#000",
													shadowOffset: {
														width: 0,
														height: 2,
													},
													shadowOpacity: 0.05,
													shadowRadius: 3.84,
													elevation: 5,
													backgroundColor: 'white',
													borderRadius: 8,
													padding: 16,
													marginBottom: 16,
												}}
											>
												<View
													style={{
														flexDirection: 'row',
														alignItems: 'center',
                            flex: 1,
                            marginRight: 16
                          }}
												>
													<Image
														source={{
															uri: item.conversation.participants.find(participant => participant.messageable_id !== auth.user.id).messageable?.artwork_url ?? 'https://admin.stugo.com/storage/853/conversions/1698695030-lg.jpg'
														}}
														resizeMode={'cover'}
														style={{
															width: 50,
															height: 50,
															borderRadius: 25,
															backgroundColor: 'gray',
															marginRight: 24
														}}
													/>
													<View
														style={{
															flex: 1,
															justifyContent: 'center',
														}}
													>
														<View>
															<Text
																style={{
																	fontWeight: '600',
																	color: '#595959'
																}}
															>{item.subject || item.conversation.subject}</Text>
														</View>
														{item.conversation.last_message && (
															<Text
																style={{
																	fontSize: 16,
																	marginTop: 8,
																	color: '#1A1A1A',
																}}
																numberOfLines={2}
															>{item.conversation.last_message.body}</Text>
														)}
													</View>
												</View>
												<svg
													width={16} height={16}
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 289.04 492"
												>
													<path d="M191.91 245.9 7.86 430a27 27 0 0 0 0 38.06l16.12 16.12a27 27 0 0 0 38.06 0L281.19 265a27.15 27.15 0 0 0 0-38.19L62.25 7.86a27 27 0 0 0-38.07 0L8.06 24a27 27 0 0 0 0 38.06Z" />
												</svg>
											</TouchableOpacity>
										))}
										<View
											style={{
												marginTop: 24,
												marginBottom: 16,
												flexDirection: 'row',
												justifyContent: 'space-between',
												alignItems: 'center'
											}}
										>
											<Text
												style={{
													fontSize: 20,
													fontWeight: '500',
												}}
											>Latest client bookings</Text>
                      {!isMobile && (
                        <TouchableOpacity
                          onPress={() => navigation.navigate('BookingsScreen')}
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            borderWidth: 1,
                            borderColor: '#E4E1E0',
                            paddingHorizontal: 16,
                            paddingVertical: 8,
                            borderRadius: 6
                          }}
                        >
                          <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M3.333 4.667a.667.667 0 0 0-.666.667v7.333a.667.667 0 0 0 .666.667h7.334a.667.667 0 0 0 .666-.667v-4a.667.667 0 0 1 1.334 0v4a2 2 0 0 1-2 2H3.333a2 2 0 0 1-2-2V5.334a2 2 0 0 1 2-2h4a.667.667 0 0 1 0 1.333h-4ZM9.333 2c0-.368.299-.666.667-.666h4c.368 0 .667.298.667.667v4a.667.667 0 0 1-1.334 0V2.667H10a.667.667 0 0 1-.667-.666Z"
                              fill="#6D6E6F"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M14.471 1.53c.26.26.26.682 0 .942L7.138 9.805a.667.667 0 0 1-.943-.942l7.334-7.334c.26-.26.682-.26.942 0Z"
                              fill="#6D6E6F"
                            />
                          </svg>
                          <Text
                            style={{
                              fontWeight: '600',
                              marginLeft: 8
                            }}
                          >See all bookings</Text>
                        </TouchableOpacity>
                      )}
										</View>
										{bookings.length ? (
											<View
												style={{
													backgroundColor: 'white',
													borderRadius: 6,
													paddingVertical: 24,
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Text>Your studio does not have any bookings yet.</Text>
											</View>
										) : (
											<MobileScrollView>
												<View
													style={{
														width: '100%',
														backgroundColor: 'white',
														shadowColor: "#000",
														shadowOffset: {
															width: 0,
															height: 2,
														},
														shadowOpacity: 0.05,
														shadowRadius: 3.84,
														elevation: 5,
														borderRadius: 8,
														overflow: 'hidden'
													}}
												>

													<View
														style={{
															borderBottomWidth: 2,
															borderColor: '#096730',
															alignSelf: 'flex-start',
															paddingHorizontal: 16
														}}
													>
														<Text
															style={{
																fontWeight: '600',
																fontSize: 16,
																color: '#096730',
																lineHeight: 40
															}}
														>Latest client bookings</Text>
													</View>
													<table
														style={{
															backgroundColor: 'white',
															width: '100%',
															borderCollapse: 'collapse'
														}}
													>
														<tr
															style={{
																backgroundColor: '#F8F8F8',
																height: 48
															}}
														>
															<th></th>
															<th
																style={{
																	textAlign: 'left',
																	paddingLeft: 16,
																	paddingRight: 16
																}}
															>
																<Text
																	style={styles.tableHeader}
																>Name</Text>
															</th>
															<th
																style={{
																	textAlign: 'left',
																	paddingLeft: 16,
																	paddingRight: 16
																}}
															>
																<Text
																	style={styles.tableHeader}
																>User since</Text>
															</th>
															<th
																style={{
																	textAlign: 'left',
																	paddingLeft: 16,
																	paddingRight: 16
																}}
															>
																<Text
																	style={styles.tableHeader}
																>Last booking</Text>
															</th>
															<th
																style={{
																	textAlign: 'left',
																	paddingLeft: 16,
																	paddingRight: 16
																}}
															>
																<Text
																	style={styles.tableHeader}
																>Status</Text>
															</th>
														</tr>
														{bookings.map((item, index) => (
															(item.user && item.room) && (
																<tr
																	key={index}
																	style={{
																		height: 64,
																	}}
																>
																	<td
																		style={{
																			borderBottom: '1px solid',
																			borderBottomColor: '#E4E1E0',
																			paddingLeft: 16,
																			paddingRight: 16
																		}}
																	>
																		<Image
																			style={{
																				borderRadius: 16,
																				marginRight: 16,
																				width: 32,
																				height: 32,
																			}}
																			source={{
																				uri: item.user.artwork_url
																			}}
																		/>
																	</td>
																	<td
																		style={{
																			borderBottom: '1px solid',
																			borderBottomColor: '#E4E1E0',
																			paddingLeft: 16,
																			paddingRight: 16
																		}}
																	>
																		<View>
																			<Text>{item.user.name}</Text>
																			<Text
																				style={{
																					color: '#909090',
																					marginTop: 4,
																					fontWeight: '600'
																				}}
																			>{item.user.email}</Text>
																		</View>
																	</td>
																	<td
																		style={{
																			borderBottom: '1px solid',
																			borderBottomColor: '#E4E1E0',
																			paddingLeft: 16,
																			paddingRight: 16
																		}}
																	>
																		<Text>{moment.utc(item.user.created_at).local().format("MMM D, YYYY")}</Text>
																	</td>
																	<td
																		style={{
																			borderBottom: '1px solid',
																			borderBottomColor: '#E4E1E0',
																			paddingLeft: 16,
																			paddingRight: 16
																		}}
																	>
																		<Text>{item.room.name}</Text>
																	</td>
																	<td
																		style={{
																			borderBottom: '1px solid',
																			borderBottomColor: '#E4E1E0',
																			paddingLeft: 16,
																			paddingRight: 16
																		}}
																	>
																		<View
																			style={{
																				width: 'fit-content',
																				alignSelf: 'flex-start',
																				backgroundColor: '#EDFDF4',
																				height: 26,
																				justifyContent: 'center',
																				paddingHorizontal: 16,
																				borderRadius: 13
																			}}
																		>
																			<Text
																				style={{
																					fontWeight: '600',
																					color: '#096730'
																				}}
																			>Finished</Text>
																		</View>
																	</td>
																</tr>
															)
														))}
													</table>
												</View>

											</MobileScrollView>
										)}
									</View>
									<View
										style={{
                      width: isMobile ? '100%': '30%',
                      marginTop: isMobile ? 40: 0
										}}
									>
										<View
											style={{
												flexDirection: 'row',
												justifyContent: 'space-between',
												alignItems: 'center',
												zIndex: 1
											}}
										>
											<Text
												style={{
													fontSize: 20,
													fontWeight: '500',
												}}
											>Bookings</Text>
											{currentRoom && (
												<View
													style={{
														display: 'inline-block'
													}}
												>
													<TouchableOpacity
														onPress={() => {
															setMenuDisplay(!menuDisplay)
														}}
														style={{
															backgroundColor: 'white',
															padding: 16,
															borderRadius: 8,
															shadowColor: "#000",
															shadowOffset: {
																width: 0,
																height: 2,
															},
															shadowOpacity: 0.05,
															shadowRadius: 3.84,
															elevation: 5,
															flexDirection: 'row',
															justifyContent: 'space-between',
															width: 190
														}}
													>
														<View>
															<Text
																style={{
																	fontWeight: '600',
																	color: '#096730'
																}}
															>Room:</Text>
															<Text
																style={{
																	fontWeight: '600',
																	marginTop: 4
																}}
															>{currentRoom.name}</Text>
														</View>
														<svg
															width={16} height={16}
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 492 492"
															style={{
																enableBackground: "new 0 0 492 492",
															}}
															xmlSpace="preserve"
														>
															<path d="M246.1 293.4 62 109.3c-5.1-5.1-11.8-7.9-19-7.9-7.2 0-14 2.8-19 7.9L7.9 125.5c-5.1 5.1-7.9 11.8-7.9 19 0 7.2 2.8 14 7.9 19L227 382.7c5.1 5.1 11.9 7.9 19.1 7.8 7.2 0 14-2.8 19.1-7.8l218.9-218.9c5.1-5.1 7.9-11.8 7.9-19 0-7.2-2.8-14-7.9-19L468 109.5c-10.5-10.5-27.6-10.5-38.1 0L246.1 293.4z" />
														</svg>
													</TouchableOpacity>
													<View
														style={{
															display: menuDisplay ? 'block' : 'none',
															borderRadius: 8,
															position: 'absolute',
															right: 0,
															width: 240,
															backgroundColor: 'white',
															shadowColor: "#000",
															shadowOffset: {
																width: 0,
																height: 2,
															},
															shadowOpacity: 0.25,
															shadowRadius: 3.84,
															elevation: 5,
															paddingHorizontal: 16
														}}
													>
														{rooms.map((item, index) => (
															<TouchableOpacity
																key={index}
																onPress={() => {
																	setMenuDisplay(false)
																	setCurrentRoom(item);
																	setTimeout(() => {
																		getRoomEvents(item.id);
																	}, 1000);
																}}
																style={{
																	flexDirection: 'row',
																	alignItems: 'center',
																	marginVertical: 16
																}}
															>
																<View
																	style={{
																		width: 64,
																		aspectRatio: 1.777,
																		borderRadius: 4,
																		overflow: 'hidden'
																	}}
																>
																	<Image
																		style={{
																			flex: 1
																		}}
																		source={{
																			uri: item.artwork_url,
																		}}
																	/>
																</View>
																<Text
																	style={{
																		marginLeft: 12,
																		fontWeight: '600'
																	}}
																>{item.name}</Text>
															</TouchableOpacity>
														))}
													</View>
												</View>
											)}
										</View>
										<View
											style={{
												marginBottom: 32,
												marginTop: 32,
												backgroundColor: 'white',
												padding: 16,
												borderRadius: 8,
												shadowColor: "#000",
												shadowOffset: {
													width: 0,
													height: 2,
												},
												shadowOpacity: 0.05,
												shadowRadius: 3.84,
												elevation: 5,
											}}
										>
											<Calendar
												onChange={(e)=> {
													setCurrentDate(e);
													setTimeout(() => {
														getRoomEvents(currentRoom.id);
													}, 200);
												}}
												value={currentDate}
											/>
										</View>
										<ScrollView
											style={{
												maxHeight: 780,
												borderWidth: 1,
												borderColor: '#dddddd',
												borderRadius: 8
											}}
										>
											<BigCalendar
												key={currentDate}
												defaultDate={currentDate}
												events={events}
												resizable
												localizer={localizer}
												defaultView="day"
												components={{
													week: {
														header: ({ date, localizer }) => (
															<View
																style={{
																	paddingTop: 8,
																	paddingLeft: 8,
																	paddingBottom: 16
																}}
															>
																<Text
																	style={{
																		textTransform: 'uppercase',
																		fontWeight: '600',
																		color: '#71717A',
																		alignSelf: 'flex-start'
																	}}
																>{localizer.format(date, 'ddd')}</Text>
																<Text
																	style={{
																		marginTop: 8,
																		fontSize: 24,
																		fontWeight: '500',
																		alignSelf: 'flex-start'
																	}}
																>{localizer.format(date, 'D')}</Text>
															</View>
														)
													}
												}}
											/>
										</ScrollView>
										{/*
								<View
									style={{

									}}
								>
									{Array.from({length: 12}, (_, i) => i + 8).map((key, index) => (
										<View
											key={index}
											style={{
												flexDirection: 'row',
												alignItems: 'center',
												width: '100%',
												height: 64,
											}}
										>
											<Text
												style={{
													color: '#5F5C6B'
												}}
											>{`${key}`}:00</Text>
											<View
												style={{
													marginLeft: 16,
													flex: 1,
													height: 1,
													backgroundColor: '#E1E0E7'
												}}
											/>
										</View>
									))}
									{[
										{startHour: 15.5, endHour: 16.5},
										{startHour: 12.5, endHour: 14.5},
										{startHour: 10.5, endHour: 11.5}
									].map((item, index) => (
										<View
											key={index}
											style={{
												backgroundColor: '#3DBA71',
												padding: 16,
												borderRadius: 8,
												height: (item.endHour - item.startHour) * 64,
												position: 'absolute',
												left: 64,
												top: (item.startHour-8) * 64 + 32
											}}
										>
											<Text
												style={{
													color: 'white',
													fontWeight: '600'
												}}
											>Guilherme Dourado</Text>
											<Text
												style={{
													marginTop: 8,
													color: 'white',
													opacity: .8
												}}
											>{item.startHour} am - {item.endHour} am</Text>
										</View>
									))}
								</View>
								*/}
									</View>
								</View>
							</>
						)}
					</>
				)}

			</ScrollView>
		</View>
	);
}

const styles = StyleSheet.create({
	tableHeader: {
		fontWeight: '600',
		textAlign: 'left',
		alignSelf: 'flex-start'
	},
	tableCell: {
		borderBottom: '1px solid',
		borderBottomColor: 'gray',
		paddingLeft: 16,
		paddingRight: 16
	},
});

DashboardScreen.propTypes = {
	navigation: PropTypes.object,
};

export default connect(({auth}) => ({auth}))(DashboardScreen);