import React, {useEffect, useState} from "react";
import {
	Text,
	View,
	TextInput, ImageBackground, ActivityIndicator
} from "react-native";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import API from "../../helpers/Axios";
import {TouchableOpacity} from "react-native-web";
import FieldSetTextInput from "../../components/FieldSetTextInput";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {store} from "../../store/configureStore";

const SigninScreen = ({navigation, auth}) => {
	const [isSubmitting, setIsSubmitting] = useState( false);

	const [email, setEmail] = useState({
		value: '',
		error: false,
		errorString: null
	});

	const [password, setPassword] = useState({
		value: '',
		error: false,
		errorString: null
	});

	const onSubmit = (code) => {
		if(isSubmitting) {
			return
		}
		let data = {
			email: email.value,
			password: password.value,
		}

		API.post('auth/login-by-email', data)
			.then(res => {
				AsyncStorage.setItem('access_token', res.data.access_token);
				store.dispatch({type: 'TOGGLE_AUTH', user: res.data.user});
				store.dispatch({type: 'UPDATE_ACCESS_TOKEN', accessToken: res.data.access_token});
				navigation.navigate('DashboardStack');
			}).catch (error => {
			setIsSubmitting(false);
			setEmail({
				value: email.value,
				error: error.response.data.errors['email'] !== undefined,
				errorString: error.response.data.errors['email'] !== undefined ? error.response.data.errors['email'][0] : 'Error'
			});
			setPassword({
				value: password.value,
				error: error.response.data.errors['password'] !== undefined,
				errorString: error.response.data.errors['password'] !== undefined ? error.response.data.errors['password'][0] : 'Error'
			});
			if(error.response.data.errors['email'] === undefined && error.response.data.errors['password'] === undefined) {
				setEmail({
					value: email.value,
					error: true,
					errorString: 'These credentials do not match our records.'
				});
				setPassword({
					value: password.value,
					error: true,
					errorString: ''
				});
			}
		});
	}

	const onFormSubmit = () => {
		setIsSubmitting(true);
		onSubmit();
	}

	return (
		<ImageBackground
			source={{uri: '/assets/splash/1.jpeg'}}
			style={{
				position: 'absolute',
				flex: 1,
				width: '100%',
				height: '100vh',
				margin: 0,
				padding: 0,
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: '#1D1F1E',
			}}
		>
			<View
				style={{
					position: 'absolute',
					backgroundColor: 'rgba(29, 31, 30, 0.4)',
					top: 0,
					right: 0,
					left: 0,
					bottom: 0,
					zIndex: -1
				}}
			/>
			<Text
				style={{
					fontSize: 20,
					fontWeight: '700',
					marginBottom: 24,
					color: 'white',
          width: '90%',
          maxWidth: 480,
				}}
			>New on Stugo? <Text onPress={() => navigation.navigate('AccountTypeScreen')} style={{color: '#3DBA71'}}>Create my Stugo account</Text></Text>
			<View
				style={{
					padding: 48,
					backgroundColor: 'white',
					borderRadius: 8,
					width: '90%',
          maxWidth: 480,
				}}
			>
				<Text
					style={{
						color: '#3DBA71',
						fontSize: 34,
						marginBottom: 24,
						fontWeight: '600',
					}}
				>Welcome back!</Text>
				<Text
					style={{
						fontSize: 20,
						marginBottom: 24,
						color: '#6D6E6F'
					}}
				>Log in to your Stugo Account.</Text>
					<View
						style={{
							marginTop: 16
						}}
					>
						<View>
							<FieldSetTextInput
								placeholder={'Email'}
								value={email.value}
								error={email.error}
								errorString={email.errorString}
								onChangeText={text => {
									setEmail({
										value: text,
										error: false,
										errorString: email.errorString
									});
								}}
								blurOnSubmit={true}
								onSubmitEditing={onFormSubmit}
							/>
						</View>
						<View
							style={{
								marginTop: 40
							}}
						>
							<FieldSetTextInput
								placeholder={'Password'}
								value={password.value}
								error={password.error}
								errorString={password.errorString}
								onChangeText={text => setPassword({
									value: text,
									error: false,
									errorString: password.errorString
								})}
								secureTextEntry={true}
								blurOnSubmit={true}
								onSubmitEditing={onFormSubmit}
							/>
						</View>
					</View>
				<Text
					style={{
						marginTop: 24
					}}
				>Forgot your password? <Text onPress={() => navigation.navigate('ForgotPasswordScreen')} style={{color: '#3DBA71', fontWeight: '600'}}>Recover password</Text></Text>
				<TouchableOpacity
					onPress={() => {
						setIsSubmitting(true);
						onSubmit();
					}}
					style={{
						backgroundColor: '#3DBA71',
						alignItems: 'center',
						justifyContent: 'center',
						borderRadius: 4,
						height: 48,
						marginTop: 16
					}}
				>
					{isSubmitting && <ActivityIndicator color={'white'}/>}
					{!isSubmitting &&
						<Text
							style={{
								fontSize: 16,
								fontWeight: '600',
								color: 'white',
								textAlign: 'center'
							}}
						>Login</Text>
					}
				</TouchableOpacity>
			</View>
		</ImageBackground>
	);

}

SigninScreen.propTypes = {
	navigation: PropTypes.object,
};

export default connect(({auth}) => ({auth}))(SigninScreen);