import React, {useEffect, useState} from "react";
import {
	StatusBar,
	Animated,
	Easing,
	StyleSheet,
	View,
	KeyboardAvoidingView,
	TouchableOpacity,
	Text, Image
} from "react-native";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {useNavigation} from "@react-navigation/native";
import {store} from "../store/configureStore";
import {Popover} from "react-tiny-popover";
import {Views} from "react-big-calendar";
import API from "../helpers/Axios";
import Responsive from "../helpers/Reponsive";

const HeaderContainer = () => {
  const { isMobile } = Responsive();
  const navigation = useNavigation();
	const [menuDisplay, setMenuDisplay] = useState( false);
	const [isPopoverOpen, setIsPopoverOpen] = useState( false);
	const [unreadCount, setUnreadCount] = useState( 0);
	const [notifications, setNotifications] = useState( []);
	const user = store.getState().auth.user;

	const getNotif = () => {
		API.post('owner/get-notifications')
			.then(res => {
				setNotifications(res.data.notifications);
				setUnreadCount(res.data.unread_count);
			}).catch (error => {
		});
	}

	useEffect(() => {
		setInterval(() => {
			getNotif();
		}, 30000);
		getNotif();
	}, []);

	return (
		<View
			style={{
				height: 80,
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
				backgroundColor: '#1E1F21',
				width: '100%',
				paddingHorizontal: isMobile ? 24 : 40,
				zIndex: 1
			}}
		>
			<TouchableOpacity
				style={{
					flexDirection: 'row',
					alignItems: 'center'
				}}
				onPress={() => navigation.navigate('DashboardScreen')}
			>
				<svg width={45} height={44} fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M36.748.5H8.252A7.251 7.251 0 0 0 1 7.751v28.497A7.251 7.251 0 0 0 8.251 43.5H36.75A7.251 7.251 0 0 0 44 36.248V7.752A7.251 7.251 0 0 0 36.748.5Z"
						fill="#212529"
					/>
					<path
						d="M36.749.564H8.252A7.188 7.188 0 0 0 1.064 7.75v28.497a7.188 7.188 0 0 0 7.188 7.188h28.497a7.188 7.188 0 0 0 7.187-7.188V7.752A7.188 7.188 0 0 0 36.75.564Z"
						stroke="#12B656"
						strokeWidth={0.544}
					/>
					<path
						d="M20.978 31.63c2.291-.555 4.91-2.206 5.481-3.792-.13.287-.377.601-.846.914-.758.506-1.716.762-2.849.762a7.025 7.025 0 0 1-2.681-.481 7.86 7.86 0 0 1-2.317-1.537 2.118 2.118 0 0 0-1.456-.557 2.205 2.205 0 0 0-1.616.7c-.421.418-.66.986-.665 1.58.004.598.234 1.172.643 1.608 1.364.63 3.757 1.42 6.306.802ZM28.605 11.775c-.943-.366-3.29-.636-5.707.604-2.188 1.124-3.818 3.174-3.569 3.618.185-.29.413-.547.679-.764.675-.468 1.633-.705 2.847-.705a6.767 6.767 0 0 1 2.441.502c.844.329 1.504.726 1.961 1.18l.034.033c.351.304.802.467 1.267.457a2.192 2.192 0 0 0 1.607-.686c.416-.414.65-.977.652-1.565 0-.715-.33-1.367-.983-1.94-.074-.07-.153-.14-.23-.207a5.066 5.066 0 0 0-.816-.45s-.094-.043-.183-.077Z"
						fill="#2DC02E"
						stroke="#fff"
						strokeWidth={0.054}
					/>
					<path
						d="M30.247 23.275a6.406 6.406 0 0 0-2.715-2.28c-1.073-.498-2.462-.935-4.118-1.296-1.511-.35-2.648-.77-3.379-1.251-.583-.384-.855-.905-.855-1.64a1.74 1.74 0 0 1 .286-.992c.513-1.14 1.287-2.313 3.466-3.437 2.405-1.24 4.742-.97 5.682-.604a5.037 5.037 0 0 1 .994.527 8.76 8.76 0 0 0-2.808-1.605 11.418 11.418 0 0 0-3.912-.67 10.382 10.382 0 0 0-4.177.814 7.001 7.001 0 0 0-3.014 2.41 6.309 6.309 0 0 0-1.11 3.68c0 2 .675 3.598 2.007 4.75 1.25 1.081 3.031 1.864 5.28 2.322 1.69.37 2.947.825 3.736 1.354a2.08 2.08 0 0 1 .986 1.815c.003.23-.044.457-.138.666-.57 1.586-3.19 3.237-5.48 3.792-2.55.617-4.942-.174-6.306-.803.09.1.185.194.287.282a11.06 11.06 0 0 0 3.582 2.219c1.244.455 2.686.686 4.285.686a10.124 10.124 0 0 0 4.152-.847 7.484 7.484 0 0 0 3.078-2.443 6.153 6.153 0 0 0 1.164-3.676c0-1.522-.328-2.792-.973-3.773Z"
						fill="#fff"
					/>
				</svg>
        {!isMobile && (
          <Text
            style={{
              marginLeft: 16,
              color: 'white',
              fontSize: 24,
              fontWeight: '600'
            }}
          >Stugo</Text>
        )}
			</TouchableOpacity>
			<View
				style={{
					flexDirection: 'row',
					alignItems: 'center',
					paddingVertical: 16
				}}
			>
				<View>
					<Popover
						isOpen={menuDisplay}
						positions={['bottom', 'right']}
						align={'end'}
						containerStyle={{
							zIndex: 5,
						}}
						padding={10} // adjust padding here!
						reposition={false} // prevents automatic readjustment of content position that keeps your popover content within its parent's bounds
						onClickOutside={() => setMenuDisplay(false)} // handle click events outside of the popover/target here!
						content={({ position, nudgedLeft, nudgedTop }) => ( // you can also provide a render function that injects some useful stuff!
							<View
								style={{
									width: 289,
									backgroundColor: 'white',
									padding: 16,
									shadowColor: "#000",
									shadowOffset: {
										width: 0,
										height: 2,
									},
									shadowOpacity: 0.05,
									shadowRadius: 3.84,
									elevation: 5,
									borderRadius: 4
								}}
							>
								<View
									style={{
										flexDirection: 'row',
										alignItems: 'center',
										marginBottom: 16
									}}
								>
									<View
										style={{
											borderWidth: 1,
											borderColor: '#DFE0EB',
											marginRight: 16,
											width: 40,
											height: 40,
											overflow: 'hidden',
											borderRadius: 20,
											justifyContent: 'center',
											alignItems: 'center'
										}}
									>
										<Image
											source={{
												uri: user.artwork_url
											}}
											resizeMode={'cover'}
											style={{
												width: 38,
												height: 38,
												borderRadius: 19
											}}
										/>
									</View>
									<View>
										<Text
											style={{
												fontWeight: '600',
												fontSize: 16
											}}
										>{user.name}</Text>
										<Text
											style={{
												marginTop: 4,
												fontWeight: '600',
												color: '#909090'
											}}
										>{user.email}</Text>
									</View>
								</View>
								<TouchableOpacity
									onPress={() => {
										setMenuDisplay(false);
										navigation.navigate('SettingsScreen');
									}}
									style={{
										flexDirection: 'row',
										alignItems: 'center',
										height: 24,
										marginVertical: 16
									}}
								>
									<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											clipRule="evenodd"
											d="M12.8 2.996a1 1 0 0 1 .942.662l.508 1.416c.08.223.237.41.442.528l1.508.87a1 1 0 0 0 .677.119l1.48-.267a1 1 0 0 1 1.046.484l.8 1.38a1 1 0 0 1-.1 1.147l-.97 1.148a1 1 0 0 0-.238.647v1.74a1 1 0 0 0 .237.647l.971 1.148a1 1 0 0 1 .1 1.147l-.8 1.38a1 1 0 0 1-1.044.484l-1.48-.267a1 1 0 0 0-.678.118l-1.508.87a1.007 1.007 0 0 0-.442.529l-.508 1.415a1 1 0 0 1-.943.663h-1.6a1 1 0 0 1-.942-.663l-.508-1.415a1.01 1.01 0 0 0-.441-.528l-1.509-.87a1 1 0 0 0-.677-.119l-1.48.267a1 1 0 0 1-1.046-.484l-.8-1.38a1 1 0 0 1 .1-1.147l.971-1.148a1 1 0 0 0 .237-.647v-1.74a1 1 0 0 0-.237-.647l-.961-1.148a1 1 0 0 1-.1-1.147l.8-1.38a1 1 0 0 1 1.045-.484l1.48.267a1 1 0 0 0 .677-.118l1.509-.87a1.01 1.01 0 0 0 .441-.529l.508-1.416a1 1 0 0 1 .933-.662h1.6Z"
											stroke="#323232"
											strokeWidth={1.5}
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<circle
											cx={12}
											cy={12}
											r={2.751}
											stroke="#323232"
											strokeWidth={1.5}
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
									<Text
										style={{
											marginLeft: 32,
											fontWeight: '600',
											fontSize: 16
										}}
									>Settings</Text>
								</TouchableOpacity>
								<TouchableOpacity
									onPress={() => {
										setMenuDisplay(false);
										navigation.navigate('HelpScreen');
									}}
									style={{
										flexDirection: 'row',
										alignItems: 'center',
										height: 24,
										marginVertical: 16
									}}
								>
									<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M9.685 9.685A2.181 2.181 0 0 1 11.9 7.998 2.137 2.137 0 0 1 14.151 10c0 1.505-2.15 2.001-2.15 3.001M12.125 15.752a.125.125 0 1 1-.25 0 .125.125 0 0 1 .25 0"
											stroke="#323232"
											strokeWidth={1.5}
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											clipRule="evenodd"
											d="M4.629 17.16a9.018 9.018 0 1 1 2.21 2.212l-2.842.631.632-2.843Z"
											stroke="#323232"
											strokeWidth={1.5}
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
									<Text
										style={{
											marginLeft: 32,
											fontWeight: '600',
											fontSize: 16
										}}
									>Help Center</Text>
								</TouchableOpacity>
								<TouchableOpacity
									onPress={() => {
										store.dispatch({type: 'TOGGLE_AUTH', user: {}});
                    store.dispatch({type: 'EXTRA_UPDATE', extra: {}});
									}}
									style={{
										flexDirection: 'row',
										alignItems: 'center',
										height: 24,
										marginTop: 16
									}}
								>
									<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											clipRule="evenodd"
											d="m14.163 21 4.444-.988A1.777 1.777 0 0 0 20 18.277V5.766c0-.833-.579-1.555-1.392-1.735l-4.444-.988A1.778 1.778 0 0 0 12 4.779v14.486A1.777 1.777 0 0 0 14.163 21v0Z"
											stroke="#323232"
											strokeWidth={1.5}
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M15 10.98v2M4 16v2.042a2 2 0 0 0 2 2h3M4 8V6a2 2 0 0 1 2-2h3M4 12h5M7 14l2-2-2-2"
											stroke="#323232"
											strokeWidth={1.5}
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
									<Text
										style={{
											marginLeft: 32,
											fontWeight: '600',
											fontSize: 16
										}}
									>Log out</Text>
								</TouchableOpacity>
							</View>
						)}
					>
						<TouchableOpacity
							onPress={() => setMenuDisplay(!menuDisplay)}
							style={{
								flexDirection: 'row',
								alignItems: 'center',
							}}
						>
              {!isMobile && (
                <Text
                  style={{
                    color: 'white'
                  }}
                >Hello, {user.name}</Text>
              )}
							<Image
								source={{
									uri: user.artwork_url
								}}
								resizeMode={'cover'}
								style={{
									width: 44,
									height: 44,
									borderRadius: 22,
									marginLeft: 16,
									borderWidth: 1,
									borderColor: '#12B656'
								}}
							/>
						</TouchableOpacity>
					</Popover>
				</View>
				<View>
					<Popover
						isOpen={isPopoverOpen}
						positions={['bottom', 'right']}
						align={'end'}
						containerStyle={{
							zIndex: 5,
						}}
						padding={10} // adjust padding here!
						reposition={false} // prevents automatic readjustment of content position that keeps your popover content within its parent's bounds
						onClickOutside={() => setIsPopoverOpen(false)} // handle click events outside of the popover/target here!
						content={({ position, nudgedLeft, nudgedTop }) => ( // you can also provide a render function that injects some useful stuff!
							<View
								style={{
									marginTop: 8,
									backgroundColor: 'white',
									width: 264,
									paddingHorizontal: 16,
									shadowColor: "#000",
									shadowOffset: {
										width: 0,
										height: 2,
									},
									shadowOpacity: 0.15,
									shadowRadius: 3.84,
									elevation: 5,
									borderRadius: 4
								}}
							>
								{notifications.map((item, index) => {
									if(item.content && item.type === 'BookingPlaced') {
										return (
											<TouchableOpacity
												onPress={() => {
													setIsPopoverOpen(false);
													navigation.navigate('BookingsScreen', {studioId: item.content.studio_id, roomId: item.content.room_id, bookingId: item.content.id})
												}}
												key={index}
												style={{
													paddingVertical: 16,
													borderBottom: 1,
													borderColor: '#CFCFCF',
													flexDirection: 'row',
													alignItems: 'center',
													justifyContent: 'space-between'
												}}
											>
												<View
													style={{
														flex: 1
													}}
												>
													<Text
														style={{
															fontSize: 12,
															fontWeight: 'bold',
															marginBottom: 8
														}}
													>New Booking request!</Text>
													<Text
														style={{
															color: '#6D6E6F',
															lineHeight: 20
														}}
													>Your room, {item.content.room.name}, has been reserved!</Text>
												</View>
												<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														d="M5.003 2 11 8.018 5 14"
														stroke="#323232"
														strokeWidth={1.5}
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</TouchableOpacity>
										)
									}
									if(item.content && item.type === 'BookingCanceled') {
										return (
											<TouchableOpacity
												onPress={() => {
													setIsPopoverOpen(false);
													navigation.navigate('BookingsScreen')
												}}
												key={index}
												style={{
													paddingVertical: 16,
													borderBottom: 1,
													borderColor: '#CFCFCF',
													flexDirection: 'row',
													alignItems: 'center',
													justifyContent: 'space-between'
												}}
											>
												<View
													style={{
														flex: 1
													}}
												>
													<Text
														style={{
															fontSize: 12,
															fontWeight: 'bold',
															marginBottom: 8
														}}
													>Booking canceled!</Text>
													<Text
														style={{
															color: '#6D6E6F',
															lineHeight: 20
														}}
													>Your room, {item.content.room.name}, has been canceled by <strong>{item.content.user.name}</strong>!</Text>
												</View>
												<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
													<path
														d="M5.003 2 11 8.018 5 14"
														stroke="#323232"
														strokeWidth={1.5}
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</TouchableOpacity>
										)
									}
								})}
							</View>
						)}
					>
						<TouchableOpacity
							onPress={() => {
								setIsPopoverOpen(!isPopoverOpen);
								API.post('auth/user/notifications/seen')
									.then(res => {
										setUnreadCount(0);
									}).catch (error => {
									console.log(error);
								});
							}}
						>
							<Image
								source={require('../assets/icons/common/bell.png')}
								resizeMode={'cover'}
								style={{
									width: 24,
									height: 24,
									marginLeft: 40,
								}}
							/>
						</TouchableOpacity>
					</Popover>
					{unreadCount > 0 && (
						<View
							style={{
								position: 'absolute',
								top: -8,
								right: -16,
								backgroundColor: '#F24C4C',
								height: 16,
								borderRadius: 8,
								paddingHorizontal: 8,
								justifyContent: 'center'
							}}
						>
							<Text
								style={{
									color: 'white',
									fontSize: 11
								}}
							>{unreadCount}</Text>
						</View>
					)}
				</View>
			</View>
		</View>
	);
}


HeaderContainer.propTypes = {
	navigation: PropTypes.object,
};

export default connect(({auth}) => ({auth}))(HeaderContainer);
