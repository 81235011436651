import React, {useCallback, useState} from "react";
import {
	Text,
	View,
	TextInput, ImageBackground, ActivityIndicator
} from "react-native";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import API from "../../helpers/Axios";
import {TouchableOpacity} from "react-native-web";
import FieldSetTextInput from "../../components/FieldSetTextInput";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {countriesArray} from "../../helpers/CountryCode";
import {store} from "../../store/configureStore";
import {GoogleReCaptcha} from "react-google-recaptcha-v3";

const SignupScreen = ({navigation}) => {
	const [token, setToken] = useState();
	const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
	const [stepIndex, setStepIndex] = useState(0)
	const [countryCode, setCountryCode] = useState('US')
	const [isSubmitting, setIsSubmitting] = useState( false);
	const [verificationCode, setVerificationCode] = useState( '');
	const [errorMessage, setErrorMessage] = useState('');
	const [botsDetected, setBotDetected] = useState(false);

	const onVerify = useCallback((token) => {
		setToken(token);
	});

	const [firstName, setFirstName] = useState({
		value: '',
		error: false,
		errorString: null
	});

	const [lastName, setLastName] = useState({
		value: '',
		error: false,
		errorString: null
	});

	const [email, setEmail] = useState({
		value: '',
		error: false,
		errorString: null
	});

	const [phoneNumber, setPhoneNumber] = useState({
		value: '',
		error: false,
		errorString: null
	});

	const onSubmit = () => {
		if(isSubmitting) {
			return
		}
		const data = new FormData();
		data.append('phoneNumber', phoneNumber.value);
		data.append('callingCode', countriesArray.find(x => x.code === countryCode).dial_code);
		data.append('countryCode', countryCode);
		data.append('firstName', firstName.value);
		data.append('lastName', lastName.value);
		data.append('email', email.value);
		data.append('token', token);

		API.post('auth/signup-verify', data)
			.then(res => {
				setIsSubmitting(false);
				setStepIndex(1);
			}).catch (error => {
			if(error.response.status === 400) {
				setBotDetected(true);
			} else {
				setBotDetected(false);
				setFirstName({
					value: firstName.value,
					error: error.response.data.errors['firstName'] !== undefined,
					errorString: error.response.data.errors['firstName'] !== undefined ? error.response.data.errors['firstName'][0] : 'Error'
				});
				setLastName({
					value: lastName.value,
					error: error.response.data.errors['lastName'] !== undefined,
					errorString: error.response.data.errors['lastName'] !== undefined ? error.response.data.errors['lastName'][0] : 'Error'
				});
				setEmail({
					value: email.value,
					error: error.response.data.errors['email'] !== undefined,
					errorString: error.response.data.errors['email'] !== undefined ? error.response.data.errors['email'][0] : 'Error'
				});
				setPhoneNumber({
					value: phoneNumber.value,
					error: error.response.data.errors['phone_number'] !== undefined,
					errorString: error.response.data.errors['phone_number'] !== undefined ? error.response.data.errors['phone_number'][0] : 'Error'
				});
			}
			setIsSubmitting(false)
		});
	}
	
	const onVerifyEmailSubmit = () => {
		if(isSubmitting) {
			return
		}
		const data = new FormData();
		//data.append('phoneNumber', phoneNumber.value);
		//data.append('callingCode', countriesArray.find(x => x.code === countryCode).dial_code);
		//data.append('countryCode', countryCode);
		data.append('verificationCode', verificationCode);
		data.append('firstName',firstName.value);
		data.append('lastName', lastName.value);
		data.append('email', email.value);

		API.post('auth/signup-verify-email', data)
			.then(res => {
				setIsSubmitting(false)
				AsyncStorage.setItem('access_token', res.data.access_token);
				API.post('auth/user')
					.then(res => {
						store.dispatch({type: 'UPDATE_ACCESS_TOKEN', accessToken: res.data.access_token});
						navigation.navigate("PasswordScreen");
					})

			}).catch (error => {
			setErrorMessage(error.response.data.errors[Object.keys(error.response.data.errors)[0]][0])
			setIsSubmitting(false);
		});
	}

	return (
		<ImageBackground
			source={{uri: '/assets/splash/1.jpeg'}}
			style={{
				position: 'absolute',
				flex: 1,
				width: '100%',
				height: '100vh',
				margin: 0,
				padding: 0,
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: '#1D1F1E',
			}}
		>
			<View
				style={{
					position: 'absolute',
					backgroundColor: 'rgba(29, 31, 30, 0.4)',
					top: 0,
					right: 0,
					left: 0,
					bottom: 0
				}}
			/>
			<View
				style={{
          width: '90%',
          maxWidth: 480,
				}}
			>
				<View
					style={{
						marginBottom: 16,
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}
				>
					<TouchableOpacity
						onPress={() => {
              setStepIndex(0)
            }}
					>
						<Text
							style={{
								color: 'white'
							}}
						>Back</Text>
					</TouchableOpacity>
					<View>
						<Text
							style={{
								color: 'white',
								fontWeight: '600',
								fontSize: 12
							}}
						>Studio info</Text>
						<Text
							style={{
								color: '#3DBA71'
							}}
						>STEP 02/03</Text>
					</View>
				</View>
				{stepIndex === 0 && (
					<View
						style={{
							padding: 48,
							backgroundColor: 'white',
							borderRadius: 8,
						}}
					>
						<Text
							style={{
								color: '#3DBA71',
								fontSize: 34,
								marginBottom: 24,
								fontWeight: '600',
							}}
						>Let’s get to know you</Text>
						<Text
							style={{
								fontSize: 20,
								marginBottom: 24,
								color: '#6D6E6F'
							}}
						>Enter your contact information to save your studio listing. This will save you some time later.</Text>
						<View>
							<View
								style={{
									flexDirection: 'row',
									justifyContent: 'space-between',
									flex: 1,
								}}
							>
								<View
									style={{
										marginRight: 12,
										flex: 1
									}}
								>
									<FieldSetTextInput
										placeholder={'First name'}
										value={firstName.value}
										error={firstName.error}
										errorString={firstName.errorString}
										onChangeText={text => {
											setFirstName({
												value: text,
												error: false,
												errorString: firstName.errorString
											});
										}}
									/>
								</View>
								<View
									style={{
										marginLeft: 12,
										flex: 1
									}}
								>
									<FieldSetTextInput
										placeholder={'Last name'}
										value={lastName.value}
										error={lastName.error}
										errorString={lastName.errorString}
										onChangeText={text => {
											setLastName({
												value: text,
												error: false,
												errorString: email.errorString
											});
										}}
									/>
								</View>
							</View>
							<View
								style={{
									marginTop: 40
								}}
							>
								<FieldSetTextInput
									name={'Email'}
									placeholder={'Email Address'}
									value={email.value}
									error={email.error}
									errorString={email.errorString}
									onChangeText={text => {
										setEmail({
											value: text,
											error: false,
											errorString: email.errorString
										});
									}}
								/>
							</View>
              {/*
              <View
                style={{
                  marginTop: 40
                }}
              >
                <FieldSetTextInputWithPhone
                  name={'Phone'}
                  placeholder={'Phone number'}
                  value={phoneNumber.value}
                  error={phoneNumber.error}
                  errorString={phoneNumber.errorString}
                  onChangeText={text => {
                    setPhoneNumber({
                      value: text.replace(/[^0-9]/g, ''),
                      error: false,
                      errorString: phoneNumber.errorString
                    });
                  }}
                  onChangeCountryCode={text => {
                    setCountryCode(text);
                  }}
                />
              </View>
              */}
						</View>
						{botsDetected && (
							<Text
								style={{
									marginTop: 16,
									marginBottom: -24,
									fontWeight: '500',
									color: 'red',
									fontSize: 24
								}}
							>Are you a robot?</Text>
						)}
						<GoogleReCaptcha
							onVerify={onVerify}
							refreshReCaptcha={refreshReCaptcha}
						/>
						<TouchableOpacity
							onPress={() =>  {
								setIsSubmitting(true);
								onSubmit();
							}}
							style={{
								backgroundColor: '#3DBA71',
								alignItems: 'center',
								justifyContent: 'center',
								borderRadius: 4,
								height: 48,
								marginTop: 40
							}}
						>
							{isSubmitting && <ActivityIndicator color={'white'}/>}
							{!isSubmitting && <Text
								style={{
									color: 'white',
									fontSize: 16,
									fontWeight: '600'
								}}
							>Save & Continue</Text>}
						</TouchableOpacity>
						<View
							style={{
								flexDirection: 'row',
								marginTop: 24
							}}
						>
							<svg width={14} height={15} fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M9.917 5.458h.583a1.17 1.17 0 0 1 1.167 1.167v5.833a1.17 1.17 0 0 1-1.167 1.167h-7a1.17 1.17 0 0 1-1.167-1.167V6.625A1.17 1.17 0 0 1 3.5 5.458h.583V4.292a2.918 2.918 0 0 1 5.834 0v1.166ZM7 2.542c-.968 0-1.75.781-1.75 1.75v1.166h3.5V4.292c0-.969-.782-1.75-1.75-1.75Zm-3.5 9.916V6.625h7v5.833h-7Zm4.667-2.916A1.17 1.17 0 0 1 7 10.708a1.17 1.17 0 0 1-1.167-1.166A1.17 1.17 0 0 1 7 8.375a1.17 1.17 0 0 1 1.167 1.167Z"
									fill="#8692A6"
								/>
							</svg>
							<Text
								style={{
									marginLeft: 8,
									color: '#8692A6'
								}}
							>Your Info is safely secured</Text>
						</View>
					</View>
				)}
				{stepIndex === 1 && (
          <View
						style={{
							padding: 48,
							backgroundColor: 'white',
							borderRadius: 8,
						}}
					>
            {/*
              <Text
                  style={{
                    color: '#3DBA71',
                    fontSize: 34,
                    marginBottom: 24,
                    fontWeight: '600',
                  }}
                >Confirm your  Phone number</Text>
                <Text
                  style={{
                    fontSize: 20,
                    marginBottom: 4,
                    color: '#6D6E6F'
                  }}
                >Please, enter the code sent to:</Text>
                <Text
                  style={{
                    fontSize: 20,
                    marginBottom: 24,
                    color: '#3DBA71'
                  }}
                >+{countriesArray.find(x => x.code === countryCode).dial_code} {phoneNumber.value}</Text>
              */}
						<Text
							style={{
								color: '#3DBA71',
								fontSize: 34,
								marginBottom: 24,
								fontWeight: '600',
							}}
						>Confirm your email address</Text>
						<Text
							style={{
								fontSize: 20,
								marginBottom: 4,
								color: '#6D6E6F'
							}}
						>Please, enter the code sent to:</Text>
						<Text
							style={{
								fontSize: 20,
								marginBottom: 24,
								color: '#3DBA71'
							}}
						>{email.value}</Text>
						<View>
							<Text
								style={{
									marginBottom: 4,
									color: '#6D6E6F'
								}}
							>Confirmation code</Text>
							<View
								style={{
									borderWidth: 1,
									borderColor: '#8692A6',
									borderRadius: 6,
									height: 64,
									marginTop: 8
								}}
							>
								<TextInput
									placeholder={'00000'}
									maxLength={5}
									style={{
										flex: 1,
										outline: 0,
										marginHorizontal: 16,
										fontSize: 24,
										textAlign: 'center',
										letterSpacing: 3,
										fontWeight: '600',
										color: '#6D6E6F'
									}}
									placeholderTextColor={'#bdbdbd'}
									value={verificationCode}
									onChangeText={text => {
										setVerificationCode(text);
									}}
								/>
								<View
									style={{
										flexDirection: 'row',
										justifyContent: 'space-between',
                    alignItems: 'center',
										marginTop: 8,
										position: 'absolute',
										height: 24,
										bottom: -32,
										left: 0,
										right: 0
									}}
								>
                  <Text
                    style={{
                      color: '#A42323'
                    }}
                  >{!!errorMessage.length && errorMessage}</Text>
                  <TouchableOpacity
                    style={{
                      justifySelf: 'flex-end'
                    }}
                    onPress={() => {

                    }}
                  >
                    <Text
                      style={{
                        color: '#1A1A1A',
                        fontWeight: '600'
                      }}
                    >Resend code</Text>
                  </TouchableOpacity>
								</View>
							</View>
						</View>
						<TouchableOpacity
							onPress={() =>  {
								setIsSubmitting(true);
								onVerifyEmailSubmit();
							}}
							style={{
								backgroundColor: '#3DBA71',
								alignItems: 'center',
								justifyContent: 'center',
								borderRadius: 4,
								height: 48,
								marginTop: 40
							}}
						>
							{isSubmitting && <ActivityIndicator color={'white'}/>}
							{!isSubmitting && <Text
								style={{
									color: 'white',
									fontSize: 16,
									fontWeight: '600'
								}}
							>Confirm email address</Text>}
						</TouchableOpacity>
					</View>
				)}
			</View>
		</ImageBackground>
	)

}

SignupScreen.propTypes = {
	navigation: PropTypes.object,
};

export default connect(({auth}) => ({auth}))(SignupScreen);